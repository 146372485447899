import { useEffect, useState } from 'react';

import { Box, Flex, Stack, useBreakpointValue } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { CiLogin } from 'react-icons/ci';
import {
  FaFileUpload,
  FaRegFile,
  FaRegFileAlt,
  FaRegFileWord,
} from 'react-icons/fa';
import { FiCalendar } from 'react-icons/fi';
import { GiProgression } from 'react-icons/gi';
import { GrHelpBook } from 'react-icons/gr';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { HiMiniCreditCard, HiMiniExclamationTriangle } from 'react-icons/hi2';
import {
  MdConnectWithoutContact,
  MdDashboard,
  MdManageAccounts,
  MdOutlineAccountBox,
  MdOutlineAccountCircle,
  MdOutlineManageAccounts,
} from 'react-icons/md';
import { SiIobroker } from 'react-icons/si';
import { TbFileInvoice } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resetAuth } from 'src/Redux/Auth/Auth';
import {
  fetchBrokerUserData,
  removeBrokerData,
} from 'src/Redux/Broker/BrokerProfile/BrokerProfile';
import { removeBrokerageData } from 'src/Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import { removePlansInfo } from 'src/Redux/Plans/slice';
import { useAppSelector } from 'src/Redux/Store';
import NavItem from './SideItem';

type ParentOpenState = {
  report: boolean;
};

interface SidebarProps {
  navSize: 'small' | 'large';
  changeNavSize: React.Dispatch<React.SetStateAction<'small' | 'large'>>;
}

export default function Sidebar({ navSize, changeNavSize }: SidebarProps) {
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useAppSelector((state) => state.broker.brokerProfileData);
  const userType = localStorage.getItem('userType');

  console.log('userData', userData);

  const handleNavItemClick = (itemName: string) => {
    setActiveItem(itemName === activeItem ? null : itemName);
    if (itemName !== 'report') {
      setIsParentOpen({
        report: false,
      });
    }
  };

  const [isParentOpen, setIsParentOpen] = useState<ParentOpenState>({
    report: false,
  });

  const handleParentNavClick = (title: keyof ParentOpenState) => {
    setIsParentOpen({
      ...isParentOpen,
      [title]: !isParentOpen[title],
    });
    handleNavItemClick(title);
  };

  useEffect(() => {
    if (userType === 'broker') {
      if (!userData || !Object.keys(userData?.data).length) {
        dispatch(fetchBrokerUserData() as any);
      }
    }
  }, [userData]);

  useEffect(() => {
    const pathname = location.pathname;
    console.log('pathname', pathname);

    let newActiveItem = null;
    if (isApplicant) {
      if (
        // pathname.includes("applicant-dashboard") ||
        pathname === '/' ||
        pathname.includes('assign-applicant') ||
        pathname.includes('applications')
      ) {
        newActiveItem = 'applicant-dashboard';
      } else if (pathname.includes('profile')) {
        newActiveItem = 'profile';
      } else if (pathname.includes('owner-table')) {
        newActiveItem = 'owner-table';
      } else if (pathname.includes('accountant-table')) {
        newActiveItem = 'accountant-table';
      } else if (pathname.includes('add-your-brokerage')) {
        newActiveItem = 'add-your-brokerage';
      } else if (pathname.includes('applicant-accountant')) {
        newActiveItem = 'applicant-accountant';
      } else if (
        pathname.includes('applicantqbo') ||
        pathname.includes('company-info')
      ) {
        newActiveItem = 'applicantqbo';
      } else if (pathname.includes('upload')) {
        newActiveItem = 'upload';
      } else if (pathname.includes('account-payable')) {
        newActiveItem = 'account-payable';
      } else if (pathname.includes('account-receivable')) {
        newActiveItem = 'account-receivable';
      } else if (pathname.includes('profit-loss')) {
        newActiveItem = 'profit-loss';
      } else if (pathname.includes('balance-sheet')) {
        newActiveItem = 'balance-sheet';
      } else if (pathname.includes('access-request')) {
        newActiveItem = 'access-request';
      } else if (pathname.includes('help')) {
        newActiveItem = 'help';
      }
    } else if (isBroker) {
      if (
        // pathname.includes("broker-dashboard") ||
        pathname === '/' ||
        pathname.includes('create-application')
      ) {
        newActiveItem = 'broker-dashboard';
      } else if (pathname === '/broker/profile') {
        newActiveItem = 'profile';
      } else if (pathname.includes('working_progress')) {
        newActiveItem = 'working_progress';
      } else if (pathname.includes('working_capital')) {
        newActiveItem = 'working_capital';
      } else if (pathname.includes('upload')) {
        newActiveItem = 'upload';
      } else if (pathname === '/broker/applicant-profile') {
        newActiveItem = 'applicant-profile';
      } else if (pathname.includes('account-payable')) {
        newActiveItem = 'account-payable';
      } else if (pathname.includes('account-receivable')) {
        newActiveItem = 'account-receivable';
      } else if (pathname.includes('profit-loss')) {
        newActiveItem = 'profit-loss';
      } else if (pathname.includes('balance-sheet')) {
        newActiveItem = 'balance-sheet';
      } else if (pathname.includes('access-request')) {
        newActiveItem = 'access-request';
      } else if (pathname.includes('help')) {
        newActiveItem = 'help';
      }
    } else if (isBrokererage) {
      if (pathname === '/') {
        newActiveItem = 'profile';
      } else if (pathname.includes('brokerage-managment')) {
        newActiveItem = 'brokerage-managment';
      } else if (pathname.includes('brokerage-enterCl')) {
        newActiveItem = 'brokerage-enterCl';
      } else if (pathname.includes('brokerage-enterEAndO')) {
        newActiveItem = 'brokerage-enterEAndO';
      } else if (pathname.includes('plans')) {
        newActiveItem = 'plans';
      } else if (pathname.includes('billing')) {
        newActiveItem = 'billing';
      }
    } else if (isAdmin) {
      if (pathname === '/') {
        newActiveItem = 'admin-dashboard';
      }
    }
    setActiveItem(newActiveItem);
  }, [location.pathname]);

  const isApplicant = localStorage.getItem('userType') === 'applicant';

  const isBroker = localStorage.getItem('userType') === 'broker';
  const isBrokererage = localStorage.getItem('userType') === 'brokerage';
  const isAdmin = localStorage.getItem('userType') === 'admin';

  const mlValue = useBreakpointValue({
    base: '-2px',
    sm: '-1px',
    md: '-45px',
    lg: '-45px',
  });

  const mlValue2 = useBreakpointValue({
    base: '7px',
    sm: '9px',
    md: ' 9px',
    lg: '9px',
  });

  const NavValue = useBreakpointValue({
    base: '178px',
    sm: '219px',
    md: '255px',
    lg: '300px',
  });

  const NavValue2 = useBreakpointValue({
    base: '50px',
    sm: '50px',
    md: ' 50px',
    lg: '50px',
  });
  return (
    <Box
      h={{ base: 'calc(100vh - 64px)', md: 'calc(100vh - 80px)' }}
      mt={'auto'}
      w={navSize === 'small' ? NavValue2 : NavValue}
      overflowX={'hidden'}
      flexDir="column"
      justifyContent="space-between"
      display={'flex'}
      bg="white"
      position={'absolute'}
      top={{ base: '64px', md: '80px' }}
      transition={'0.3s'}
      left={{ base: navSize === 'large' ? 0 : '-100%', md: '0' }}
      color="white"
      boxShadow="0 0 6px 0 rgba(0,0,0,0.3)"
      zIndex={50}
    >
      <Box overflowY={'auto'} flex={'1'}>
        {/* <Box sx={{ display: { base: "none", md: "flex" } }} alignItems={"center"} p={2} height={{ base: "auto", md: "64px" }}>
          <Tooltip
            hasArrow
            label={navSize === "small" ? "Right" : "Left"}
            bg="gray.300"
            color="black"
            placement="right"
          >
            <IconButton
              display={{ base: "none", md: "flex" }}
              aria-label="Toggle Navigation"
              background="none"
              p="5px"
              borderRadius="14px"
              bg="#114684"
              _hover={{ bg: "#ffa011" }}
              icon={<FiMenu size={22} color="white" />}
              onClick={() => {
                if (navSize === "small") changeNavSize("large");
                else changeNavSize("small");
              }}
            />
          </Tooltip>
        </Box> */}
        {isApplicant && (
          <Flex
            flexDir="column"
            w="100%"
            alignItems={navSize === 'small' ? 'center' : 'flex-start'}
            as="nav"
            rowGap={{ base: 0.5, sm: 0.5, md: 1, lg: 1, xl: 2 }}
          >
            <>
              <Box width="100%" mt={['10px', '10px', '19px', '18px']}>
                <NavItem
                  navSize={navSize}
                  icon={MdDashboard}
                  title=" Dashboard"
                  active={activeItem === 'applicant-dashboard'}
                  onClick={() => handleNavItemClick('applicant-dashboard')}
                  to="/"
                />
              </Box>
              <Box width="100%">
                <NavItem
                  navSize={navSize}
                  icon={MdOutlineAccountCircle}
                  title="Profile"
                  active={activeItem === 'profile'}
                  onClick={() => handleNavItemClick('profile')}
                  to="/applicant/profile"
                />
              </Box>

              <Box width="100%">
                <NavItem
                  navSize={navSize}
                  icon={SiIobroker}
                  title="Add your brokerage"
                  active={activeItem === 'add-your-brokerage'}
                  onClick={() => handleNavItemClick('add-your-brokerage')}
                  to="/applicant/add-your-brokerage "
                />
              </Box>

              <Box width="100%">
                <NavItem
                  navSize={navSize}
                  icon={MdOutlineManageAccounts}
                  title="Owner"
                  active={activeItem === 'owner-table'}
                  onClick={() => handleNavItemClick('owner-table')}
                  to="/applicant/owner-table"
                />
              </Box>

              <Box width="100%">
                <NavItem
                  navSize={navSize}
                  icon={MdOutlineAccountBox}
                  title="Accountant"
                  active={activeItem === 'accountant-table'}
                  onClick={() => handleNavItemClick('accountant-table')}
                  to="/applicant/accountant-table"
                />
              </Box>

              <Box width="100%">
                <NavItem
                  navSize={navSize}
                  icon={MdConnectWithoutContact}
                  title="Connect to QBO"
                  active={activeItem === 'applicantqbo'}
                  onClick={() => handleNavItemClick('applicantqbo')}
                  to="/applicant/applicantqbo"
                />
              </Box>

              {/* Report */}
              <Box width="100%">
                <Flex
                  flexDir="column"
                  w="100%"
                  rowGap={0.5}
                  alignItems={navSize === 'small' ? 'center' : 'flex-start'}
                >
                  <Box width="100%">
                    <NavItem
                      navSize={navSize}
                      icon={FaRegFileAlt}
                      title="Report"
                      active={activeItem === 'report'}
                      onClick={() => handleParentNavClick('report')}
                      to="#"
                    />
                  </Box>
                  {isParentOpen.report && (
                    <Stack flex={1} w={'100%'} pl={4} h={0} overflow={'hidden'}>
                      <Box width="100%">
                        <NavItem
                          navSize={navSize}
                          icon={GiProgression}
                          title="Work in Progress"
                          active={activeItem === 'working_progress'}
                          onClick={() => handleNavItemClick('working_progress')}
                          to="/applicant/report/working_progress"
                        />
                      </Box>
                      <Box width="100%">
                        <NavItem
                          navSize={navSize}
                          icon={FaRegFileWord}
                          title="Working Capital"
                          active={activeItem === 'working_capital'}
                          onClick={() => handleNavItemClick('working_capital')}
                          to="/applicant/report/working_capital"
                        />
                      </Box>
                      <Box width="100%">
                        <NavItem
                          navSize={navSize}
                          icon={HiOutlineDocumentReport}
                          title="A/P Aging Report"
                          TooltipTitle="Account Payable Aging Report"
                          active={activeItem === 'account-payable'}
                          onClick={() => handleNavItemClick('account-payable')}
                          to="/applicant/report/account-payable"
                        />
                      </Box>
                      <Box width="100%">
                        <NavItem
                          navSize={navSize}
                          icon={FaRegFile}
                          title="A/R Aging Report"
                          TooltipTitle="Account Receivable Aging Report"
                          active={activeItem === 'account-receivable'}
                          onClick={() =>
                            handleNavItemClick('account-receivable')
                          }
                          to="/applicant/report/account-receivable"
                        />
                      </Box>
                      <Box width="100%">
                        <NavItem
                          navSize={navSize}
                          icon={TbFileInvoice}
                          title="Profit and Loss Report"
                          active={activeItem === 'profit-loss'}
                          onClick={() => handleNavItemClick('profit-loss')}
                          to="/applicant/report/profit-loss"
                        />
                      </Box>
                      <Box width="100%">
                        <NavItem
                          navSize={navSize}
                          icon={BsFileEarmarkSpreadsheet}
                          title="Balance Sheet Report"
                          active={activeItem === 'balance-sheet'}
                          onClick={() => handleNavItemClick('balance-sheet')}
                          to="/applicant/report/balance-sheet"
                        />
                      </Box>
                    </Stack>
                  )}
                </Flex>
              </Box>
              <Box width="100%">
                <NavItem
                  navSize={navSize}
                  icon={FaFileUpload}
                  title="Upload"
                  active={activeItem === 'upload'}
                  onClick={() => handleNavItemClick('upload')}
                  to="/applicant/upload"
                />
              </Box>
              <Box width="100%">
                <NavItem
                  navSize={navSize}
                  icon={MdManageAccounts}
                  title="Access Request"
                  active={activeItem === 'access-request'}
                  onClick={() => handleNavItemClick('access-request')}
                  to="/applicant/access-request"
                />
              </Box>
              <Box width="100%">
                <NavItem
                  navSize={navSize}
                  icon={FaRegFileAlt}
                  title="OCR Report"
                  active={activeItem === 'ocr-report'}
                  onClick={() => handleNavItemClick('ocr-report')}
                  to="/applicant/ocr-report"
                />
              </Box>
              <Box width="100%">
                <NavItem
                  navSize={navSize}
                  icon={GrHelpBook}
                  title="Help"
                  active={activeItem === 'help'}
                  onClick={() => handleNavItemClick('help')}
                  to="/applicant/help"
                />
              </Box>
            </>
          </Flex>
        )}

        {isBroker && (
          <Flex
            flexDir="column"
            w="100%"
            alignItems={navSize === 'small' ? 'center' : 'flex-start'}
            as="nav"
            rowGap={{ base: 0.5, sm: 0.5, md: 1, lg: 1, xl: 2 }}
          >
            <>
              <Box width="100%" mt="20px">
                <NavItem
                  navSize={navSize}
                  icon={MdDashboard}
                  title=" Dashboard"
                  active={activeItem === 'broker-dashboard'}
                  onClick={() => handleNavItemClick('broker-dashboard')}
                  to="/"
                />
              </Box>

              {!!userData &&
                !['decline', 'revoke']?.includes(
                  userData?.data?.brokerageStatus
                ) && (
                  <>
                    <Box width="100%">
                      <NavItem
                        navSize={navSize}
                        icon={MdOutlineAccountCircle}
                        title="Profile"
                        active={activeItem === 'profile'}
                        onClick={() => handleNavItemClick('profile')}
                        to="/broker/profile"
                      />
                    </Box>

                    <Box width="100%">
                      <Flex
                        flexDir="column"
                        w="100%"
                        rowGap={0.5}
                        alignItems={
                          navSize === 'small' ? 'center' : 'flex-start'
                        }
                      >
                        <Box width="100%">
                          <NavItem
                            navSize={navSize}
                            icon={FaRegFileAlt}
                            title="Report"
                            active={
                              activeItem === 'report' || isParentOpen.report
                            }
                            onClick={() => handleParentNavClick('report')}
                            to="#"
                          />
                        </Box>
                        {isParentOpen.report && (
                          <Stack
                            flex={1}
                            w={'100%'}
                            pl={navSize !== 'small' ? 4 : 0}
                            h={0}
                            overflow={'hidden'}
                          >
                            <Box width="100%">
                              <NavItem
                                navSize={navSize}
                                icon={GiProgression}
                                title="Work in Progress"
                                active={activeItem === 'working_progress'}
                                onClick={() =>
                                  handleNavItemClick('working_progress')
                                }
                                to="/broker/report/working_progress"
                              />
                            </Box>
                            <Box width="100%">
                              <NavItem
                                navSize={navSize}
                                icon={FaRegFileWord}
                                title="Working Capital"
                                active={activeItem === 'working_capital'}
                                onClick={() =>
                                  handleNavItemClick('working_capital')
                                }
                                to="/broker/report/working_capital"
                              />
                            </Box>
                            <Box width="100%">
                              <NavItem
                                navSize={navSize}
                                icon={HiOutlineDocumentReport}
                                title="A/P Aging Report"
                                TooltipTitle="Account Payable Aging Report"
                                active={activeItem === 'account-payable'}
                                onClick={() =>
                                  handleNavItemClick('account-payable')
                                }
                                to="/broker/report/account-payable"
                              />
                            </Box>
                            <Box width="100%">
                              <NavItem
                                navSize={navSize}
                                icon={FaRegFile}
                                title="A/R Aging Report"
                                TooltipTitle="Account Receivable Aging Report"
                                active={activeItem === 'account-receivable'}
                                onClick={() =>
                                  handleNavItemClick('account-receivable')
                                }
                                to="/broker/report/account-receivable"
                              />
                            </Box>
                            <Box width="100%">
                              <NavItem
                                navSize={navSize}
                                icon={TbFileInvoice}
                                title="Profit and Loss Report"
                                active={activeItem === 'profit-loss'}
                                onClick={() =>
                                  handleNavItemClick('profit-loss')
                                }
                                to="/broker/report/profit-loss"
                              />
                            </Box>
                            <Box width="100%">
                              <NavItem
                                navSize={navSize}
                                icon={BsFileEarmarkSpreadsheet}
                                title="Balance Sheet Report"
                                active={activeItem === 'balance-sheet'}
                                onClick={() =>
                                  handleNavItemClick('balance-sheet')
                                }
                                to="/broker/report/balance-sheet"
                              />
                            </Box>
                          </Stack>
                        )}
                        {/* <Menu placement="bottom" matchWidth>
                    <MenuButton
                    >
                      <NavItem
                        navSize={navSize}
                        icon={MdOutlineAccountCircle}
                        title="Report"
                        active={activeItem === "report"}
                        onClick={() => handleNavItemClick("report")}
                      />
                    </MenuButton>
                    <MenuList width="100%" style={{ width: "100%", border: "none" }} flex={1}>
                      <MenuItem width="100%">
                        <NavItem
                          navSize={navSize}
                          icon={MdOutlineAccountCircle}
                          title="Working Progress"
                          active={activeItem === "report"}
                          onClick={() => handleNavItemClick("report")}
                          to="/broker/profile"
                        />
                      </MenuItem>
                      <MenuItem width="100%" >
                        <NavItem
                          navSize={navSize}
                          icon={MdOutlineAccountCircle}
                          title="Working Capital"
                          active={activeItem === "report"}
                          onClick={() => handleNavItemClick("report")}
                        />
                      </MenuItem>
                    </MenuList>
                  </Menu> */}
                      </Flex>
                    </Box>

                    <Box width="100%">
                      <NavItem
                        navSize={navSize}
                        icon={FaFileUpload}
                        title="Upload"
                        active={activeItem === 'upload'}
                        onClick={() => handleNavItemClick('upload')}
                        to="/broker/upload"
                      />
                    </Box>

                    <Box width="100%">
                      <NavItem
                        navSize={navSize}
                        icon={MdOutlineAccountCircle}
                        title="Applicant Profile"
                        active={activeItem === 'applicant-profile'}
                        onClick={() => handleNavItemClick('applicant-profile')}
                        to="/broker/applicant-profile"
                      />
                    </Box>

                    <Box width="100%">
                      <NavItem
                        navSize={navSize}
                        icon={MdManageAccounts}
                        title="Access Request"
                        active={activeItem === 'access-request'}
                        onClick={() => handleNavItemClick('access-request')}
                        to="/broker/access-request"
                      />
                    </Box>
                    <Box width="100%">
                      <NavItem
                        navSize={navSize}
                        icon={FaRegFileAlt}
                        title="OCR Report"
                        active={activeItem === 'ocr-report'}
                        onClick={() => handleNavItemClick('ocr-report')}
                        to="/broker/ocr-report"
                      />
                    </Box>
                    <Box width="100%">
                      <NavItem
                        navSize={navSize}
                        icon={GrHelpBook}
                        title="Help"
                        active={activeItem === 'help'}
                        onClick={() => handleNavItemClick('help')}
                        to="/broker/help"
                      />
                    </Box>
                  </>
                )}
            </>
          </Flex>
        )}

        {isBrokererage && (
          <Flex
            flexDir="column"
            w="100%"
            alignItems={navSize === 'small' ? 'center' : 'flex-start'}
            as="nav"
            rowGap={{ base: 0.5, sm: 0.5, md: 1, lg: 1, xl: 2 }}
          >
            <Box width="100%" mt="20px">
              <NavItem
                navSize={navSize}
                icon={CgProfile}
                title="Profile"
                active={activeItem === 'profile'}
                onClick={() => handleNavItemClick('profile')}
                to="/"
              />
            </Box>

            <Box width="100%">
              <NavItem
                navSize={navSize}
                icon={FiCalendar}
                title="Corporate License"
                active={activeItem === 'brokerage-enterCl'}
                onClick={() => handleNavItemClick('brokerage-enterCl')}
                to="/brokerage/brokerage-enterCl "
              />
            </Box>

            <Box width="100%">
              <NavItem
                navSize={navSize}
                icon={HiMiniExclamationTriangle}
                title="Errors & Omissions"
                active={activeItem === 'brokerage-enterEAndO'}
                onClick={() => handleNavItemClick('brokerage-enterEAndO')}
                to="/brokerage/brokerage-enterEAndO "
              />
            </Box>
            <Box width="100%">
              <NavItem
                navSize={navSize}
                icon={HiMiniCreditCard}
                title="Plan & Purchases"
                active={activeItem === 'plans'}
                onClick={() => handleNavItemClick('plans')}
                to="/brokerage/plans"
              />
            </Box>

            <Box width="100%">
              <NavItem
                navSize={navSize}
                icon={MdManageAccounts}
                title="Brokers"
                active={activeItem === 'brokerage-managment'}
                onClick={() => handleNavItemClick('brokerage-managment')}
                to="/brokerage/brokerage-managment "
              />
            </Box>
            {/* <Box width="100%">
              <NavItem
                navSize={navSize}
                icon={PiWalletFill}
                title="Plan & Billing"
                active={activeItem === 'billing'}
                onClick={() => handleNavItemClick('billing')}
                to="/brokerage/billing"
              />
            </Box> */}
          </Flex>
        )}

        {isAdmin && (
          <Flex
            flexDir="column"
            w="100%"
            alignItems={navSize === 'small' ? 'center' : 'flex-start'}
            as="nav"
            rowGap={{ base: 0.5, sm: 0.5, md: 1, lg: 1, xl: 2 }}
          >
            <>
              <Box width="100%" mt={['10px', '10px', '19px', '18px']}>
                <NavItem
                  navSize={navSize}
                  icon={MdDashboard}
                  title=" Dashboard"
                  active={activeItem === 'admin-dashboard'}
                  onClick={() => handleNavItemClick('admin-dashboard')}
                  to="/"
                />
              </Box>
            </>
          </Flex>
        )}
      </Box>

      <Box>
        <Box>
          <NavItem
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#ffa011',
            }}
            navSize={navSize}
            icon={CiLogin}
            title="Logout"
            active={activeItem === ''}
            onClick={() => {
              dispatch(resetAuth());
              dispatch(removeBrokerData({}));
              dispatch(removeBrokerageData({}));
              dispatch(removePlansInfo({}));
              localStorage.removeItem('Token');
              localStorage.removeItem('userType');
              Cookies.remove('token');
              // window.location.href = "/";
            }}
            to="/login"
          />
        </Box>
      </Box>
    </Box>
  );
}
