import { Box, Flex, Text } from '@chakra-ui/react';
import { ActionIcon, Stack, Tooltip } from '@mantine/core';
import { modals, ModalsProvider } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  useMantineReactTable,
} from 'mantine-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CiLock, CiUnlock } from 'react-icons/ci';
import { LiaTrashRestoreAltSolid } from 'react-icons/lia';
import { MdBlockFlipped } from 'react-icons/md';
import { useAppToast } from 'src/hooks';
import {
  adminDeletBrokerage,
  getAdminDashboardList,
} from 'src/Redux/Admin/slice';
import {
  AdminDashboardApplication,
  AdminDashboardList,
} from 'src/Redux/Admin/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { getRandomUUID } from 'src/utils/helpers';

export interface AdminDashboardBroker {
  brokerId: number;
  firstName: string;
  subRows: AdminDashboardApplication[];
}
export interface AdminDashboardTableSchema {
  legalName: string;
  brokerageId: number;
  brokerId?: number;
  firstName?: string;
  applicationName?: string;
  subRows: AdminDashboardBroker[];
  id?: string;
  isSuspended: boolean;
  isDeleted: boolean;
  isBlacklisted: boolean;
}

const AdminDashboardTable = () => {
  const dispatch = useAppDispatch();
  const adminSlice = useAppSelector((state) => state?.adminSlice);
  const toast = useAppToast();
  const [loader, setLoader] = useState<{
    contentLoader: boolean;
  }>({ contentLoader: false });
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    fetchAdminDashboardList();
  }, []);

  useEffect(() => {
    const { status, type, adminDashboardList, error, msg } = adminSlice;
    console.log('adminSlice', adminSlice);

    switch (status) {
      case 'loading': {
        if (type === 'GET_ALL_ADMIN_DASHBOARD_LIST') {
          setLoader((prev) => ({ ...prev, contentLoader: true }));
        }
        break;
      }
      case 'succeed': {
        if (
          type === 'GET_ALL_ADMIN_DASHBOARD_LIST' &&
          !!adminDashboardList.length
        ) {
          const adminDAshboardData = getBrokerListTableData(adminDashboardList);
          setTableData(adminDAshboardData);
          setLoader((prev) => ({ ...prev, contentLoader: false }));
        }
        if (type === 'BROKERAGE_STATUS_UPDATED_BY_ADMIN') {
          toast({
            title: msg,
            status: 'success',
          });
          fetchAdminDashboardList();
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_ALL_ADMIN_DASHBOARD_LIST') {
          toast({
            title: error,
            status: 'error',
          });
          setLoader((prev) => ({ ...prev, contentLoader: false }));
        }
        if (type === 'BROKERAGE_STATUS_UPDATED_BY_ADMIN') {
          toast({
            title: error,
            status: 'error',
          });
        }
        break;
      }
      default:
        break;
    }
  }, [adminSlice.status, adminSlice.type]);

  const fetchAdminDashboardList = () => {
    dispatch(getAdminDashboardList() as any);
  };

  const openDeleteConfirmModal = (row: MRT_Row<AdminDashboardTableSchema>) => {
    console.log('delete-row', row);
    return modals.openConfirmModal({
      title: `DELETE`,
      children: (
        <Text>
          Are you sure you want to delete{' '}
          <Box as="span" fontWeight={'600'}>
            {row?.original?.legalName}
          </Box>
          ?
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: {
        color: 'red',
      },
      onConfirm: async () => {
        const { brokerageId } = row?.original;
        if (!!brokerageId) {
          await dispatch(
            adminDeletBrokerage({ brokerageId, action: 'delete' })
          );
        }
      },
    });
  };

  const handleAdminAction = (
    row: MRT_Row<AdminDashboardTableSchema>,
    action: string
  ) => {
    console.log('test123');

    try {
      const { brokerageId } = row?.original;
      dispatch(adminDeletBrokerage({ brokerageId, action }));
    } catch (error) {
      console.log('error', error);
    }
  };

  const getBrokerListTableData = (brokerListData: AdminDashboardList[]) => {
    return brokerListData?.map(({ brokers, ...rest }) => {
      // Calculate total applications for the broker list
      const totalApplications = brokers.reduce(
        (sum, broker) => sum + broker.applications.length,
        0
      );

      // Create the data for each row, including the subRows with individual broker's application count
      const subRows = brokers.map(({ applications, ...broker }) => {
        const brokerApplicationsCount = applications.length; // Number of applications for each broker

        return {
          ...broker,
          applicationName: brokerApplicationsCount,
          subRows: [], // Individual broker's applications
        };
      });

      return {
        ...rest,
        firstName: brokers.length, // Count of brokers
        applicationName: totalApplications, // Total applications across all brokers
        subRows, // The subrows for brokers
        id: getRandomUUID(), // Unique ID
      };
    });
  };

  const getTotals = useCallback(() => {
    let totalApplications = 0;
    let totalBrokers = 0;
    let totalBrokerages = 0;

    tableData.forEach((brokerage) => {
      totalBrokerages++; // Count each brokerage
      brokerage.subRows.forEach((broker: Record<string, any>) => {
        totalBrokers++; // Count each broker
        totalApplications += broker.applicationName; // Add the number of applications for this broker
      });
    });

    return { totalApplications, totalBrokers, totalBrokerages };
  }, [tableData]);

  const { totalBrokers, totalApplications, totalBrokerages } = getTotals();

  const columns = useMemo<MRT_ColumnDef<AdminDashboardTableSchema>[]>(
    () => [
      {
        accessorKey: 'legalName',
        header: 'Brokerage Name',
        Cell: ({ row }) => (
          <Box key={row?.original?.id}>{row.original.legalName}</Box>
        ),
        grow: false,
        size: 5,
        maxSize: 5,
        Footer: () => (
          <Stack>
            Total Brokerages:
            <Box color="orange">{Math.round(totalBrokerages)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'firstName',
        enableColumnOrdering: false,
        header: 'Broker Count / Name ',
        Cell: ({ row }) => (
          <Box
            fontWeight={row.original.legalName ? 'bold' : 'normal'}
            key={row?.original?.id}
          >
            {row.original.firstName}
          </Box>
        ),
        Footer: () => (
          <Stack>
            Total Brokers:
            <Box color="orange">{Math.round(totalBrokers)}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'NA',
        enableColumnOrdering: false,
        header: 'Application Count',
        Cell: ({ row }) => (
          <Box
            as="p"
            key={row?.original?.id}
            fontWeight={row.original.legalName ? 'bold' : 'normal'}
          >
            {row.original.applicationName}
          </Box>
        ),
        Footer: () => (
          <Stack>
            Total Applications:
            <Box color="orange">{Math.round(totalApplications)}</Box>
          </Stack>
        ),
      },
    ],
    [totalBrokers, totalApplications]
  );

  const table = useMantineReactTable({
    columns: columns,
    data: tableData as any,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    getRowId: (row, index) => row?.id,
    layoutMode: 'semantic',
    initialState: {
      density: 'xs',
    },
    mantineTableContainerProps: {
      sx: {
        width: '100%',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 45,
        opacity: '1 !important',
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        boxShadow: 'unset !important',
      },
    },
    mantineTableBodyCellProps: {
      sx: {
        boxShadow: 'unset !important',
      },
    },
    renderRowActions: ({ row }) => {
      console.log('row-action', row);
      const { isSuspended, isDeleted, isBlacklisted } = row?.original;
      if (!!row?.original?.legalName) {
        if (isSuspended && isDeleted && isBlacklisted) {
          return <Text>Blacklisted</Text>;
        } else if (isSuspended && isDeleted) {
          return (
            <Flex gap={'lg'} flexWrap={'wrap'}>
              <Tooltip label="Blacklist" position="right">
                <ActionIcon
                  onClick={() => handleAdminAction(row, 'blacklist')}
                  size={'md'}
                >
                  <MdBlockFlipped size={20} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Restore" position="right">
                <ActionIcon
                  onClick={() => handleAdminAction(row, 'delete')}
                  size={'md'}
                  mr={1}
                >
                  {/* <CgUnblock size={22} /> */}
                  <LiaTrashRestoreAltSolid size={22} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          );
        } else if (isSuspended) {
          return (
            <Flex gap={'lg'} flexWrap={'wrap'}>
              <Tooltip label="Delete" position="right">
                <ActionIcon
                  onClick={() => openDeleteConfirmModal(row)}
                  size={'md'}
                >
                  <IconTrash size={20} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Active" position="right">
                <ActionIcon
                  onClick={() => handleAdminAction(row, 'suspend')}
                  size={'md'}
                  mr={1}
                >
                  <CiUnlock size={20} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          );
        } else {
          return (
            <Flex>
              <Tooltip label="Suspend" position="right">
                <ActionIcon
                  onClick={() => handleAdminAction(row, 'suspend')}
                  size={'md'}
                >
                  <CiLock size={20} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          );
        }
      }
    },
    positionExpandColumn: 'first',
    enableStickyHeader: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableTopToolbar: false,
    enablePagination: false,
    positionPagination: 'none',
    enableBottomToolbar: false,
    enableExpanding: true,
    state: {
      isLoading: loader.contentLoader,
    },
  });

  return (
    <ModalsProvider>
      <Box maxW={'1250px'} w={'100%'} p={10} mx={'auto'}>
        <MantineReactTable table={table} key={'admin-dashboard-table'} />
      </Box>
    </ModalsProvider>
  );
};

export default AdminDashboardTable;
