import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Input,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BrokerageEandOSchema } from '../../../ValidationSchema/Pages/Index';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBrokerageUserData,
  RequestDataEO,
} from '../../../Redux/Brokerage/BrokerageProfile/BrokerageProfile';

import { useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import { useNavigate } from 'react-router-dom';
import useObjectComparator from '../../../hooks/useObjectComparator';
import { RootState } from '../../../Redux/Store';
import {
  addFocusEventListenerOnInput,
  preventAlphabetsTyping,
  removeFocusEventListenerOnInput,
} from '../../../utils/helpers';

function BrokererageEnterEAndO() {
  const dispatch = useDispatch();
  const Toast = useToast();
  const navigate = useNavigate();
  const expiryDateRef = useRef<DatePickerInput>(null);
  const [isBrokererageEnterEAndOUpdate, setIsBrokererageEnterEAndOUpdate] =
    useState(false);
  const useruidData = useSelector((state: RootState) => state.brokerage.user);
  let userGetidData = useruidData && useruidData?.brokerageInfo;
  const { areDifferent, setInitialValues, setNewValues } =
    useObjectComparator();

  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        if (!useruidData || !Object.keys(useruidData).length) {
          const actionResult = await dispatch(fetchBrokerageUserData() as any);
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          }
        }
      } else {
        navigate('/login');
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (userGetidData) {
      const { insurer, policy, limit, expiryDate, claims_EO, claims_EO_info } =
        userGetidData;
      const today = new Date();
      formik.setValues((prevValues) => ({
        ...prevValues,
        insurer: insurer || '',
        policy: policy || '',
        limit: limit || '',
        expiryDate: expiryDate ? new Date(expiryDate) : today,

        claims_EO: claims_EO,
        claims_EO_info: claims_EO_info || '',
      }));

      setInitialValues((prevValues: any) => ({
        ...prevValues,
        insurer: insurer || '',
        policy: policy || '',
        limit: limit || '',
        expiryDate: expiryDate ? new Date(expiryDate) : today,

        claims_EO: claims_EO,
        claims_EO_info: claims_EO_info || '',
      }));
    }
  }, [userGetidData]);

  interface FormValues {
    insurer: string | null;
    policy: string | null;
    limit: string | null;
    expiryDate: Date | null;
    claims_EO: boolean;
    claims_EO_info: string | null;
  }

  const initialValues: FormValues = {
    insurer: null,
    policy: null,
    limit: null,
    expiryDate: new Date(),
    claims_EO: false,
    claims_EO_info: null,
  };

  const formik = useFormik({
    initialValues,

    validationSchema: BrokerageEandOSchema,

    onSubmit: async (values: FormValues, { resetForm }) => {
      setIsBrokererageEnterEAndOUpdate(true);
      let res = '';
      try {
        if (!areDifferent) {
          setIsBrokererageEnterEAndOUpdate(false);
          return;
        }
        const actionResult = await dispatch(RequestDataEO(values) as any);
        const response = actionResult?.payload;
        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 200) {
          Toast({
            title: response.msg,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
          resetForm();
        } else {
          if (resStatus !== 401) {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else {
            navigate('/login');
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          Toast({
            title: '404',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      if (!!localStorage.getItem('Token')) {
        const actionResult = await dispatch(fetchBrokerageUserData() as any);
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
      setIsBrokererageEnterEAndOUpdate(false);
    },
  });
  useEffect(() => {
    setNewValues(formik.values);
  }, [formik.values]);
  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        marginBottom="auto"
        p="0px 22px 0px 22px"
        borderRadius="20px"
      >
        <form onSubmit={formik.handleSubmit}>
          <Box display={'flex'} flexDirection={'column'} gap={3}>
            <Box>
              <Text
                fontWeight="600"
                color="#114684"
                textAlign="center"
                fontSize={20}
                pt="20px"
                mb={5}
              >
                Errors & Omission Information
              </Text>
            </Box>

            <Box display="flex">
              <Tooltip label="Insurer" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder=" Insurer"
                  borderRadius="5px"
                  name="insurer"
                  value={formik.values.insurer ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.insurer && formik.errors.insurer && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.insurer}
              </div>
            )}

            <Box display="flex">
              <Tooltip label="Policy" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Policy "
                  borderRadius="5px"
                  name="policy"
                  value={formik.values.policy ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.policy && formik.errors.policy && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.policy}
              </div>
            )}

            <Box display="flex">
              <Tooltip label="Limit" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder="Limit"
                  borderRadius="5px"
                  name="limit"
                  value={formik.values.limit ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                  onKeyDown={preventAlphabetsTyping}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                />
              </Tooltip>
            </Box>
            {formik.touched.limit && formik.errors.limit && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.limit}
              </div>
            )}

            <Tooltip label="Expiry Date" placement="right" hasArrow>
              <Box display="flex" flexDirection={'column'}>
                <DatePickerInput
                  ref={expiryDateRef}
                  value={
                    formik.values.expiryDate !== null
                      ? formik.values.expiryDate
                      : undefined
                  }
                  onChange={(date: Date | null) => {
                    formik.setFieldValue('expiryDate', date);
                  }}
                  locale="en"
                  placeholder="Expiry Date"
                  className="react-datepicker-component react-datepicker-input input null-border"
                  showOnInputClick={true}
                  onShow={() => addFocusEventListenerOnInput(expiryDateRef)}
                  onHide={() => removeFocusEventListenerOnInput(expiryDateRef)}
                  minDate={new Date()}
                />
                {formik.touched.expiryDate && formik.errors.expiryDate && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.expiryDate as any}
                  </div>
                )}
              </Box>
            </Tooltip>

            <Flex justifyContent="space-between" alignItems={'flex-start'}>
              <Text
                fontWeight="500"
                fontSize={13}
                color="#114684"
                textAlign="start"
              >
                Has there been any E&O claims in the past 5 years?
              </Text>
              <Stack spacing={5} direction="row">
                <Checkbox
                  colorScheme="green"
                  fontWeight="500"
                  isChecked={formik.values.claims_EO === true}
                  onChange={(e) =>
                    formik.setFieldValue('claims_EO', e.target.checked)
                  }
                >
                  Yes
                </Checkbox>
                <Checkbox
                  colorScheme="red"
                  defaultChecked
                  fontWeight="500"
                  isChecked={formik.values.claims_EO === false}
                  onChange={(e) =>
                    formik.setFieldValue('claims_EO', !e.target.checked)
                  }
                >
                  No
                </Checkbox>
              </Stack>
            </Flex>
            {formik.touched.claims_EO && formik.errors.claims_EO && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.claims_EO}
              </div>
            )}
            {formik.values.claims_EO && (
              <Box>
                <Box display="flex">
                  <Tooltip
                    label="Please list & Explain"
                    placement="right"
                    hasArrow
                  >
                    <Textarea
                      fontSize={'13px'}
                      w="100%"
                      placeholder="Please list & explain"
                      name="claims_EO_info"
                      borderRadius="5px"
                      value={formik.values.claims_EO_info ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      focusBorderColor="transparent"
                      bg={'#f0f5f9'}
                      border="none"
                    />
                  </Tooltip>
                </Box>
                {formik.touched.claims_EO_info &&
                  formik.errors.claims_EO_info && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.claims_EO_info}
                    </div>
                  )}
              </Box>
            )}
            <Button
              isLoading={isBrokererageEnterEAndOUpdate}
              loadingText="Update"
              type="submit"
              bg="#114684"
              color="white"
              w="100%"
              _hover={{
                bg: '#114684',
                color: 'white',
                cursor: areDifferent ? 'pointer' : 'not-allowed',
              }}
              isDisabled={!areDifferent}
              mt={5}
              mb={5}
            >
              Update
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
}

export default BrokererageEnterEAndO;
