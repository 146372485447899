import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import moment from 'moment';
import { DISABLE_INPUT_BG, HISTORY_INPUT_BG } from 'src/constants/styles';
import {
  legalFormAccUploadFields,
  liensTableSchemaArrayFields,
  liensTableSchemaObjectFields,
  liensTableUploadFileds,
} from 'src/Pages/BrokerPages/CreateApplicationForm/utils';
import {
  checkoutStatusAll,
  IStripePlanBase,
  stripeMetadata,
  SubscriptionMonthlyPlans,
  SubscriptionYearlyPlans,
  SunscriptionSingleApplicaitonPlan,
} from 'src/Redux/Plans/state';

/** Prevent Alphabet typing */
export const preventAlphabetsTyping = (e: any) => {
  if (
    !(
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) || // Numeric keys
      (e.keyCode >= 37 && e.keyCode <= 40) || // Arrow keys
      e.keyCode === 8 || // Backspace
      e.keyCode === 46 || // Delete
      e.keyCode === 9
    )
  ) {
    // Tab
    e.preventDefault();
  }
};

/** Blur the focus from input field */
export const blurInputFocus = (e: any) => {
  e.target.blur();
};

/** Add event listener on rc-datepicker input field */
export const addFocusEventListenerOnInput = (
  datePickerRef: React.RefObject<any>
) => {
  datePickerRef.current.datePickerInputRef.firstChild.firstChild.blur();
  datePickerRef.current.datePickerInputRef.firstChild.firstChild.addEventListener(
    'focus',
    blurInputFocus
  );
};

/** Remove event listener from rc-datepicker input field */
export const removeFocusEventListenerOnInput = (
  datePickerRef: React.RefObject<any>
) => {
  datePickerRef.current.datePickerInputRef.firstChild.firstChild.blur();
  datePickerRef.current.datePickerInputRef.firstChild.firstChild.removeEventListener(
    'focus',
    blurInputFocus
  );
};

/** Compare Object and return if field is different or not */
type deepObjectComparison2ArgumentsType = {
  addArray: boolean;
};
export const deepObjectComparison = (
  formValue: any,
  apiData: any,
  options?: deepObjectComparison2ArgumentsType
): Record<string, any> => {
  // Iterate over all properties of formValue
  let result: Record<string, any> = options?.addArray ? [] : {};

  for (const key in formValue) {
    if (
      !apiData ||
      (typeof formValue[key] !== 'object' && formValue[key] !== apiData[key])
    ) {
      result[key] = formValue[key];
      if (formValue?.['id']) {
        result['id'] = formValue['id'];
      }
    } else if (typeof formValue[key] === 'object' && formValue[key] !== null) {
      // Handle Array
      if (Array.isArray(formValue[key])) {
        const res = deepObjectComparison(formValue[key], apiData[key], {
          addArray: true,
        });
        if (Array.isArray(res) && res.length) {
          result[key] = res;
        }
      }
      // Handle Array elements
      else if (options?.addArray) {
        const res = deepObjectComparison(formValue[key], apiData[key]);
        result.push(res);
        // if ((typeof res !== 'object' && res) || (typeof res === 'object' && res !== null && Object.keys(res).length)) {

        // }
      }
      // Handle Date
      else if (formValue[key] instanceof Date) {
        const formDate = formValue[key].getTime();
        const apiDate =
          apiData[key] instanceof Date ? apiData[key].getTime() : 0;
        if (formDate !== apiDate) {
          result = {
            ...result,
            [key]: formValue[key],
          };
        }
      } else {
        const res = deepObjectComparison(formValue[key], apiData[key]);
        if (
          (typeof res !== 'object' && res) ||
          (typeof res === 'object' && res !== null && Object.keys(res).length)
        ) {
          result = {
            ...result,
            [key]: { ...res },
          };
        }
      }
    }
    // Handle null value in formValues
    else if (
      typeof formValue[key] === 'object' &&
      formValue[key] === null &&
      formValue[key] !== apiData[key]
    ) {
      result = {
        ...result,
        [key]: formValue[key],
      };
    }
  }

  return result;
};

export const areObjectsEqual = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (
      typeof val1 === 'object' &&
      val1 !== null &&
      typeof val2 === 'object' &&
      val2 !== null
    ) {
      // Recursively compare objects
      if (!areObjectsEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      // Primitive comparison
      return false;
    }
  }
  return true;
};

export const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    const el1 = arr1[i];
    const el2 = arr2[i];

    if (
      typeof el1 === 'object' &&
      el1 !== null &&
      typeof el2 === 'object' &&
      el2 !== null
    ) {
      // Perform deep comparison for objects
      if (!areObjectsEqual(el1, el2)) {
        return false;
      }
    } else if (el1 !== el2) {
      // Primitive comparison
      return false;
    }
  }
  return true;
};

export const deepObjectComparisonLegalForm = (
  formValue: any,
  apiData: any,
  options?: deepObjectComparison2ArgumentsType,
  skipArray: boolean = false
): Record<string, any> => {
  // Iterate over all properties of formValue
  let result: Record<string, any> = options?.addArray ? [] : {};

  if (skipArray && Array.isArray(formValue) && Array.isArray(apiData)) {
    const isArrayEqual = areArraysEqual(formValue, apiData);
    if (!isArrayEqual) {
      return formValue;
    } else {
      return {};
    }
  }

  for (const key in formValue) {
    if (
      !apiData ||
      (typeof formValue[key] !== 'object' && formValue[key] !== apiData[key]) ||
      (typeof formValue[key] === 'object' &&
        [
          ...liensTableSchemaObjectFields,
          ...liensTableUploadFileds,
          ...liensTableSchemaArrayFields,
        ].includes(key) &&
        formValue[key] !== apiData[key])
    ) {
      // result[key] = formValue[key];

      if (formValue['uuid']) {
        if (result['uuid']) {
          result = Object.assign(result, { [key]: formValue[key] });
        } else {
          result = Object.assign(
            { [key]: formValue[key] },
            { uuid: formValue['uuid'] }
          );
        }
      } else {
        result[key] = formValue[key];
      }
      console.log('formValue', formValue, key, result);

      if (formValue?.['id']) {
        result['id'] = formValue['id'];
      }
    } else if (typeof formValue[key] === 'object' && formValue[key] !== null) {
      // Handle Array
      if (Array.isArray(formValue[key])) {
        console.log('femil input', formValue[key], apiData[key]);

        const res = deepObjectComparisonLegalForm(
          formValue[key],
          apiData[key],
          {
            addArray: true,
          },
          [
            ...legalFormAccUploadFields,
            ...liensTableUploadFileds,
            ...liensTableSchemaArrayFields,
          ].includes(key)
        );
        console.log('femil', res);

        if (Array.isArray(res) && res.length) {
          result[key] = res;
        }
      }
      // Handle Array elements
      else if (options?.addArray) {
        const res = deepObjectComparisonLegalForm(formValue[key], apiData[key]);
        result.push(res);
        // if ((typeof res !== 'object' && res) || (typeof res === 'object' && res !== null && Object.keys(res).length)) {

        // }
      }
      // Handle Date
      else if (formValue[key] instanceof Date) {
        const formDate = formValue[key].getTime();
        const apiDate =
          apiData[key] instanceof Date ? apiData[key].getTime() : 0;
        if (formDate !== apiDate) {
          result = {
            ...result,
            [key]: formValue[key],
          };
        }
      } else {
        const res = deepObjectComparisonLegalForm(formValue[key], apiData[key]);
        if (
          (typeof res !== 'object' && res) ||
          (typeof res === 'object' && res !== null && Object.keys(res).length)
        ) {
          result = {
            ...result,
            [key]: { ...res },
          };
        }
      }
    }
    // Handle null value in formValues
    else if (
      typeof formValue[key] === 'object' &&
      formValue[key] === null &&
      formValue[key] !== apiData[key]
    ) {
      result = {
        ...result,
        [key]: formValue[key],
      };
    }
  }

  return result;
};

//after upload remove all file then not pass anything in updated data
// export const removeEmptyValue = (obj: any): any => {
//   if (typeof obj !== 'object' || obj === null) return obj;

//   // If it's an array, recursively clean the elements and remove empty arrays
//   // console.log('obj:::', obj);

//   console.log('obj:::', obj);

//   if (Array.isArray(obj)) {
//     const cleanedArray = obj
//       .map(removeEmptyValue)
//       .filter((item) => item !== null && item !== undefined);
//     return cleanedArray.length ? cleanedArray : null;
//   }

//   // For objects, recursively clean the values and remove empty objects
//   const cleanedObj: Record<string, any> = {};
//   for (const key in obj) {
//     const cleanedValue = removeEmptyValue(obj[key]);
//     if (cleanedValue !== null && cleanedValue !== undefined) {
//       cleanedObj[key] = cleanedValue;
//     }
//   }

//   // Return the cleaned object or null if it's empty
//   return Object.keys(cleanedObj).length ? cleanedObj : null;
// };

// export const removeEmptyValue = (obj: any): any => {
//   if (typeof obj !== 'object' || obj === null) return obj;

//   if (Array.isArray(obj)) {
//     // Create an array of non-empty items with their original index
//     const cleanedArray = obj
//       .map((item, index) => ({ value: removeEmptyValue(item), index }))
//       .filter(({ value }) => value !== null && value !== undefined && Object.keys(value).length);

//     // If the cleaned array has elements, return the array with original index values
//     return cleanedArray.length ? cleanedArray : null;
//   }

//   // For objects, recursively clean the values
//   const cleanedObj: Record<string, any> = {};
//   for (const key in obj) {
//     const cleanedValue = removeEmptyValue(obj[key]);
//     if (cleanedValue !== null && cleanedValue !== undefined) {
//       cleanedObj[key] = cleanedValue;
//     }
//   }

//   // Return the cleaned object or null if it's empty
//   return Object.keys(cleanedObj).length ? cleanedObj : null;
// };

export const removeEmptyValue = (obj: any): any => {
  // console.log('obj:::', obj, typeof obj, obj instanceof Date);

  if (
    typeof obj !== 'object' ||
    obj === null ||
    (typeof obj === 'object' && obj instanceof Date)
  )
    return obj;

  // Handle arrays by returning an object where keys are the original indices
  if (Array.isArray(obj)) {
    const cleanedArray = obj.reduce(
      (acc, item, index) => {
        const cleanedValue = removeEmptyValue(item);
        if (
          cleanedValue !== null &&
          cleanedValue !== undefined &&
          Object.keys(cleanedValue).length
        ) {
          acc[cleanedValue?.uuid] = cleanedValue; // Use index as the key for non-empty items
        }
        return acc;
      },
      {} as Record<number, any>
    ); // Create an object instead of array

    // If the cleaned array has elements, return the object
    return Object.keys(cleanedArray).length ? cleanedArray : null;
  }

  // For objects, recursively clean the values
  const cleanedObj: Record<string, any> = {};
  for (const key in obj) {
    const cleanedValue = [
      'companies',
      ...legalFormAccUploadFields,
      ...liensTableUploadFileds,
      ...liensTableSchemaArrayFields,
    ].includes(key)
      ? Array.isArray(obj[key])
        ? obj[key].map((item: any) => removeEmptyValue(item)) // Apply removeEmptyValue to each item in the array
        : obj[key]
      : removeEmptyValue(obj[key]);
    if (cleanedValue !== null && cleanedValue !== undefined) {
      cleanedObj[key] = cleanedValue;
    }
  }

  // Return the cleaned object or null if it's empty
  return Object.keys(cleanedObj).length ? cleanedObj : null;
};

// export function removeNullValues(obj: any) {
//   // Iterate over the keys of the object
//   for (let key in obj) {
//     if (obj.hasOwnProperty(key)) {
//       // If the value is an object, recursively call removeNullValues
//       if (typeof obj[key] === 'object' && obj[key] !== null) {
//         removeNullValues(obj[key]);

//         // If the object is now empty after removing nulls, remove the key
//         if (Object.keys(obj[key]).length === 0) {
//           delete obj[key];
//         }
//       }
//       // If the value is null, delete the key
//       else if (obj[key] === null) {
//         delete obj[key];
//       }
//     }
//   }
//   return obj;
// }

/** Remove fields from Object */
export const removeFields = (obj: Record<string, any>, fields: String[]) => {
  if (!fields || fields.length === 0 || !obj) return obj;
  const objClone = { ...obj };
  fields.forEach((field) => {
    if (`${field}` in objClone) {
      delete objClone[`${field}`];
    }
  });

  return objClone;
};

/** Get value from API if not undefiled */
export const getValue = (
  isShowHistory: boolean,
  historyData: any,
  apiData: any
) => {
  if (isShowHistory) {
    // return (historyData !== undefined) ? historyData : (apiData !== undefined) ? apiData : null;
    return historyData !== undefined ? historyData : null;
  }

  return apiData !== undefined ? apiData : null;
};

/** Trim String values in a nested object */
export const trimStringValues = (obj: Record<string, any> | string): any => {
  if (typeof obj === 'string') {
    return obj.trim();
  }
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Iterate over all properties of obj
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/\s+/g, ' ').trim();
      } else if (typeof obj[key] === 'object') {
        obj[key] = trimStringValues(obj[key]);
      }
    }
  }

  return obj;
};

/** Insert at index */
export const insertAtIndex = (arr: Array<any>, index: number, element: any) => {
  const newArray = [...arr];
  newArray.splice(index, 0, element);
  return newArray;
};

/** Get random UUID */
export const getRandomUUID = (): string => {
  return crypto.randomUUID();
};

export const getToken = (): string => {
  try {
    const token = localStorage.getItem('Token') ?? Cookies.get('token');
    return token ?? '';
  } catch (error) {
    console.log('error in getToken', error);
    return '';
  }
};

export const fixedNumber = (value: number, fixedLength: number = 2): number =>
  parseFloat(value.toFixed(fixedLength));

// Function to check if the uploaded file's type is allowed
export const isFileTypeAllowed = (
  fileName: string,
  allowedFileTypes: string[]
) => {
  const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
  return allowedFileTypes.includes(fileExtension.toLowerCase());
};

export const getFileNameFromURL = (url: string): string => {
  const decodedURL = decodeURIComponent(url);

  const urlWithoutQuery = decodedURL.split('?')[0];

  const fileName = urlWithoutQuery.substring(
    urlWithoutQuery.lastIndexOf('/') + 1
  );

  const cleanedFileName = fileName.replace(/%20/g, ' ');

  return cleanedFileName;
};

export const handleMinMaxNumberInput = ({
  inputValue,
  max = 99,
  min = 1,
}: {
  inputValue: number;
  min?: number;
  max?: number;
}): number => {
  let value = inputValue;
  if (value >= max) {
    value = max;
  }
  if (value <= min) {
    value = min;
  }
  return value;
};

type NestedObject = { [key: string]: any };
export const deepSortObject = (obj: NestedObject): NestedObject => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(deepSortObject);
  }
  return Object.keys(obj)
    .sort()
    .reduce((result: NestedObject, key: string) => {
      result[key] = deepSortObject(obj[key]);
      return result;
    }, {});
};

export const convertNaNtoZero = (obj: Record<string, any>) => {
  for (let key in obj) {
    if (typeof obj[key] === 'number' && isNaN(obj[key])) {
      obj[key] = 0;
    }
  }
  return obj;
};

export const formatDate = (createdAt: any) => {
  const localDate = new Date(createdAt).toLocaleString('en-US', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return {
    date: moment(localDate).format('DD MMM YYYY'),
    time: moment(localDate).format('hh:mm A'),
  };
};

export const getInputBg = ({
  isHistory = false,
  isDisable = false,
}: {
  isHistory?: boolean;
  isDisable?: boolean;
}) => {
  if (isHistory) return HISTORY_INPUT_BG;
  if (isDisable) return DISABLE_INPUT_BG;
  return '#ffffff';
};

export const formatAddress = (
  // @ts-ignore
  addressComponents: google.maps.GeocoderAddressComponent[] | undefined
) => {
  let streetNo = '';
  let route = '';
  let subLocalityLevel3 = '';
  let subLocalityLevel2 = '';
  let subLocalityLevel1 = '';
  let locality = '';

  // Extract components from address components
  addressComponents?.forEach((component) => {
    const types = component.types;

    if (types.includes('street_number')) {
      streetNo = component.long_name;
    }
    if (types.includes('route')) {
      route = component.long_name;
    }
    if (types.includes('sublocality_level_3')) {
      subLocalityLevel3 = component.long_name;
    }
    if (types.includes('sublocality_level_2')) {
      subLocalityLevel2 = component.long_name;
    }
    if (types.includes('sublocality_level_1')) {
      subLocalityLevel1 = component.long_name;
    }

    if (types.includes('locality')) {
      locality = component.long_name;
    }
  });

  // Build formatted address
  let formattedAddress = '';

  if (subLocalityLevel3 || subLocalityLevel2 || subLocalityLevel1) {
    if (streetNo) formattedAddress += `${streetNo} `;
    if (route) formattedAddress += `${route}, `;
    // if (subLocality) formattedAddress += `${subLocality}, `;
    if (subLocalityLevel3) {
      formattedAddress += `${subLocalityLevel3}, `;
    }
    if (subLocalityLevel2) {
      formattedAddress += `${subLocalityLevel2}, `;
    }
    if (subLocalityLevel1) {
      formattedAddress += `${subLocalityLevel1}, `;
    }
  } else if (locality) {
    formattedAddress += `${locality}`;
  }

  // Remove trailing comma and space
  formattedAddress = formattedAddress.trim();
  if (formattedAddress.endsWith(',')) {
    formattedAddress = formattedAddress.slice(0, -1);
  }

  return formattedAddress;
};

export const createRateLimiter = ({
  callback,
  milliSeconds,
}: {
  callback: Function;
  milliSeconds: number;
}) => {
  let waiting = false;

  return (...args: any) => {
    if (!waiting) {
      // If not waiting, invoke the callback function with arguments
      callback(...args);
      waiting = true;

      // After 1 second, reset waiting to false to allow the function to be called again
      setTimeout(() => {
        waiting = false;
      }, milliSeconds);
    }
    // If waiting, do nothing (function will not be called)
  };
};

// export const getPlanByBillingCycle = (
//   plans: IPlanBase[]
// ): {
//   yearly: IPlanBase[];
//   monthly: IPlanBase[];
// } => {
//   return plans.reduce(
//     (acc, curr) => {
//       if (curr.billingCycle === SubscriptionPlanCycles.monthly) {
//         acc.monthly.push(curr);
//       }
//       if (curr.billingCycle === SubscriptionPlanCycles.yearly) {
//         acc.yearly.push(curr);
//       }
//       return acc;
//     },
//     {
//       yearly: [],
//       monthly: [],
//     } as {
//       yearly: IPlanBase[];
//       monthly: IPlanBase[];
//     } // Explicitly annotate the initial value
//   );
// };
export const getPlanByBillingCycle = (
  plans: IStripePlanBase[]
): {
  yearly: IStripePlanBase[];
  monthly: IStripePlanBase[];
} => {
  return plans.reduce(
    (acc, curr) => {
      if (Object.values(SubscriptionMonthlyPlans).includes(curr?.name as any)) {
        acc.monthly.push(curr);
      }
      if (Object.values(SubscriptionYearlyPlans).includes(curr?.name as any)) {
        acc.yearly.push(curr);
      }
      return acc;
    },
    {
      yearly: [],
      monthly: [],
    } as {
      yearly: IStripePlanBase[];
      monthly: IStripePlanBase[];
    } // Explicitly annotate the initial value
  );
};

export const setPlansByOrder = (
  plans: IStripePlanBase[]
): IStripePlanBase[] => {
  const order = [
    SubscriptionMonthlyPlans?.STARTER,
    SubscriptionYearlyPlans?.STARTER,
    SubscriptionMonthlyPlans?.GROWTH,
    SubscriptionYearlyPlans?.GROWTH,
    SubscriptionMonthlyPlans?.SCALE,
    SubscriptionYearlyPlans?.SCALE,
    SubscriptionMonthlyPlans?.OPTIMIZE,
    SubscriptionYearlyPlans?.OPTIMIZE,
  ];

  const sortedPlanData = plans.sort((a, b) => {
    const aIndex = order.indexOf(a.name as any);
    const bIndex = order.indexOf(b.name as any);

    return aIndex - bIndex;
  });

  return sortedPlanData;
};

export const getOneTimePurchasePlans = (
  plans: IStripePlanBase[]
): IStripePlanBase[] => {
  return plans?.filter(
    (el) => el.name === SunscriptionSingleApplicaitonPlan?.SINGLE_APP
  );
};

export const replaceNullUndefined = (obj: Record<string, any>) => {
  if (typeof obj === 'object' && obj !== null) {
    const newObj: any = Array.isArray(obj) ? [] : {};

    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        // Recursively call replaceNullUndefined for each element
        newObj[i] = replaceNullUndefined(obj[i]);
      }
    } else {
      // If obj is an object, iterate through its properties
      for (let key in obj) {
        // Recursively call replaceNullUndefined for each property value
        newObj[key] = replaceNullUndefined(obj[key]);
      }
    }
    return newObj;
  } else {
    return obj === null || obj === undefined ? '' : obj;
  }
};

export const getPlanFeatureList = (
  planMetaData: stripeMetadata,
  plantype: 'yearly' | 'monthly'
): string[] => {
  const { pricePerYear, totalApplications, avCostPerApplication } =
    planMetaData;
  if (plantype === 'yearly') {
    return [
      `$${Math.trunc(Number(pricePerYear))} per year`,
      `${totalApplications} applications included`,
      `Average cost per application $${Math.trunc(Number(avCostPerApplication))}`,
    ];
  } else {
    return [
      `${totalApplications} applications included`,
      `Average cost per application $${Math.trunc(Number(avCostPerApplication))}`,
    ];
  }
};

export const getPlanName = (name: string) => {
  return name?.split('--')?.[0];
  // if (name?.includes('--')) {
  //   return name?.split('--')?.[0];
  // } else {
  //   return name
  // }
};
export const getPlanTypeFromPlanName = (name: string) => {
  return name?.split('--')?.pop()?.trim();

  //actual 8 plan & top-up plan both
  // if (name && name.includes('--')) {
  //   return name?.split('--')?.pop()?.trim();
  // } else if (name && name.includes('-')) {
  //   return name.split('-')?.pop()?.trim() === 'Per Month' ? 'Monthly' : name.split('-')?.pop()?.trim();
  // } else return ''
};

export const getCurrencyDigit = ({
  number,
  locales = 'en-US',
  options,
}: {
  number: number;
  locales?: Intl.LocalesArgument;
  options?: Intl.NumberFormatOptions;
}): string => {
  return number.toLocaleString(locales, {
    maximumFractionDigits: 0,
    style: 'currency',
    ...options,
  });
};

export const findTopUpPlan = (
  plans: IStripePlanBase[],
  currentPlanType: string,
  currentPlanName: string
) => {
  const targetPlanName = `Top up - ${currentPlanName} - ${currentPlanType}`;
  return plans.find((plan) => plan.name === targetPlanName);
};

export const purchaseHistoryStatusColor = (status: string) => {
  switch (status) {
    case checkoutStatusAll.checkoutSuccess:
      return 'green';
    case checkoutStatusAll.checkoutFailed:
      return 'red';
    case checkoutStatusAll.checkoutOpen:
      return 'gray';
    default:
      break;
  }
};

export const generateRandomColor = () => {
  const colors = [
    'rgba(255, 99, 132, 0.2)', // Light red
    'rgba(54, 162, 235, 0.2)', // Light blue
    'rgba(255, 206, 86, 0.2)', // Light yellow
    'rgba(75, 192, 192, 0.2)', // Light green
    'rgba(153, 102, 255, 0.2)', // Light purple
    'rgba(255, 159, 64, 0.2)', // Light orange
  ];
  const borderColors = [
    'rgba(255, 99, 132, 1)', // Dark red
    'rgba(54, 162, 235, 1)', // Dark blue
    'rgba(255, 206, 86, 1)', // Dark yellow
    'rgba(75, 192, 192, 1)', // Dark green
    'rgba(153, 102, 255, 1)', // Dark purple
    'rgba(255, 159, 64, 1)', // Dark orange
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return {
    backgroundColor: colors[randomIndex],
    borderColor: borderColors[randomIndex],
  };
};

// Function to generate light background and dark border colors
export const generateColorStyles = (color: string) => ({
  bg: `${color}.100`,
  borderColor: `${color}.500`,
});

//decrypt
export const decrypt = (text: string, secretKey: string): string => {
  const textParts = text.split(':');
  const iv = CryptoJS.enc.Hex.parse(textParts.shift()!);
  const key = CryptoJS.enc.Hex.parse(secretKey);
  const encryptedText = textParts.join(':');
  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

//encryptedPayload type string
export const decryptedPayload = (
  encryptedPayload: string,
  secretKey: string
) => {
  return JSON.parse(decrypt(encryptedPayload, secretKey));
};

export const getFileNames = (
  FileArray: {
    link: string;
    linkId: string;
    urlKey: string;
  }[]
) => {
  const FileNames = FileArray?.map((item: any) =>
    item?.urlKey?.split('/').pop()
  )?.join(',');
  return FileNames;
};

export const getS3FileInfo = (fileArray: Record<string, any>) => {
  return fileArray?.map((item: any) => ({
    linkId: item?.encoding,
    link: item?.location,
    urlKey: item?.key,
    updatedInfo: item?.updatedInfo,
  }));
};

export const checkLeapYear = (year: number) => {
  // A year is a leap year if it is divisible by 4, but not divisible by 100,
  // unless it is also divisible by 400.
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getDateFormat = (date: string, format: string) => {
  return moment(date).format(format);
};
