import {
  Box,
  Button,
  Checkbox,
  Flex,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import 'rc-datepicker/lib/style.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  BrokerageCl,
  fetchBrokerageUserData,
} from '../../../Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import { RootState } from '../../../Redux/Store';
import { BrokerageCLformSchema } from '../../../ValidationSchema/Auth/Index';
import useObjectComparator from '../../../hooks/useObjectComparator';
import BrokerageCLform from './BrokerageCLform';

function BrokererageEnterCl() {
  const dispatch = useDispatch();
  const Toast = useToast();
  const navigate = useNavigate();
  const useruidData = useSelector((state: RootState) => state.brokerage.user);
  const [isBrokerageEnterCIUpdate, setIsBrokerageEnterCIUpdate] =
    useState(false);
  let userGetidData = useruidData && useruidData?.brokerageInfo;
  const { areDifferent, setInitialValues, setNewValues } =
    useObjectComparator();

  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        if (!useruidData || !Object.keys(useruidData).length) {
          const actionResult = await dispatch(fetchBrokerageUserData() as any);
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          }
        }
      } else {
        navigate('/login');
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (userGetidData) {
      const { reprimanded_info, hasLicenseSuspensionFine, reprimanded } =
        userGetidData;
      formik.setValues((prevValues) => ({
        ...prevValues,
        reprimanded_info: reprimanded_info || '',
        hasLicenseSuspensionFine: hasLicenseSuspensionFine,
        reprimanded: reprimanded,
      }));
      setInitialValues((prevValues: any) => ({
        ...prevValues,
        reprimanded_info: reprimanded_info || '',
        hasLicenseSuspensionFine: hasLicenseSuspensionFine,
        reprimanded: reprimanded,
      }));
    }
  }, [userGetidData]);

  interface FormValues {
    reprimanded_info: string;
    hasLicenseSuspensionFine: boolean;
    reprimanded: boolean;
  }

  const initialValues: FormValues = {
    reprimanded_info: '',
    hasLicenseSuspensionFine: false,
    reprimanded: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: BrokerageCLformSchema,
    onSubmit: async (values: FormValues) => {
      setIsBrokerageEnterCIUpdate(true);
      let res = '';
      try {
        if (!areDifferent) {
          setIsBrokerageEnterCIUpdate(false);
          return;
        }
        const actionResult = await dispatch(BrokerageCl(values) as any);
        const response = actionResult?.payload;
        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 200) {
          Toast({
            title: response.msg,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          if (resStatus !== 401) {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else {
            navigate('/login');
          }
        }
      } catch (error: any) {
        Toast({
          title: res,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      } finally {
        if (!!localStorage.getItem('Token')) {
          const actionResult = await dispatch(fetchBrokerageUserData() as any);
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          }
        } else {
          navigate('/login');
        }
        setIsBrokerageEnterCIUpdate(false);
      }
    },
  });
  function capitalizeFirstLetter(str: any) {
    return (
      str.charAt(0).toUpperCase() +
      str.slice(1).replace(/[A-Z]/g, (match: any) => ' ' + match.toLowerCase())
    );
  }
  useEffect(() => {
    setNewValues(formik.values);
  }, [formik.values]);
  return (
    <>
      <BrokerageCLform />
      <Box>
        <Box p={5}>
          <form onSubmit={formik.handleSubmit}>
            <Flex justifyContent="space-between">
              <Text fontWeight="500" fontSize={13} color="#114684">
                Has the Brokererage had any license(s) being
                suspended,terminated or being fine?
              </Text>
              <Stack spacing={5} direction="row">
                <Checkbox
                  colorScheme="green"
                  fontWeight="500"
                  isChecked={formik.values.reprimanded === true}
                  onChange={(e) =>
                    formik.setFieldValue('reprimanded', e.target.checked)
                  }
                  // onBlur={formik.handleBlur}
                >
                  Yes{' '}
                </Checkbox>
                <Checkbox
                  colorScheme="red"
                  fontWeight="500"
                  isChecked={formik.values.reprimanded === false}
                  onChange={(e) =>
                    formik.setFieldValue('reprimanded', !e.target.checked)
                  }
                  // onBlur={formik.handleBlur}
                >
                  No
                </Checkbox>
              </Stack>
            </Flex>
            {formik.touched.reprimanded && formik.errors.reprimanded && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {capitalizeFirstLetter(formik.errors.reprimanded)}.
              </div>
            )}
            <Flex justifyContent="space-between" mt={5}>
              <Text fontWeight="500" fontSize={13} color="#114684">
                Has the Brokerage or any licensed individual representing the
                Brokerage been reprimanded by the Regulator in the past 5 years?
              </Text>
              <Stack spacing={5} direction="row">
                <Checkbox
                  colorScheme="green"
                  isChecked={formik.values.hasLicenseSuspensionFine === true}
                  onChange={(e) =>
                    formik.setFieldValue(
                      'hasLicenseSuspensionFine',
                      e.target.checked
                    )
                  }
                  // onBlur={formik.handleBlur}
                  fontWeight="500"
                >
                  Yes
                </Checkbox>
                <Checkbox
                  colorScheme="red"
                  isChecked={formik.values.hasLicenseSuspensionFine === false}
                  onChange={(e) =>
                    formik.setFieldValue(
                      'hasLicenseSuspensionFine',
                      !e.target.checked
                    )
                  }
                  // onBlur={formik.handleBlur}
                  fontWeight="500"
                >
                  No
                </Checkbox>
              </Stack>
            </Flex>
            {formik.touched.hasLicenseSuspensionFine &&
              formik.errors.hasLicenseSuspensionFine && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {capitalizeFirstLetter(
                    formik.errors.hasLicenseSuspensionFine
                  )}
                  .
                </div>
              )}
            {formik.values.hasLicenseSuspensionFine && (
              <Box>
                <Text fontWeight="500" fontSize={13} mt={5} color="#114684">
                  Please list & explain
                  <span style={{ color: 'red' }}>*</span>
                </Text>
                <Textarea
                  placeholder="Please list & explain"
                  mt={2}
                  value={formik.values.reprimanded_info ?? ''}
                  name="reprimanded_info"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                {formik.touched.reprimanded_info &&
                  formik.errors.reprimanded_info && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {formik.errors.reprimanded_info}
                    </div>
                  )}
              </Box>
            )}
            <Flex justifyContent="space-evenly" columnGap={5}>
              <Box mt="20px" w="100%" mb={10}>
                <Button
                  isLoading={isBrokerageEnterCIUpdate}
                  loadingText="Update"
                  type="submit"
                  bg="#114684"
                  color="white"
                  w="100%"
                  _hover={{
                    bg: '#114684',
                    color: 'white',
                    cursor: areDifferent ? 'pointer' : 'not-allowed',
                  }}
                  isDisabled={!areDifferent}
                >
                  Update
                </Button>
              </Box>
            </Flex>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default BrokererageEnterCl;
