import { ArrowRightIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Divider,
  HStack,
  List,
  ListItem,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useDisclosure as useMantineDisclosure } from '@mantine/hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { HiMiniDocumentText } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { AppOutlineButton } from 'src/Component/Common/Micro/AppButtons';
import { useAppToast } from 'src/hooks';
import {
  getAllPlans,
  getPurchasedPlan,
  getPurchasePlanHistory,
  removeType,
} from 'src/Redux/Plans/slice';
import {
  IStripePlanBase,
  PurchasedPlanInfo,
  purchaseHistory,
} from 'src/Redux/Plans/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import {
  findTopUpPlan,
  getCurrencyDigit,
  getPlanName,
  getPlanTypeFromPlanName,
  purchaseHistoryStatusColor,
} from 'src/utils/helpers';
import { TabTypes } from '../PlanAndPurchases';
import PlanBillingSkeleton from './components/PlanBillingSkeleton';
import PlanHistoryDetailsModal from './components/PlanHistoryDetailsModal';
import PurchaseHistorySkeleton from './components/PurchaseHistorySkeleton';
import TopupPlanModal from './components/TopupPlanModal';

const BiilingPage = ({
  setActiveTab,
}: {
  setActiveTab: React.Dispatch<React.SetStateAction<TabTypes>>;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [plantype, setPlantype] = useState<'yearly' | 'monthly'>('yearly');
  const plansStore = useAppSelector((state) => state.plans);
  const [purchasedPlanInfo, setPurchasedPlanInfo] =
    useState<PurchasedPlanInfo | null>(null);
  const [loader, setLoader] = useState<{
    planBilling: boolean;
    purchaseHistory: boolean;
  }>({
    planBilling: false,
    purchaseHistory: false,
  });
  const [allPlans, setAllPlans] = useState<IStripePlanBase[]>([]);
  const [topupPlan, setTopupPlan] = useState<IStripePlanBase | null>(null);
  const [purchaseHistoryData, setPurchaseHistoryData] = useState<
    purchaseHistory[]
  >([]);
  const [purchasePlanDetails, setPurchasePlanDetails] =
    useState<purchaseHistory | null>(null);
  const [
    isOpenPlanDetail,
    { open: onOpenPlanDetail, close: onClosePlanDetail },
  ] = useMantineDisclosure(false);
  const brokerageSlice = useAppSelector((state) => state.brokerage);

  const isPurchasePlan =
    brokerageSlice &&
    brokerageSlice?.user &&
    brokerageSlice?.user?.purchasedPlanInfo &&
    !!Object.keys(brokerageSlice?.user?.purchasedPlanInfo)?.length;

  const handleOpen = () => {
    // setNumApplications(0);
    onOpen();
  };

  useEffect(() => {
    dispatch(getPurchasedPlan());
    dispatch(getPurchasePlanHistory());
    if (!plansStore.plans.length) {
      dispatch(getAllPlans());
    } else {
      setAllPlans(plansStore.plans);
    }

    return () => {
      dispatch(removeType({}));
    };
  }, []);

  useEffect(() => {
    const {
      status,
      type,
      purchasedPlanDetails,
      plans,
      purchaseHistory,
      error,
    } = plansStore;

    switch (status) {
      case 'loading': {
        if (
          type === 'GET_PURCHASED_PLAN_DETAILS' ||
          type === 'GET_PURCHASED_PLAN_HISTORY'
        ) {
          setLoader((prev) => ({
            ...prev,
            planBilling: true,
          }));
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_PURCHASED_PLAN_DETAILS' && purchasedPlanDetails) {
          setPurchasedPlanInfo(purchasedPlanDetails);
          setLoader((prev) => ({ ...prev, planBilling: false }));
        }
        if (type === 'GET_PLANS' && !!plans?.length) {
          setAllPlans(plans);
        }
        if (
          type === 'GET_PURCHASED_PLAN_HISTORY' &&
          !!purchaseHistory?.length
        ) {
          setPurchaseHistoryData(purchaseHistory);
        }
        break;
      }

      case 'failed': {
        if (
          type === 'GET_PURCHASED_PLAN_DETAILS' ||
          type === 'GET_PURCHASED_PLAN_HISTORY'
        ) {
          setLoader((prev) => ({
            ...prev,
            planBilling: false,
            purchaseHistory: false,
          }));
          // toast({
          //   title: error,
          //   status: 'error',
          // });
        }
        break;
      }
    }
  }, [plansStore.status, plansStore.type]);

  useEffect(() => {
    if (!!purchasedPlanInfo && !!allPlans?.length) {
      const planType = getPlanTypeFromPlanName(
        purchasedPlanInfo?.name!
      )?.trim();
      const currentPlanType = planType === 'Monthly' ? 'Per Month' : planType;
      const currentPlanName = getPlanName(purchasedPlanInfo?.name!)?.trim();
      if (!!currentPlanType && !!currentPlanName) {
        const topUpPlan = findTopUpPlan(
          allPlans,
          currentPlanType,
          currentPlanName
        );

        setTopupPlan(topUpPlan!);
      }
    }
  }, [purchasedPlanInfo, allPlans]);

  const handlePlanDetail = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    plan: purchaseHistory
  ) => {
    e.preventDefault();
    setPurchasePlanDetails(plan);
    onOpenPlanDetail();
  };

  console.log('purchasedPlanInfo', purchasedPlanInfo);

  return (
    <>
      {isPurchasePlan ? (
        <HStack
          p={[2, 4, 6]}
          alignItems={'flex-start'}
          spacing={4}
          flexDirection={['column', 'column', 'column', 'column', 'row']}
          wrap={['wrap', 'wrap', 'wrap', 'nowrap']}
          w="100%"
          mx={'auto'}
          display={'flex'}
          justifyContent={'center'}
        >
          {loader?.planBilling ? (
            <PlanBillingSkeleton />
          ) : (
            <VStack
              shadow={'sm'}
              borderWidth="1px"
              borderColor={'gray.400'}
              borderRadius={'xl'}
              p={[4, 6, 6]}
              gap={4}
              flex={1}
              w={'100%'}
              maxW={['100%', '100%', '100%', '100%', '60rem']}
            >
              <VStack alignItems={'flex-start'} w={'100%'} gap={0}>
                <HStack
                  justifyContent={'space-between'}
                  w={'100%'}
                  flexWrap="wrap"
                >
                  <Text as={'h1'} fontWeight={600} fontSize={[16, 18, 20]}>
                    Plans & Billing
                  </Text>
                  {/* <HStack>
                  <Text>M</Text>
                  <Switch
                    onChange={() =>
                      setPlantype((val) =>
                        val === 'yearly' ? 'monthly' : 'yearly'
                      )
                    }
                    isChecked={plantype === 'yearly'}
                  />
                  <Text>Y</Text>
                </HStack> */}
                </HStack>
                <HStack
                  justifyContent={'space-between'}
                  w={'100%'}
                  flexWrap="wrap"
                >
                  <Text fontSize={12}>Manage your plan and payment</Text>
                  {/* <HStack spacing={2}>
                  <AppSolidButton rightIcon={<RiShareBoxLine />}>
                    Manage payment
                  </AppSolidButton>
                </HStack> */}
                </HStack>
              </VStack>
              <Divider borderColor="gray.400" />
              <VStack alignItems="flex-start" w="100%" spacing={4}>
                <HStack justifyContent="space-between" w="100%" flexWrap="wrap">
                  <Text as="h1" fontWeight={600} fontSize={[14, 16]}>
                    Current plan
                  </Text>
                  <HStack>
                    {plantype === 'monthly' && (
                      <AppOutlineButton
                        onClick={() => navigate('/brokerage/plans')}
                      >
                        Buy new plan
                      </AppOutlineButton>
                    )}
                    <AppOutlineButton onClick={handleOpen}>
                      Top Up plan
                    </AppOutlineButton>
                  </HStack>
                </HStack>
                <VStack
                  w="100%"
                  // direction={['column', 'column', 'row']}
                  alignItems="stretch"
                  spacing={4}
                >
                  <VStack
                    flex={1}
                    p={4}
                    border={1}
                    borderColor="gray.300"
                    borderStyle="solid"
                    rounded="lg"
                    alignItems="flex-start"
                    w="100%"
                  >
                    <HStack
                      justifyContent="space-between"
                      w="100%"
                      alignItems={'flex-start'}
                    >
                      <VStack alignItems={'flex-start'}>
                        {purchasedPlanInfo?.name !==
                          'Single Application -- out of subscription' && (
                          <Text fontSize={12}>
                            {getPlanTypeFromPlanName(purchasedPlanInfo?.name!)}{' '}
                            plan
                          </Text>
                        )}
                        <VStack alignItems={'flex-start'} gap={0}>
                          <Text fontWeight={600} fontSize={20}>
                            {getPlanName(purchasedPlanInfo?.name!)}
                          </Text>
                          <HStack gap={0} alignItems="flex-end">
                            <Text fontWeight={600} fontSize={18}>
                              {getCurrencyDigit({
                                number: Math.trunc(
                                  purchasedPlanInfo?.stripeSession
                                    ?.amount_total / 100
                                ),
                                options: {
                                  currency:
                                    purchasedPlanInfo?.stripeSession
                                      ?.currency ?? 'usd',
                                },
                              })}
                            </Text>
                            {getPlanTypeFromPlanName(
                              purchasedPlanInfo?.name!
                            ) !== 'Yearly' &&
                              purchasedPlanInfo?.name !==
                                'Single Application -- out of subscription' && (
                                <>
                                  <Text fontWeight={600} fontSize={18}>
                                    /
                                  </Text>
                                  <Text>month</Text>
                                </>
                              )}
                          </HStack>
                        </VStack>
                      </VStack>
                      <HStack>
                        <Badge
                          variant="subtle"
                          colorScheme={
                            purchasedPlanInfo?.planStatus === 'inactive'
                              ? 'red'
                              : 'green'
                          }
                          px={2}
                          // border={1}
                          // borderStyle="solid"
                          // borderColor="green.700"
                          py={1}
                          rounded="lg"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          gap={1}
                        >
                          <Text
                            bg={
                              purchasedPlanInfo?.planStatus === 'inactive'
                                ? 'red.700'
                                : 'green.700'
                            }
                            w={2}
                            h={2}
                            rounded="100%"
                            mb={0.5}
                          />
                          <Text fontSize={10} textTransform={'uppercase'}>
                            {purchasedPlanInfo?.planStatus}
                          </Text>
                        </Badge>
                      </HStack>
                    </HStack>
                  </VStack>
                  <HStack
                    spacing={4}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <VStack
                      flex={1}
                      border={1}
                      borderColor="gray.300"
                      borderStyle="solid"
                      rounded="lg"
                      p={4}
                      alignItems="flex-start"
                      w="100%"
                      justifyContent={'space-between'}
                    >
                      <HStack w="100%">
                        <Text fontSize={12}>Starting Date</Text>
                      </HStack>
                      <Text fontWeight={600} fontSize={18}>
                        {moment(purchasedPlanInfo?.purchasedDate).format(
                          'D MMMM, YYYY'
                        )}
                      </Text>
                    </VStack>
                    <VStack
                      flex={1}
                      border={1}
                      borderColor="gray.300"
                      borderStyle="solid"
                      rounded="lg"
                      p={4}
                      alignItems="flex-start"
                      w="100%"
                      justifyContent={'space-between'}
                    >
                      <HStack w="100%">
                        <Text fontSize={12}>Expiry Date</Text>
                      </HStack>
                      <Text fontWeight={600} fontSize={18}>
                        {moment(purchasedPlanInfo?.planExpiryDate).format(
                          'D MMMM, YYYY'
                        )}
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>
              </VStack>
              <Divider borderColor="gray.400" />
              <VStack alignItems="flex-start" w="100%" spacing={4}>
                <VStack alignItems="flex-start" gap={0}>
                  <Text as="h1" fontWeight={600} fontSize={[14, 16]}>
                    Usage
                  </Text>
                  <Text as="h6" fontSize={12}>
                    Your usage expires on{' '}
                    {moment(purchasedPlanInfo?.planExpiryDate).format(
                      'D MMMM, YYYY'
                    )}
                    .
                  </Text>
                </VStack>
                <VStack
                  gap={4}
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  border={1}
                  borderStyle="solid"
                  borderColor="gray.300"
                  w={'100%'}
                  rounded="lg"
                  p={4}
                >
                  <HStack alignItems={'center'}>
                    <Box
                      border={1}
                      borderStyle="solid"
                      borderColor="gray.300"
                      w="min-content"
                      p={2}
                      rounded="100%"
                    >
                      <HiMiniDocumentText size={22} />
                    </Box>
                    <Text>Applications</Text>
                  </HStack>
                  <HStack
                    alignItems={'stretch'}
                    width={'100%'}
                    flexWrap="wrap"
                    spacing={4}
                  >
                    <VStack
                      flex={1}
                      alignItems={'flex-start'}
                      justifyContent={'space-between'}
                      border={1}
                      borderColor="gray.300"
                      borderStyle="solid"
                      rounded="lg"
                      p={4}
                    >
                      <Text mb={1}>Applications Purchased</Text>
                      <Text fontWeight={600} fontSize={18}>
                        {purchasedPlanInfo?.totalApplications}
                      </Text>
                    </VStack>
                    <VStack
                      flex={1}
                      alignItems={'flex-start'}
                      justifyContent={'space-between'}
                      border={1}
                      borderColor="gray.300"
                      borderStyle="solid"
                      rounded="lg"
                      p={4}
                    >
                      <Text mb={1}>Application Used</Text>
                      <Text fontWeight={600} fontSize={18}>
                        {purchasedPlanInfo?.usedApplications}
                      </Text>
                    </VStack>
                    <VStack
                      flex={1}
                      alignItems={'flex-start'}
                      justifyContent={'space-between'}
                      border={1}
                      borderColor="gray.300"
                      borderStyle="solid"
                      rounded="lg"
                      p={4}
                    >
                      <Text mb={1}>Applications Remaining</Text>
                      <Text fontWeight={600} fontSize={18}>
                        {purchasedPlanInfo?.remainingApplications}
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>
              </VStack>
            </VStack>
          )}
          {loader?.planBilling ? (
            <PurchaseHistorySkeleton />
          ) : (
            <VStack
              alignItems={'start'}
              shadow={'sm'}
              borderWidth="1px"
              borderColor={'gray.400'}
              borderRadius={'xl'}
              p={[4, 6, 6]}
              gap={4}
              flex={1}
              maxW={['100%', '100%', '100%', '100%', '30rem']}
              w={'100%'}
            >
              <VStack alignItems={'flex-start'} w={'100%'} gap={0}>
                <Text as={'h1'} fontWeight={600} fontSize={[16, 18, 20]}>
                  Purchase History
                </Text>
                <HStack
                  justifyContent={'space-between'}
                  w={'100%'}
                  flexWrap="wrap"
                >
                  <Text lineHeight={2.8} fontSize={12}>
                    There are {purchaseHistoryData?.length} total invoices
                  </Text>
                </HStack>
              </VStack>
              <Divider borderColor="gray.400" />
              <List
                spacing={3}
                styleType={'none'}
                w={'100%'}
                border="1px"
                borderColor={'gray.300'}
                rounded={'lg'}
                p={4}
                maxH={'497px'}
                overflowY={'auto'}
              >
                {purchaseHistoryData?.map((invoice) => {
                  const {
                    stripeId,
                    checkoutSessionStatus,
                    purchasedDate,
                    stripeSession,
                    legalName,
                  } = invoice;
                  const { amount_total, currency } = stripeSession;
                  return (
                    <ListItem
                      key={stripeId}
                      borderBottom="1px"
                      borderColor={'gray.300'}
                      w={'100%'}
                      pb={4}
                      _last={{
                        border: 0,
                        pb: 0,
                      }}
                    >
                      <HStack
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <VStack gap={0} alignItems={'flex-start'}>
                          <Text fontWeight={600}>{legalName}</Text>
                          {/* <Text color={'gray.500'} fontSize={12}>
                          #{id}
                        </Text> */}
                          <Text>
                            {moment(purchasedDate).format(
                              'DD MMMM, YYYY hh:mm A'
                            )}
                          </Text>
                        </VStack>
                        <HStack gap={3}>
                          <VStack
                            gap={0}
                            alignItems={'flex-start'}
                            minW={'55px'}
                          >
                            <Text fontSize={18} fontWeight={600}>
                              {getCurrencyDigit({
                                number: Math.trunc(amount_total / 100),
                                options: {
                                  currency,
                                },
                              })}
                            </Text>
                            <Badge
                              variant="solid"
                              colorScheme={purchaseHistoryStatusColor(
                                checkoutSessionStatus
                              )}
                              textTransform={'uppercase'}
                            >
                              {checkoutSessionStatus === 'complete'
                                ? 'success'
                                : checkoutSessionStatus}
                            </Badge>
                          </VStack>
                          <ArrowRightIcon
                            cursor={'pointer'}
                            onClick={(e) => handlePlanDetail(e, invoice)}
                          />
                        </HStack>
                      </HStack>
                    </ListItem>
                  );
                })}
              </List>
            </VStack>
          )}
        </HStack>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={'calc(100vh - 265px)'}
          // bg="#f9f9f9"
        >
          <Box
            border="1px solid #ddd"
            borderRadius="8px"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            px="20px"
            py="35px"
            bg="white"
            maxWidth="400px"
            textAlign="center"
          >
            <Box>
              <Text
                as="h1"
                fontSize="x-large"
                fontWeight="bold"
                color="#333"
                mb={2}
              >
                No Active Plan Found
              </Text>
              <Text as="p" fontSize="lg" color="#555">
                There is no active plan, please purchase a plan{' '}
                <Text
                  as="a"
                  // href="/brokerage/plans"
                  color="blue.500"
                  textDecoration="underline"
                  cursor="pointer"
                  onClick={() => setActiveTab('Plans')}
                >
                  here
                </Text>
              </Text>
            </Box>
          </Box>
        </Box>
      )}
      <TopupPlanModal
        isOpen={isOpen}
        onClose={onClose}
        purchasedPlanInfo={purchasedPlanInfo}
        topupPlanInfo={topupPlan}
      />
      {isOpenPlanDetail && (
        <PlanHistoryDetailsModal
          isOpen={isOpenPlanDetail}
          onClose={onClosePlanDetail}
          planDetails={purchasePlanDetails}
        />
      )}
    </>
  );
};

export default BiilingPage;
