import { hasNonNullOrObject } from 'src/Pages/BrokerPages/CreateApplicationForm/utils';
import * as Yup from 'yup';

// define small schemas (utils)
export const requiredCheckbox = (message?: string) =>
  Yup.boolean().required(message || 'Please select at least one option');

export const AccoutingSystemSchema = Yup.object({
  legalName: Yup.string()
    .trim()
    .min(2, 'Legal name must be at least 2 characters long')
    .required('Legal name is required'),
  email: Yup.string()
    .email()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    // .matches(
    //   /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
    //   "Email must be a valid email address"
    // )
    .required('Email address is required'),
  mailingAddressSameAsOffice: Yup.boolean()
    .oneOf([true, false], 'Please select at least one option')
    .required('This field is required.')
    .nonNullable('Please Select one of the option provided.'),
  street: Yup.string()
    .trim()
    .min(2, 'Street must be at least 2 characters long')
    .required('Street is required'),
  city: Yup.string()
    .trim()
    .min(2, 'City must be at least 2 characters long')
    .required('City is required'),
  province: Yup.string()
    .trim()
    .min(2, 'Province must be at least 2 characters long')
    .required('Province is required'),
  postalCode: Yup.string()
    .trim()
    .min(2, 'Postal code must be at least 2 characters long')
    .required('Postal code is required'),
  country: Yup.string()
    .trim()
    .min(2, 'Country must be at least 2 characters long')
    .required('Country is required'),
  // grossProfitAtRecentFiscalYearEnd: Yup.number().required(
  //   "Gross profit is required"
  // ),
  dateCompleted: Yup.string().trim().required('Date field is required'),
  accountingFirmName: Yup.string()
    .trim()
    .min(2, 'This field must be at least 2 characters long')
    .required('Name of accouting firm is required'),
  // interimStatementInterval: Yup.string()
  //   .trim()
  //   .min(2, "This field must be at least 2 characters long")
  //   .required("This field is required"),
  // bankName: Yup.string()
  //   .trim()
  //   .min(2, "Bank must be at least 2 characters long")
  //   .required("Bank is required"),
  // authorizedLineOfCredit: Yup.number().required("This field is required"),
  // usedLineOfCredit: Yup.number().required("This field is required"),
  // bankInfo: Yup.array().of(
  //   Yup.object().shape({
  //     address: Yup.string().trim().required("This field is required"),
  //     bankName: Yup.string().trim().required("This field is required"),
  //     // authorizedLineOfCredit: Yup.number().required("This field is required"),
  //     usedLineOfCredit: Yup.number().required("This field is required"),
  //   })
  // ),
  // email address
  mailingStreet: Yup.string().when(
    'mailingAddressSameAsOffice',
    (mailingAddressSameAsOffice, schema) => {
      const mailAddressSameAsOffice = Array.isArray(mailingAddressSameAsOffice)
        ? mailingAddressSameAsOffice.includes(true)
        : mailingAddressSameAsOffice === true;
      return !mailAddressSameAsOffice
        ? schema
            .trim()
            .min(2, 'Street must be at least 2 characters long')
            .required('Street is required')
        : schema.notRequired();
    }
  ),
  mailingCity: Yup.string().when(
    'mailingAddressSameAsOffice',
    (mailingAddressSameAsOffice, schema) => {
      const mailAddressSameAsOffice = Array.isArray(mailingAddressSameAsOffice)
        ? mailingAddressSameAsOffice.includes(true)
        : mailingAddressSameAsOffice === true;
      return !mailAddressSameAsOffice
        ? schema
            .trim()
            .min(2, 'City must be at least 2 characters long')
            .required('City required')
        : schema.notRequired();
    }
  ),
  mailingProvince: Yup.string().when(
    'mailingAddressSameAsOffice',
    (mailingAddressSameAsOffice, schema) => {
      const mailAddressSameAsOffice = Array.isArray(mailingAddressSameAsOffice)
        ? mailingAddressSameAsOffice.includes(true)
        : mailingAddressSameAsOffice === true;
      return !mailAddressSameAsOffice
        ? schema
            .trim()
            .min(2, 'Province must be at least 2 characters long')
            .required('Province is required')
        : schema.notRequired();
    }
  ),
  mailingPostalCode: Yup.string().when(
    'mailingAddressSameAsOffice',
    (mailingAddressSameAsOffice, schema) => {
      const mailAddressSameAsOffice = Array.isArray(mailingAddressSameAsOffice)
        ? mailingAddressSameAsOffice.includes(true)
        : mailingAddressSameAsOffice === true;
      return !mailAddressSameAsOffice
        ? schema
            .trim()
            .min(2, 'Postal code must be at least 2 characters long')
            .required('Postal code is required')
        : schema.notRequired();
    }
  ),
  mailingCountry: Yup.string().when(
    'mailingAddressSameAsOffice',
    (mailingAddressSameAsOffice, schema) => {
      const mailAddressSameAsOffice = Array.isArray(mailingAddressSameAsOffice)
        ? mailingAddressSameAsOffice.includes(true)
        : mailingAddressSameAsOffice === true;
      return !mailAddressSameAsOffice
        ? schema
            .trim()
            .min(2, 'Country must be at least 2 characters long')
            .required('Country required')
        : schema.notRequired();
    }
  ),
  bankInfo: Yup.array().of(
    Yup.object().shape({
      authorizedLineOfCredit: Yup.number().nullable().notRequired(),
      usedLineOfCredit: Yup.number()
        .nullable()
        .notRequired()
        .transform((value, originalValue) => {
          return originalValue === '' ? undefined : value;
        })
        .when('authorizedLineOfCredit', (authorizedLineOfCredit, schema) => {
          return schema.test('is-valid-usedLineOfCredit', function (value) {
            // Get the context of the current values
            const { path, createError } = this;
            if (value === null || value === undefined) {
              return true;
            } else if (
              (value !== undefined || value !== null) &&
              authorizedLineOfCredit[0] === undefined
            ) {
              return createError({
                path,
                message: 'Please enter authorized line of credit',
              });
            } else if (
              value !== undefined &&
              authorizedLineOfCredit[0] !== undefined &&
              value > authorizedLineOfCredit[0]
            ) {
              return createError({
                path,
                message: 'Used amount cannot exceed authorized amount',
              });
            }

            return true;
          });
        }),
    })
  ),
});

export const CreditBureauSchema = (formData: Record<string, any>) => {
  return Yup.object({
    receivershipOrBankruptcyHistory: Yup.object().shape({
      hasHistory: requiredCheckbox(),
      details: Yup.string().when('hasHistory', (hasHistory, schema, parent) => {
        // console.log(
        //   'parent-receivershipOrBankruptcyHistory-validation',
        //   parent
        // );

        //stepper validate function -> need to manually pass context(on Credit Bureau form we can get parent.context so below code work)
        // const isReceivershipOrBankruptcyAcc =
        //   parent?.context?.receivershipOrBankruptcyAcc &&
        //   parent?.context?.receivershipOrBankruptcyAcc?.length > 0;
        const isReceivershipOrBankruptcyAcc =
          formData?.hasBankruptcyHistory &&
          ((formData?.hasBankruptcyHistory?.bankruptcyDetails &&
            !!formData?.hasBankruptcyHistory?.bankruptcyDetails?.length) ||
            (formData?.hasBankruptcyHistory?.insolvencyDetails &&
              !!formData?.hasBankruptcyHistory?.insolvencyDetails?.length) ||
            (formData?.hasBankruptcyHistory?.liquidationDetails &&
              !!formData?.hasBankruptcyHistory?.liquidationDetails?.length));
        const isHasHistoryTrue = Array.isArray(hasHistory)
          ? hasHistory.includes(true)
          : hasHistory === true;
        return isHasHistoryTrue && !isReceivershipOrBankruptcyAcc
          ? schema.trim().required('This field is required')
          : schema.notRequired();
      }),
    }),
    receivershipOrBankruptcyAcc: Yup.array().nullable(),

    guaranteesForOtherParties: Yup.object().shape({
      hasGuarantees: requiredCheckbox(),
      details: Yup.string().when(
        'hasGuarantees',
        (hasGuarantees, schema, parent) => {
          // console.log('parent', parent, formData, schema);
          // const isHasGuaranteesAcc = parent?.context?.hasGuarantees
          //   ? true
          //   : false;
          // const isHasGuaranteesAcc = checkHasGuarantees(formData);
          const isHasGuaranteesAcc = hasNonNullOrObject(
            formData?.hasGuarantees
          );
          const isHasGuaranteesTrue = Array.isArray(hasGuarantees)
            ? hasGuarantees.includes(true)
            : hasGuarantees === true;

          return isHasGuaranteesTrue && !isHasGuaranteesAcc
            ? schema.trim().required('This field is required')
            : schema.notRequired();
        }
      ),
    }),

    hasGuarantees: Yup.object().nullable(),

    // relatedCompanies: Yup.object().shape({
    //   hasRelatedCompanies: Yup.boolean().oneOf(
    //     [true, false],
    //     'Please select at least one option'
    //   ),
    //   companies: Yup.array().when(
    //     'hasRelatedCompanies',
    //     (hasRelatedCompanies, schema) => {
    //       const isHasChangesTrue = Array.isArray(hasRelatedCompanies)
    //         ? hasRelatedCompanies.includes(true)
    //         : hasRelatedCompanies === true;
    //       if (isHasChangesTrue) {
    //         return schema.of(
    //           Yup.object().shape({
    //             name: Yup.string()
    //               .trim()

    //               .required('This field is required'),
    //           })
    //         );
    //       } else {
    //         return schema;
    //       }
    //     }
    //   ),
    // }),

    changesInManagement: Yup.object().shape({
      hasChanges: requiredCheckbox(),
      explanation: Yup.string().when(
        'hasChanges',
        (hasChanges, schema, parent) => {
          // console.log('parent', parent?.context?.hasChangesInManagement);
          // const isHasChangesInManagementAcc = parent?.context
          //   ?.hasChangesInManagement
          //   ? true
          //   : false;
          const isHasChangesInManagementAcc = hasNonNullOrObject(
            formData?.hasChangesInManagement
          );
          const isHasChangesTrue = Array.isArray(hasChanges)
            ? hasChanges.includes(true)
            : hasChanges === true;
          return isHasChangesTrue && !isHasChangesInManagementAcc
            ? schema.trim().required('This field is required')
            : schema.notRequired();
        }
      ),
    }),

    hasChangesInManagement: Yup.object().nullable(),

    liensFiledAgainstCompany: Yup.object().shape({
      hasLiens: requiredCheckbox(),
      // amount: Yup.string().when("hasLiens", (hasLiens, schema) => {
      //   const isHasLiensTrue = Array.isArray(hasLiens)
      //     ? hasLiens.includes(true)
      //     : hasLiens === true;
      //   return isHasLiensTrue
      //     ? schema.trim().required("This field is required")
      //     : schema.notRequired();
      // }),
      details: Yup.string().when('hasLiens', (hasLiens, schema, parent) => {
        const isHasLiensTrue = Array.isArray(hasLiens)
          ? hasLiens.includes(true)
          : hasLiens === true;
        // const isLiensFiledAgainstCompany =
        //   parent?.context?.hasLiensFiledAgainstCompany &&
        //   parent?.context?.hasLiensFiledAgainstCompany?.length > 0;
        const isLiensFiledAgainstCompany =
          formData?.hasLiensFiledAgainstCompany &&
          !!formData?.hasLiensFiledAgainstCompany?.length;
        return isHasLiensTrue && !isLiensFiledAgainstCompany
          ? schema.trim().required('This field is required')
          : schema.notRequired();
      }),
    }),

    outstandingJudgments: Yup.object().shape({
      hasOutstandingJudgments: requiredCheckbox(),
      details: Yup.string().when(
        'hasOutstandingJudgments',
        (hasOutstandingJudgments, schema, parent) => {
          const isHasOutstandingJudgmentsTrue = Array.isArray(
            hasOutstandingJudgments
          )
            ? hasOutstandingJudgments.includes(true)
            : hasOutstandingJudgments === true;

          // const isOutstandingJudgments =
          //   parent?.context?.outstandingJudgmentsAcc &&
          //   parent?.context?.outstandingJudgmentsAcc?.length > 0;
          const isOutstandingJudgments =
            (formData?.hasOutstandingJudgments &&
              !!formData?.hasOutstandingJudgments?.length) ||
            (formData?.hasOutstandingSuits &&
              !!formData?.hasOutstandingSuits?.length) ||
            (formData?.hasOutstandingClaims &&
              !!formData?.hasOutstandingClaims?.length);
          return isHasOutstandingJudgmentsTrue && !isOutstandingJudgments
            ? schema.trim().required('This field is required')
            : schema.notRequired();
        }
      ),
    }),
    disputesWithOthers: Yup.object().shape({
      hasDisputes: requiredCheckbox(),
      details: Yup.string().when('hasDisputes', (hasDisputes, schema) => {
        const isHasDisputesTrue = Array.isArray(hasDisputes)
          ? hasDisputes.includes(true)
          : hasDisputes === true;
        return isHasDisputesTrue
          ? schema.trim().required('This field is required')
          : schema.notRequired();
      }),
    }),
    liensFiledByCompany: Yup.object().shape({
      hasLiens: requiredCheckbox(),
      details: Yup.string().when('hasLiens', (hasLiens, schema) => {
        const isHasLiensTrue = Array.isArray(hasLiens)
          ? hasLiens.includes(true)
          : hasLiens === true;
        return isHasLiensTrue
          ? schema.trim().required('This field is required')
          : schema.notRequired();
      }),
    }),
    disputesWithSuppliers: Yup.object().shape({
      hasDisputes: requiredCheckbox(),
      details: Yup.string().when('hasDisputes', (hasDisputes, schema) => {
        const isHasDisputesTrue = Array.isArray(hasDisputes)
          ? hasDisputes.includes(true)
          : hasDisputes === true;
        return isHasDisputesTrue
          ? schema.trim().required('This field is required')
          : schema.notRequired();
      }),
      fundingMeans: Yup.string().when('hasDisputes', (hasDisputes, schema) => {
        const isHasDisputesTrue = Array.isArray(hasDisputes)
          ? hasDisputes.includes(true)
          : hasDisputes === true;
        return isHasDisputesTrue
          ? schema.trim().required('This field is required')
          : schema.notRequired();
      }),
    }),
    // buySellAgreement: Yup.boolean().nullable().notRequired(),
    // agreementDetails: Yup.string().when(
    //   'buySellAgreement',
    //   (buySellAgreement, schema) => {
    //     const isSellAgreementTrue = Array.isArray(buySellAgreement)
    //       ? buySellAgreement.includes(true)
    //       : buySellAgreement === true;
    //     return isSellAgreementTrue
    //       ? schema.trim().required('This field is required')
    //       : schema.notRequired();
    //   }
    // ),
    suretyMade: Yup.boolean().nullable(),
    whyNotMade: Yup.string().when('suretyMade', ([], schema, parent) => {
      const { suretyMade, bondClaims, claimsPaid } = parent?.context || {};
      const isSuretyMade = bondClaims && claimsPaid && !suretyMade;
      return isSuretyMade
        ? schema.trim().required('This field is required')
        : schema.notRequired();
    }),
  });
};
const validateSalesPercentage = ({
  data,
  key = '',
}: {
  data: any;
  key: string;
}) => {
  if (!data?.length) return true;
  const totalPercentage = data.reduce(
    (sum: number, item: any) => sum + item[key],
    0
  );
  return totalPercentage == 100;
};

export const CmsSchema = Yup.object({
  salesPercentageByOwnerType: Yup.array().test(
    'total-salesPercentageByOwnerType',
    'Annual sales percentages must total 100%',
    (values) =>
      validateSalesPercentage({
        data: values,
        key: 'salesPercentage',
      })
  ),
  fixedContract: Yup.array().test(
    'total-fixedContract',
    'Annual sales percentages must total 100%',
    (values) =>
      validateSalesPercentage({
        data: values,
        key: 'annualSales',
      })
  ),
  contractors: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required('Please select at least one option'),
        // type: Yup.string().oneOf(
        //   ["General Building", "Paving", "Electrical", "Excavation", "Concrete", "Framing", "Roofing/Siding/Sheet metal work", "Plumbing", "HVAC", "Carpentry", "Masonry/Stonework", "Plastering", "Painting/ Paper hanging", "Tile Setting", "Others"],
        //   "Please select at least one option"
        // ).required('Please select at least one option'),
        // annualSalesPercentage: Yup.number().required("This field is required"),
        // annualSalesPercentage: Yup.number().when('contractors', ([], schema,parent) => {
        //   return parent && parent?.context?.contractors?.length > 1 ? schema.required('This field is required') : schema.notRequired();
        // }),
      })
    )
    .min(1, 'Please select at least one option'),
  contractorTypeOther: Yup.string().when(
    'contractors',
    (contractors, schema) => {
      const iscontractorTypeOther =
        Array.isArray(contractors) &&
        contractors[0]?.map((item: any) => item.type).includes('Others');
      return iscontractorTypeOther
        ? schema.trim().required('This field is required')
        : schema.notRequired();
    }
  ),
  workSublet: Yup.array().min(1, 'Please select at least one option'),
  workSubletOther: Yup.string().when('workSublet', (workSublet, schema) => {
    const isworkSubletOther =
      Array.isArray(workSublet) &&
      workSublet[0]?.map((item: any) => item.type).includes('Others');
    return isworkSubletOther
      ? schema.trim().required('This field is required')
      : schema.notRequired();
  }),
  typeOfWork: Yup.array().min(1, 'Please select at least one option'),
  largestWorkAmount: Yup.number().required('This filed is required'),
  numberOfContracts: Yup.number().required('This filed is required'),
  workSubletPercentage: Yup.number().required('This filed is required'),
  workUndertakenOwnForces: Yup.string()
    .trim()
    .required('This field is required'),
  estimatedSubletBudget: Yup.number().required('This field is required'),
  ownerName: Yup.string().trim().required('This field is required'),
  contractPrice: Yup.number().required('This field is required'),
  obtainBondsFromSubcontractors: Yup.object({
    hasBonds: Yup.boolean()
      .oneOf([true, false], 'Please select at least one option')
      .nonNullable('Please select one of the options provided.'),
    minimumContract: Yup.string().when('hasBonds', (hasBonds, schema) => {
      const ishasBonds = Array.isArray(hasBonds)
        ? hasBonds.includes(true)
        : hasBonds === true;
      return ishasBonds
        ? Yup.string().required('This field is required')
        : schema.notRequired();
    }),
  }),
  workOutsideCanada: Yup.object().shape({
    performWorkOutside: requiredCheckbox(),
    annualSalesPercentage: Yup.array().when(
      'performWorkOutside',
      (performWorkOutside, schema) => {
        const isHasChangesTrue = Array.isArray(performWorkOutside)
          ? performWorkOutside.includes(true)
          : performWorkOutside === true;
        if (isHasChangesTrue) {
          return schema.of(
            Yup.object().shape({
              country: Yup.string().trim().required('This field is required'),
              percentage: Yup.string()
                .trim()
                .required('This field is required'),
            })
          );
        } else {
          return schema;
        }
      }
    ),
  }),
  typeOfProjectOwner: Yup.object({
    type: Yup.string().trim().required('This field is required'),
    otherType: Yup.string().when('type', (type, schema) => {
      const isotherType = Array.isArray(type)
        ? type.includes('Others')
        : type === 'Others';
      return isotherType
        ? Yup.string().trim().required('This field is required')
        : schema.notRequired();
    }),
  }),
  // keyPersonnel: Yup.array().of(
  //   Yup.object().shape({
  //     name: Yup.string().required('Name is required'),
  //   })
  // ),
});
export const PersonalFinancialInfoSchema = Yup.object({
  ownerInfo: Yup.object().shape({
    nameHoldco: Yup.string().trim().nullable(),
    dateOfBirth: Yup.date().nullable(),
    // .transform((value, originalValue) => {
    //   return originalValue instanceof Date ? originalValue : null;
    // })
    ownershipPercentage: Yup.number()
      .nullable()
      .max(100, 'Ownership percentage must total 100%'),
  }),
  assetsResidential: Yup.object().shape({
    address: Yup.string().trim().nullable(),
    value: Yup.number().nullable(),
  }),
  assetsRealEstate: Yup.array().of(
    Yup.object().shape({
      address: Yup.string().trim().nullable(),
      value: Yup.number().nullable(),
    })
  ),
  assetsPrice: Yup.array().of(
    Yup.object().shape<Record<string, any>>({
      type: Yup.string().trim().nullable(),
      value: Yup.number().nullable(),
      otherAssetType: Yup.string().when('type', ([type], schema) => {
        return type === 'Others' ? schema : schema.nullable();
      }),
    })
  ),
  // gic: Yup.array().of(
  //   Yup.object().shape({
  //     cash: Yup.string().trim(),
  //     cashValue: Yup.number(),
  //   })
  // ),
  // assetsRrsp: Yup.array().of(
  //   Yup.object().shape({
  //     stocksBonds: Yup.string().trim(),
  //     bondsValue: Yup.number(),
  //   })
  // ),
  businessInterests: Yup.array().of(
    Yup.object().shape({
      legalName: Yup.string().trim().nullable(),
      value: Yup.number().nullable(),
      ownershipPercentage: Yup.number().nullable(),
    })
  ),
  // liabilitiesAddress: Yup.array().of(
  //   Yup.object().shape({
  //     type: Yup.string().trim(),
  //     value: Yup.number(),
  //     address: Yup.number(),
  //   })
  // ),
  liabilitiesResidence: Yup.object().shape({
    mortgage: Yup.string().trim().nullable(),
    value: Yup.number().nullable(),
  }),
  liabilitiesRealEstate: Yup.array().of(
    Yup.object().shape({
      // address: Yup.string().trim(),
      mortgage: Yup.string().trim().nullable(),

      value: Yup.number().nullable(),
    })
  ),
  liabilitiesPrice: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().trim().nullable(),
      value: Yup.number().nullable(),
      otherLiabilityType: Yup.string().when('type', ([type], schema) => {
        return type === 'Others' ? schema : schema.nullable();
      }),
    })
  ),
  totalLiabilities: Yup.number().nullable(),
  totalAssets: Yup.number().nullable(),
  personalNetWorth: Yup.number().nullable(),
});

export const suretyvalidationSchema = Yup.object({
  refusedBond: Yup.boolean()
    .oneOf([true, false], 'Please select at least one option')
    .nullable(),
  // refusedBondExplanation: Yup.string().when(
  //   'refusedBond',
  //   (refusedBond, schema) => {
  //     const isHasDisputesTrue = Array.isArray(refusedBond)
  //       ? refusedBond.includes(true)
  //       : refusedBond === true;
  //     return isHasDisputesTrue
  //       ? schema.trim().required('This field is required')
  //       : schema.notRequired();
  //   }
  // ),
});
export const InsuranceSchema = Yup.object({
  keyManLifeInsurance: Yup.object()
    .shape({
      hasKeyManLifeInsurance: Yup.boolean()
        .oneOf([true, false], 'Please select at least one option')
        .nullable(),
      // details: Yup.array()
      // .when(
      //   'hasKeyManLifeInsurance',
      //   (hasKeyManLifeInsurance, schema) => {
      //     const isHasChangesTrue = Array.isArray(hasKeyManLifeInsurance)
      //       ? hasKeyManLifeInsurance.includes(true)
      //       : hasKeyManLifeInsurance === true;
      //     if (isHasChangesTrue) {
      //       return schema.of(
      //         Yup.object().shape({
      //           keyPerson: Yup.string().trim(),
      //           amount: Yup.number(),
      //           beneficiary: Yup.string().trim(),
      //         })
      //       );
      //     } else {
      //       return schema;
      //     }
      //   }
      // ),
    })
    .nullable(),
  commercialGeneralLiabilityInsurance: Yup.object()
    .shape({
      hasCommercialGeneralLiabilityInsurance: Yup.boolean()
        .oneOf([true, false], 'Please select at least one option')
        .nullable(),
      limit: Yup.string().nullable(),
      // .when(
      //   'hasCommercialGeneralLiabilityInsurance',
      //   (hasCommercialGeneralLiabilityInsurance, schema) => {
      //     const isHasDisputesTrue = Array.isArray(
      //       hasCommercialGeneralLiabilityInsurance
      //     )
      //       ? hasCommercialGeneralLiabilityInsurance.includes(true)
      //       : hasCommercialGeneralLiabilityInsurance === true;
      //     return isHasDisputesTrue ? schema.trim() : schema.notRequired();
      //   }
      // ),
    })
    .nullable(),
  environmentalInsurance: Yup.object()
    .shape({
      hasEnvironmentalInsurance: Yup.boolean()
        .oneOf([true, false], 'Please select at least one option')
        .nullable(),
      limit: Yup.string().nullable(),
      // .when(
      //   'hasEnvironmentalInsurance',
      //   (hasEnvironmentalInsurance, schema) => {
      //     const isHasDisputesTrue = Array.isArray(hasEnvironmentalInsurance)
      //       ? hasEnvironmentalInsurance.includes(true)
      //       : hasEnvironmentalInsurance === true;
      //     return isHasDisputesTrue ? schema.trim() : schema.notRequired();
      //   }
      // ),
    })
    .nullable(),
  errorsAndOmissionsInsurance: Yup.object()
    .shape({
      hasErrorsAndOmissionsInsurance: Yup.boolean()
        .oneOf([true, false], 'Please select at least one option')
        .nullable(),
      limit: Yup.string().nullable(),
      // .when(
      //   'hasErrorsAndOmissionsInsurance',
      //   (hasErrorsAndOmissionsInsurance, schema) => {
      //     const isHasDisputesTrue = Array.isArray(hasErrorsAndOmissionsInsurance)
      //       ? hasErrorsAndOmissionsInsurance.includes(true)
      //       : hasErrorsAndOmissionsInsurance === true;
      //     return isHasDisputesTrue ? schema.trim() : schema.notRequired();
      //   }
      // ),
    })
    .nullable(),
  fidelityInsurance: Yup.object()
    .shape({
      hasFidelityInsurance: Yup.boolean()
        .oneOf([true, false], 'Please select at least one option')
        .nullable(),
      limit: Yup.string().nullable(),
      // .when(
      //   'hasFidelityInsurance',
      //   (hasFidelityInsurance, schema) => {
      //     const isHasDisputesTrue = Array.isArray(hasFidelityInsurance)
      //       ? hasFidelityInsurance.includes(true)
      //       : hasFidelityInsurance === true;
      //     return isHasDisputesTrue ? schema.trim() : schema.notRequired();
      //   }
      // ),
    })
    .nullable(),
  equipmentInsurance: Yup.object()
    .shape({
      hasEquipmentInsurance: Yup.boolean()
        .oneOf([true, false], 'Please select at least one option')
        .nullable(),
      limit: Yup.string().nullable(),
      // .when(
      //   'hasEquipmentInsurance',
      //   (hasEquipmentInsurance, schema) => {
      //     const isHasDisputesTrue = Array.isArray(hasEquipmentInsurance)
      //       ? hasEquipmentInsurance.includes(true)
      //       : hasEquipmentInsurance === true;
      //     return isHasDisputesTrue ? schema.trim() : schema.notRequired();
      //   }
      // ),
    })
    .nullable(),
});

const today = new Date();

export const OwnerSchema = Yup.object({
  firstName: Yup.string().trim().required('First name is required'),
  lastName: Yup.string().trim().required('Last name is required'),

  email: Yup.string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Email is required'),
  contactNumber: Yup.string()
    .trim()
    .required('Contact number is required')
    .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
  SIN_SSN: Yup.string()
    .trim()
    .matches(/^\d{9}$/, 'Please enter 9 digits')
    .nullable(),
  dateOfBirth: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue instanceof Date ? originalValue : null;
    })
    .required('Date of Birth is required')
    .test(
      'is-at-least-18',
      'You must be at least 18 years old',
      function (value) {
        const minAgeDate = new Date();
        minAgeDate.setFullYear(today.getFullYear() - 18);
        return !value || value <= minAgeDate;
      }
    ),
  homeAddress: Yup.string().trim().required('Home address is required'),
});

export const AccountantSchema = Yup.object({
  name: Yup.string().trim().required('Name is required'),

  email: Yup.string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Email address is required'),
  phoneNumber: Yup.string()
    .trim()
    .required('Contact number is required')
    .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
  nameOfAccountingFirm: Yup.string().trim().required('Name is required'),
});

export const PermissionSchema = Yup.object({
  deniedReason: Yup.string().trim().required('Denied reason is required'),
});

export const BrokerageEandOSchema = Yup.object({
  insurer: Yup.string().trim().required('Insurer  is required'),
  policy: Yup.string()
    .trim()
    // .matches(/^[a-zA-Z0-9]*$/, 'Special characters are not allowed')
    .required('Policy  is required'),
  limit: Yup.string().trim().required('Limit  is required'),
  expiryDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue instanceof Date ? originalValue : null;
    })
    .required('Expiry date is required'),
  claims_EO: Yup.boolean()
    .nullable()
    .test(
      'is-boolean',
      'Please select at least one option',
      (value) => value === true || value === false
    ),
  claims_EO_info: Yup.string().when('claims_EO', (claims_EO, schema) => {
    const isClaims_EO = Array.isArray(claims_EO)
      ? claims_EO.includes(true)
      : claims_EO === true;
    return isClaims_EO
      ? schema
          .trim()
          .required('Please enter the Errors & Omission claim information')
      : schema.notRequired();
  }),
});

export const UploadSchema = Yup.object({
  applicantId: Yup.string().trim().required('This field is required'),
  applicationId: Yup.string().trim().required('This field is required'),
  financial: Yup.string().trim().required('This field is required'),
  financialYear: Yup.string().trim().required('This field is required'),
  // financialStatement: Yup.string().trim().required('This field is required'),
  financialStatement: Yup.string().when('financial', (financial, schema) => {
    const isfinancialTrue = Array.isArray(financial)
      ? financial.includes('Financial Statement')
      : financial === 'Financial Statement';
    return isfinancialTrue
      ? schema.trim().required('This field is required')
      : schema.notRequired();
  }),
  financialFileWithComment: Yup.array()
    .min(1, 'Please upload documents.')
    .nonNullable('Please upload documents'),
  reportAsOf: Yup.string().when('financial', (financial, schema) => {
    const isfinancialTrue = Array.isArray(financial)
      ? financial.includes('Financial Statement')
      : financial === 'Financial Statement';
    return isfinancialTrue
      ? schema.trim().required('This field is required')
      : schema.notRequired();
  }),
});

export const UploadSchemaEdit = Yup.object({
  financial: Yup.string().trim().required('This field is required'),
  financialYear: Yup.string().trim().required('This field is required'),
  financialStatement: Yup.string().when('financial', (financial, schema) => {
    const isfinancialTrue = Array.isArray(financial)
      ? financial.includes('Financial Statement')
      : financial === 'Financial Statement';
    return isfinancialTrue
      ? schema.trim().required('This field is required')
      : schema.notRequired();
  }),
  // financialFileWithComment: Yup.object().shape({
  //   oldFiles: Yup.array(),
  //   newFiles: Yup.array().when('oldFiles', (oldFiles) => {
  //     return oldFiles.length === 0
  //       ? Yup.array().min(1, 'Please upload documents.')
  //       : Yup.array().min(1, 'Please upload documents.');
  //   }),
  // }),
});

export const ARSchemaEdit = Yup.object({
  question: Yup.string().notRequired(),
  holdbackRetention: Yup.number()
    .nullable()
    .when('question', (question, schema, parent) => {
      const OverValue = Number(parent?.context?.tableRow?.['91 and over']) + 1;
      const queValue = Array.isArray(question)
        ? question.includes('Amount')
        : question === 'Amount';
      return queValue
        ? schema.lessThan(OverValue, 'Please enter less than 91-and-over value')
        : schema.max(100, 'Percentage is allowed between 0 to 100');
    }),
});

export const ARADASchemaEdit = Yup.object({
  question: Yup.string().notRequired(),
  holdbackRetention: Yup.number()
    .nullable()
    .when('question', (question, schema, parent) => {
      const isTotalADA = parent?.context?.applyADA === 'Total';
      const queValue = Array.isArray(question)
        ? question.includes('Percentage') && isTotalADA
        : question === 'Percentage' && isTotalADA;
      return queValue
        ? schema.max(100, 'Percentage is allowed between 0 to 100')
        : schema.notRequired();
    }),
});
