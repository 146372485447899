export const helpPageContent = [
  {
    sectionName: 'General Questions',
    sectionId: 1,
    sectionContent: [
      {
        question: 'How do I create a new account?',
        answer: `<ul><li><span>Step-by-step guide on setting up an account, including verification.</span></li></ul>`,
        id: 2,
      },
      {
        question: 'How do I reset my password?',
        answer: `<ul><li><span>Instructions on how to reset a forgotten password.</span></li></ul>`,
        id: 3,
      },
      {
        question:
          'What are the system requirements for using the Subtraid application?',
        // answer: `<p><span>To ensure the best experience while using the Subtraid application, please ensure that your system meets the following requirements: Supported Browsers: <br />Subtraid works seamlessly on all major web browsers. We recommend using the latest version of any of the following browsers for optimal performance and security: <br />&bull; Google Chrome <br />&bull; Mozilla Firefox <br />&bull; Safari <br />&bull; Microsoft Edge <br />Using the latest version of these browsers ensures compatibility with all features and functionalities of the Subtraid application. Operating Systems: <br />The Subtraid application is compatible with most modern operating systems, including: <br />&bull; Windows 10 and above <br />&bull; macOS 10.14 (Mojave) and above <br />Subtraid can be accessed via mobile but is not mobile optimized, the Subtraid application supports</span></p>`,
        answer: `<p><span>To ensure the best experience while using the Subtraid application, please ensure that your system meets the following requirements:<br /><br /><strong>Supported Browsers:</strong><br /><br />Subtraid works seamlessly on all major web browsers. We recommend using the latest version of any of the following browsers for optimal performance and security:<br /><br />&bull; Google Chrome <br />&bull; Mozilla Firefox <br />&bull; Safari <br />&bull; Microsoft Edge<br /><br />Using the latest version of these browsers ensures compatibility with all features and functionalities of the Subtraid application. <br /><br /><strong>Operating Systems:</strong><br /><br />The Subtraid application is compatible with most modern operating systems, including: </br><br />&bull; Windows 10 and above <br />&bull; macOS 10.14 (Mojave) and above </br><br />Subtraid can be accessed via mobile but is not mobile optimized, the Subtraid application supports</br><br />&bull; iOS 13 and above <br />&bull; Android 8.0 (Oreo) and above </span>
        </br><span><strong>Browser Settings:</strong><br /><br /></span><span>Ensure the following browser settings are enabled:<br /><br />&bull; JavaScript: The Subtraid application relies on JavaScript for interactive elements and functionality. Make sure JavaScript is enabled in your browser settings. <br />&bull; Cookies: Enabling cookies allows the Subtraid application to save your preferences and keep you logged in during your session. <br />&bull; Pop-ups: Allow pop-ups for the Subtraid domain to ensure all application features work correctly, such as notifications and d</span><span>ocument previews. </span>
        </br><span><strong>Security</strong><span><strong> Recommendations:<br /></strong> <br />To protect your data and ensure secure transactions:<br /><br />&bull; Keep your browser and operating system updated with the latest security patches. <br />&bull; Use reputable antivirus software to safeguard against malware. <br />&bull; Avoid using public Wi-Fi networks when accessing sensitive information on the Subtraid application. <br /><br />By meeting these system requirements, you can ensure a smooth and efficient experience while using the Subtraid application.</span></p>`,
        id: 4,
      },
    ],
  },
  {
    sectionName: 'Application Process',
    sectionId: 5,
    sectionContent: [
      {
        question: 'How do I start a new contract surety application?',
        answer: `<ul><li><span>Walkthrough of starting a new application from the dashboard.</span></li></ul>`,
        id: 6,
      },
      {
        question: 'What information do I need to input manually?',
        answer: `<ul><li><span>Overview of the necessary data and documents that need to be entered/uploaded.</span></li></ul>`,
        id: 7,
      },
      {
        question: 'How do I save my progress and come back later?',
        answer: `<ul><li><span>Explanation of the save feature and how to resume an incomplete application.</span></li></ul>`,
        id: 8,
      },
    ],
  },
  {
    sectionName: 'Integration and Data Retrieval',
    sectionId: 9,
    sectionContent: [
      {
        question: 'How do I link external software systems to Subtraid?',
        answer: `<ul><li><span>Detailed steps on integrating accounting software systems via API.</span></li></ul>`,
        id: 10,
      },
      {
        question:
          'What information will Subtraid access from your accounting system?',
        answer: `<p><span>"Financial information is an essential part of the underwriting process. Allowing us to securely pull information from your accounting system will significantly shorten the time it takes to secure a bond and insurance. We do NOT store your login credentials. Subtraid accesses the following information for the past 3 years: <br />- Balance Sheet <br />- Profit &amp; Loss Statement <br />- Account Receivable Report <br />- Accounts Payable Report&rdquo;</span></p>`,
        id: 11,
      },
      {
        question: 'How do I troubleshoot integration issues?',
        answer: `<ul><li><span>Common problems and solutions when connecting to external APIs. Unsure of what these issues might be or look like but if you have any idea please show via screen record.</span></li></ul>`,
        id: 12,
      },
    ],
  },
  {
    sectionName: 'Submission and Download',
    sectionId: 13,
    sectionContent: [
      {
        question: 'How do I review and finalize an application?',
        answer: `<ul><li><span>Guide on reviewing entered data and ensuring everything is complete before submission.</span></li></ul>`,
        id: 14,
      },
      {
        question: 'How do I download the completed application?',
        answer: `<ul><li><span>Instructions on downloading the completed bond application to send to underwriters.</span></li></ul>`,
        id: 15,
      },
      {
        question: 'How do I track the status of my applications?',
        answer: `<ul><li><span>Overview of the application tracking feature on the dashboard.</span></li></ul>`,
        id: 16,
      },
      {
        question: 'How do I upload supportive documents?',
        answer: `<ul><li><span>Upload tab from the dashboard.</span></li><li><span>Documents uploaded to our system will be deleted two years after the creation of the application, we recommend downloading and storing them on your own system for future reference.</span></li></ul>`,
        id: 23,
      },
    ],
  },
  {
    sectionName: 'Support and Troubleshooting',
    sectionId: 17,
    sectionContent: [
      {
        question: 'Who do I contact for technical support?',
        answer: `<ul><li><span>For help email us at <a href="mailto:support@subtraid.com">support@subtraid.com</a></span></li></ul>`,
        id: 18,
      },
      {
        question:
          'What should I do if the application form isn’t loading properly?',
        answer: `<ul><li><span>Please try clearing your browser's cache or checking your internet connection.</span></li></ul>`,
        id: 19,
      },
      {
        question: 'How do I report a bug or provide feedback?',
        answer: `<ul><li><span>For help email us at <a href="mailto:support@subtraid.com">support@subtraid.com</a></span></li></ul>`,
        id: 20,
      },
    ],
  },
  {
    sectionName: 'Security and Privacy',
    sectionId: 21,
    sectionContent: [
      {
        question: 'How is my data protected?',
        answer: `<ul><li><span>Here the Personal Financial data is a private data, need to make it private key by applicant. Same applies with Reports which show the sensitive data like profile, loss etx</li></ul>`,
        id: 22,
      },
      // {
      //   question: 'Can I update or delete my personal information?',
      //   answer: `<ul><li><span>Guide on managing personal data within the system.</span></li></ul>`,
      //   id: 23,
      // },
    ],
  },
];
