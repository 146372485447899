import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { APIStatus } from 'src/types/unions';
import { BASEURL } from '../../../App';
import callAPI from '../../../utils/callAPI';
import { tokenExipry } from '../../tokenExpiry';
import { isRejectedActionWithPayload } from '../Reports/WorkInProgress/slice';

interface CreateApplicationFormState {
  status: APIStatus | null;
  user: any;
  loading: boolean;
  error: any;
  type?:
    | 'GET_ALL_APPLICATIONS'
    | 'GET_APPLICATIONS_BY_APPLICANT_ID'
    | 'RESCIND_APPLICATION_FROM_APPLICANT';
  applications: {
    id: number;
    applicationName: string;
    applicantId: number;
    brokerId: number;
    assignedTo: {
      userId: number;
      userType: string;
    };
    applicationId: number;
    bureauId: number;
    financialInfoId: number;
    cmsId: number;
    suretyId: number;
    insuranceId: number;

    brokerStatus: string;
    applicantStatus: string;
    accountantStatus: string;

    isDraft: boolean;
    isApproved: boolean;
    declinedReason: string;
    autoPopulateStatus: {
      qbo: boolean;
      esc: boolean;
      totalConsent: number | null;
      totalAwaitingConsent: number | null;
    };

    brokerDashboard: string;
    applicantDashboard: string;

    // for finish button of application
    isFinishByBroker: boolean;
    isFinishByApplicant: boolean;
    cms: {
      keyPersonnel: {
        hiringDate: string;
        name: string;
        position: string;
      }[];
    };
    creditBureau: {
      taxingEntityDoc: boolean | null;
      paymentPlan: boolean | null;
    };
    fiscalYearEnd?: string;
    applicant?: Record<string, any>;
  }[];
  errorResponse: Record<string, any> | null;
}

const initialState: CreateApplicationFormState = {
  status: null,
  user: [],
  loading: false,
  error: null,
  applications: [],
  errorResponse: null,
};

export const CreateApplicationForm = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

//Rescind application form from applicant
export const RescindFromApplicant = createAsyncThunk(
  'CreateApplicationForm/RescindFromApplicant',
  async (
    payload: {
      applicationId: number;
      formName:
        | 'accountingSystem'
        | 'creditBureau'
        | 'cms'
        | 'surety'
        | 'insurance';
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(`application/rescind-form`, 'PUT', payload);

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

// Accounting System
export const AccountingSystem = createAsyncThunk(
  'CreateApplicationForm/AccountingSystem',
  async (
    Adddata: {
      brokerId: number | null;
      applicantId: number | null;
      legalName: string | null;
      businessEstablishedDate: string | null;
      email: string | null;
      website: string | null;
      street: string | null;
      city: string | null;
      province: string | null;
      postalCode: string | null;
      telephone: string | null;
      accountingFirmName: string | null;
      contactName: string | null;
      phoneNumber: number | null;
      interimStatementInterval: string | null;
      grossProfitAtRecentFiscalYearEnd: number | null;
      dateCompleted: string | null;
      bankName: string | null;
      address: string | null;
      accountManagerName: string | null;
      bankPhoneNumber: number | null;
      withBankSinceYear: number | null;
      authorizedLineOfCredit: number | null;
      usedLineOfCredit: number | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/accountingSystem/create`,
          Adddata,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Credit Bureau
export const Creditbureau = createAsyncThunk(
  'CreateApplicationForm/Creditbureau',
  async (
    Adddata: {
      applicationId: number | null;
      brokerId: number | null;

      receivershipOrBankruptcyHistory: {
        hasHistory: boolean | null;
        details: string | null;
      };

      guaranteesForOtherParties: {
        hasGuarantees: boolean | null;
        details: string | null;
      };

      relatedCompanies: {
        hasRelatedCompanies: boolean | null;
        companies: {
          name: string | null;
          natureOfOperations: string | null;
          ownershipStructure: string | null;
        }[];
      };
      changesInManagement: {
        hasChanges: boolean | null;
        explanation: string | null;
      };
      liensFiledAgainstCompany: {
        hasLiens: boolean | null;
        // amount: number | null;
        // details: string | null;
      };
      stateFederalTax: boolean | null;
      paymentPlan: boolean | null;
      outstandingJudgments: {
        hasOutstandingJudgments: boolean | null;
        // details: string | null;
      };
      bondClaims: boolean | null;
      claimsPaid: boolean | null;
      suretyMade: boolean | null;
      disputesWithOthers: {
        hasDisputes: boolean | null;
        details: string | null;
      };

      liensFiledByCompany: {
        hasLiens: boolean | null;
        details: string | null;
      };

      disputesWithSuppliers: {
        hasDisputes: boolean | null;
        details: string | null;
        fundingMeans: string | null;
      };

      buySellAgreement: boolean | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/creditBureau/create`,
          Adddata,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// cms
export const CMS = createAsyncThunk(
  'CreateApplicationForm/Cms',
  async (
    Adddata: {
      applicationId: number | null;
      brokerId: number | null;

      name: string | null;
      position: string | null;
      hiringDate: string | null;
      contractorType: string | null;
      contractorTypeOther: string | null;
      // annualSalesPercentage: number | null;
      salesPercentageByOwnerTypeOther: string | null;
      workSubletOther: string | null;
      typeOfWorkOther: string | null;
      typeOfWork: [
        {
          type: string | null;
          otherType: string | null;
        },
      ];
      workSublet: [
        {
          type: string | null;
          otherSublet: string | null;
        },
      ];

      salesPercentageByOwnerType: [
        {
          ownerType: string | null;
          otherOwnerType: string | null;
        },
      ];
      contractors: [
        {
          type: string | null;
          annualSalesPercentage: number | null;
          otherContractorType: string | null;
        },
      ];
      // largestContracts: [
      //   {
      //     ownerType: string | null;
      //     // ownerName: string | null;
      //     // contractPrice: number | null;
      //     workType: string | null;
      //     // employeesUnionized: boolean | null;
      //     // payUnionScaleWages: boolean | null;
      //     // largestWorkAmount: number | null;
      //     // numberOfContracts: number | null;
      //     // workSubletPercentage: number | null;
      //     // workUndertakenOwnForces: string | null;
      //     // workSublet: string | null;
      //     // obtainBondsFromSubcontractors: boolean | null;
      //   }
      // ];
      // maxContractSize: [
      //   {
      //     workType: string | null;
      //     contractAmount: number | null;
      //   }
      // ];
      // totalQualifiedWorkProgram: {
      //   current: number | null;
      //   next12Months: number | null;
      // };

      // largestSuppliers: [
      //   {
      //     name: string | null;
      //     mailingAddress: string | null;
      //     fax: number | null;
      //     phoneNumber: number | null;
      //   }
      // ];
      largestWorkAmount: number | null;
      numberOfContracts: number | null;
      workSubletPercentage: number | null;
      workUndertakenOwnForces: string | null;
      totalWorkProgramOneTime: number | null;
      totalWorkProgram12Months: number | null;
      estimatedSubletBudget: number | null;
      ownerName: string | null;
      contractPrice: number | null;
      obtainBondsFromSubcontractors: {
        hasBonds: boolean | null;
        minimumContract: string | null;
      };
      typeOfProjectOwner: {
        type: string | null;
        otherType: string | null;
      };
      workOutsideCanada: {
        performWorkOutside: boolean | null;
        annualSalesPercentage: {
          country: string | null;
          percentage: number | null;
        }[];
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/cms/create`,
          Adddata,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
//Surety
export const surety = createAsyncThunk(
  'CreateApplicationForm/surety',
  async (
    Adddata: {
      applicationId: number | null;
      brokerId: number | null;

      suretyName: string | null;
      totalWorkOnHand: number | null;
      singleJob: number | null;
      reasonChangingSurety: string | null;
      refusedBond: boolean | null;
      refusedBondExplanation: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/surety/create`,
          Adddata,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
//insurance
export const insurance = createAsyncThunk(
  'CreateApplicationForm/Insurance',
  async (Adddata: TInsurence, { rejectWithValue }) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/insurance/create`,
          Adddata,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
//Personal Financial Info
export const PFinfo = createAsyncThunk(
  'CreateApplicationForm/PFinfo',
  async (
    data: { updatedData: any; applicationId: any },
    { rejectWithValue }
  ) => {
    try {
      const { updatedData, applicationId } = data;

      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/financialInfo/create`,
          updatedData,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Accounting System update
export const AccountingSystemUpdateOwner = createAsyncThunk(
  'CreateApplicationForm/AccountingSystemUpdateOwner',
  async (
    data: { updatedData: any; applicationId: number },
    { rejectWithValue }
  ) => {
    try {
      const { updatedData, applicationId } = data;
      const res = await callAPI(
        `accountingSystem/update/${applicationId}`,
        'PUT',
        updatedData
      );

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Cms update
export const CMSUpdate = createAsyncThunk(
  'CreateApplicationForm/CMSUpdate',
  async (
    data: { updatedData: any; applicationId: any },
    { rejectWithValue }
  ) => {
    try {
      const { updatedData, applicationId } = data;

      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/cms/update/${applicationId}`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// surety update
export const suretyUpdate = createAsyncThunk(
  'CreateApplicationForm/suretyUpdate',
  async (
    data: { updatedData: any; applicationId: any },
    { rejectWithValue }
  ) => {
    try {
      const { updatedData, applicationId } = data;

      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/surety/update/${applicationId}`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// creditBureau update
export const creditBureauUpdate = createAsyncThunk(
  'CreateApplicationForm/creditBureauUpdate',
  async (
    data: { updatedData: any; applicationId: any },
    { rejectWithValue }
  ) => {
    try {
      const { updatedData, applicationId } = data;

      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/creditBureau/update/${applicationId}`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Insurance update
export const InsuranceUpdate = createAsyncThunk(
  'CreateApplicationForm/InsuranceUpdate',
  async (
    data: { updatedData: any; applicationId: any },
    { rejectWithValue }
  ) => {
    try {
      const { updatedData, applicationId } = data;

      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/insurance/update/${applicationId}`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Personal Financial Info update
export const PFinfoUpdate = createAsyncThunk(
  'CreateApplicationForm/PFinfoUpdate',
  async (
    data: { updatedData: any; applicationId: any },
    { rejectWithValue }
  ) => {
    try {
      const { updatedData, applicationId } = data;
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/financialInfo/update/${applicationId}`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Accounting System id threw get
export const fetchAccountingSystemData = createAsyncThunk(
  'CreateApplicationForm/fetchAccountingSystemData',
  async () => {
    try {
      // const applicationId = localStorage.getItem("applicationId");
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/accountingSystem/get`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//cms id threw get
export const fetchCMSData = createAsyncThunk(
  'CreateApplicationForm/fetchCMSData',
  async () => {
    try {
      const applicationId = localStorage.getItem('applicationId');
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/cms/get/${applicationId}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//surety id threw get
export const fetchsuretyData = createAsyncThunk(
  'CreateApplicationForm/fetchsuretyData',
  async () => {
    try {
      const applicationId = localStorage.getItem('applicationId');
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/surety/get/${applicationId}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//creditbureau id threw get
export const fetchCreditBureauData = createAsyncThunk(
  'CreateApplicationForm/fetchCreditBureauData',
  async () => {
    try {
      const applicationId = localStorage.getItem('applicationId');
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/creditBureau/get/${applicationId}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//Insurance id threw get
export const fetchInsuranceData = createAsyncThunk(
  'CreateApplicationForm/fetchInsuranceData',
  async () => {
    try {
      const applicationId = localStorage.getItem('applicationId');
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/insurance/get/${applicationId}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//Personal Financial Info id threw get
export const fetchPFinfoData = createAsyncThunk(
  'CreateApplicationForm/fetchPFinfoData',
  async () => {
    try {
      const applicationId = localStorage.getItem('applicationId');
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/financialInfo/get/${applicationId}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.request(config);
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// application/get-all-application
export const getAllApplication = createAsyncThunk(
  'application/getAllApplication',
  async (_, { rejectWithValue }) => {
    try {
      const response = await callAPI('application/get-all-application', 'GET');
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getApplicationsByApplicant = createAsyncThunk(
  'application/getApplicationsByApplicant',
  async (payload: { applicantId: number }, { rejectWithValue }) => {
    try {
      const response = await callAPI(
        'application/get-app-by-applicant',
        'POST',
        payload
      );
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const CreateApplicationFormSlice = createSlice({
  name: 'CreateApplicationForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Accounting System account

      .addCase(AccountingSystem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AccountingSystem.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(AccountingSystem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //Credit Bureau

      .addCase(Creditbureau.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Creditbureau.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(Creditbureau.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      // cms
      .addCase(CMS.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CMS.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(CMS.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      // surety
      .addCase(surety.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(surety.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(surety.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Insurance
      .addCase(insurance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(insurance.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(insurance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      //Personal Financial Info

      .addCase(PFinfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PFinfo.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(PFinfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Accounting System update id
      .addCase(fetchAccountingSystemData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAccountingSystemData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchAccountingSystemData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      // cms  update id
      .addCase(fetchCMSData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCMSData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchCMSData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      // surety  update id
      .addCase(fetchsuretyData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchsuretyData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchsuretyData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // creditBureau  update id
      .addCase(fetchCreditBureauData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchCreditBureauData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchCreditBureauData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Insurance  update id
      .addCase(fetchInsuranceData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchInsuranceData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchInsuranceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Personal Financial Info  update id
      .addCase(fetchPFinfoData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchPFinfoData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchPFinfoData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //Accounting System update
      .addCase(AccountingSystemUpdateOwner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AccountingSystemUpdateOwner.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(AccountingSystemUpdateOwner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      //cms  update
      .addCase(CMSUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CMSUpdate.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(CMSUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //surety  update
      .addCase(suretyUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(suretyUpdate.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(suretyUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //creditBureau Update
      .addCase(creditBureauUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        creditBureauUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(creditBureauUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //Insurance Update
      .addCase(InsuranceUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        InsuranceUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(InsuranceUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //Personal Financial Info Update
      .addCase(PFinfoUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PFinfoUpdate.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(PFinfoUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //GET All Application
      .addCase(getAllApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.type = 'GET_ALL_APPLICATIONS';
      })
      .addCase(getAllApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.applications = action.payload.data;
        state.type = 'GET_ALL_APPLICATIONS';
      })
      .addCase(getAllApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.type = 'GET_ALL_APPLICATIONS';
      })
      //GET All Application by Applicant ID
      .addCase(getApplicationsByApplicant.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
        state.error = null;
        state.type = 'GET_APPLICATIONS_BY_APPLICANT_ID';
      })
      .addCase(getApplicationsByApplicant.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.loading = false;
        state.applications = action.payload.data;
        state.type = 'GET_APPLICATIONS_BY_APPLICANT_ID';
      })
      .addCase(getApplicationsByApplicant.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.type = 'GET_APPLICATIONS_BY_APPLICANT_ID';
        state.errorResponse = action.payload!;
      })

      //Accounting System update
      .addCase(RescindFromApplicant.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
        state.error = null;
        state.type = 'RESCIND_APPLICATION_FROM_APPLICANT';
      })
      .addCase(
        RescindFromApplicant.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeed';
          state.loading = false;
          state.user = action.payload;
          state.type = 'RESCIND_APPLICATION_FROM_APPLICANT';
        }
      )
      .addCase(RescindFromApplicant.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.type = 'RESCIND_APPLICATION_FROM_APPLICANT';
      });
  },
});

export default CreateApplicationFormSlice.reducer;
