import { Box, Text, VStack } from '@chakra-ui/react';
import { Modal } from '@mantine/core';
import { purchaseHistory } from 'src/Redux/Plans/state';
import { getCurrencyDigit, getDateFormat } from 'src/utils/helpers';

const PlanHistoryDetailsModal = ({
  isOpen,
  onClose,
  planDetails,
}: {
  isOpen: boolean;
  onClose: () => void;
  planDetails: purchaseHistory | null;
}) => {
  const {
    name,
    checkoutSessionStatus,
    purchasedDate,
    stripeSession,
    planExpiryDate,
  } = planDetails!;
  const { amount_total, currency } = stripeSession;

  return (
    <Modal
      opened={isOpen}
      onClose={() => onClose()}
      title="Purchase Plan"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'lg'}
      className="global_history"
    >
      <Box margin="10px" maxW="725px" maxH="600px" overflow="auto">
        <VStack spacing={3} align="flex-start">
          <Text fontSize="16px">
            <Text as={'span'} fontWeight={600}>
              Name:
            </Text>{' '}
            <Text as="span">{name}</Text>
          </Text>
          <Text fontSize="16px">
            <Text as={'span'} fontWeight={600}>
              Price:
            </Text>{' '}
            {getCurrencyDigit({
              number: Math.trunc(amount_total / 100),
              options: { currency },
            })}
          </Text>
          <Text fontSize="16px" textTransform="capitalize">
            <Text as={'span'} fontWeight={600}>
              Status:
            </Text>{' '}
            <Text as="span">
              {checkoutSessionStatus === 'complete'
                ? 'Success'
                : checkoutSessionStatus}
            </Text>
          </Text>
          <Text fontSize="16px">
            <Text as={'span'} fontWeight={600}>
              Purchase Date:
            </Text>{' '}
            {getDateFormat(purchasedDate!, 'DD MMMM, YYYY hh:mm A')}
          </Text>
          <Text fontSize="16px">
            <Text as={'span'} fontWeight={600}>
              Expiry Date:
            </Text>{' '}
            {getDateFormat(planExpiryDate!, 'DD MMMM, YYYY hh:mm A')}
          </Text>
        </VStack>
      </Box>
    </Modal>
  );
};

export default PlanHistoryDetailsModal;
