import { useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { IoIosCall } from 'react-icons/io';
import {
  MdDriveFileRenameOutline,
  MdEmail,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BrokererageAccountUser } from '../../Redux/Auth/Auth';
import { UserBrokererageAccountSchema } from '../../ValidationSchema/Auth/Index';

import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  IconButton,
  Image,
  Input,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Anchor } from '@mantine/core';

function BrokererageAccount() {
  const formWidth = useBreakpointValue({ base: '100%', sm: '60%' });
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const Toast = useToast();
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleTogglePassword1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  interface FormValues {
    userType: string;
    userData: {
      legalName: string;
      contactNumber: string;
      email: string;
      password: string;
      confirmPassword: string;
      termCondition: boolean;
    };
  }

  const initialValues: FormValues = {
    userType: 'brokerage',
    userData: {
      legalName: '',
      password: '',
      email: '',
      contactNumber: '',
      termCondition: false,
      confirmPassword: '',
    },
  };
  const formik = useFormik({
    initialValues,

    validationSchema: UserBrokererageAccountSchema,

    onSubmit: async (values: FormValues) => {
      setIsSignUpLoading(true);
      let res = '';
      try {
        const actionResult = await dispatch(
          BrokererageAccountUser(values) as any
        );
        const response = actionResult?.payload;
        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 400) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          Toast({
            title: res,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
          Navigate('/login');
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      setIsSignUpLoading(false);
    },
  });

  const HandelSignin = () => {
    Navigate('/login');
  };
  const HandelApplicant = () => {
    Navigate('/applicant-account');
  };
  const HandelBroker = () => {
    Navigate('/broker-account');
  };
  const HandelBrokererage = () => {
    Navigate('/brokererage-account');
  };

  return (
    <>
      <Box h="100%">
        <Flex>
          <Container maxW="100%" padding={0}>
            <Flex flexWrap="wrap" height="100vh  ">
              <Box
                bg="#114684"
                borderRadius={[
                  '0px 200px 0px 0px',
                  '0px 245px 0px 0px',
                  '0px 350px 0px 0px',
                ]}
                w={['100%', '40%']}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDir={'column'}
                gap={'10px'}
              >
                <Box display="flex" justifyContent="center">
                  <Image
                    src="Image/insurance3.jpg"
                    alt="insurance"
                    width={['700px', '400px', '500px', '700px']}
                    boxSize={['250px', '230px', '260px', '300px']}
                    objectFit="cover"
                  />
                </Box>
                <Text
                  fontWeight="bold"
                  color="white"
                  textAlign="center"
                  fontSize={20}
                  mt={['10px']}
                >
                  Do you already have a account?
                </Text>
                <Box textAlign="center" mt={3}>
                  <Button
                    bg="#114684"
                    color="white"
                    variant="outline"
                    _hover={{ color: 'white  ' }}
                    mb={3}
                    onClick={HandelSignin}
                  >
                    Sign In
                  </Button>
                </Box>
              </Box>
              <Container
                textAlign="center"
                display={'flex'}
                flexDir={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap="1rem"
                bgColor={'erd'}
                w={formWidth}
                mt={5}
              >
                <Flex justifyContent="center" flexWrap="wrap" gap={1}>
                  <Box>
                    <Button
                      name="Applicat"
                      borderRadius="10px"
                      variant="outline"
                      color="#114684"
                      _hover={{
                        bg: '#114684',
                        color: 'white',
                        border: ' 2px solid #114684',
                      }}
                      onClick={HandelApplicant}
                      border=" 2px solid #114684"
                    >
                      Applicant
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      borderRadius="10px"
                      color="#114684"
                      border=" 2px solid #114684"
                      variant="outline"
                      _hover={{
                        bg: '#114684',
                        color: 'white',
                        border: ' 2px solid #114684',
                      }}
                      onClick={HandelBroker}
                    >
                      Broker
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      borderRadius="10px"
                      variant="outline"
                      border=" 2px solid #114684"
                      _hover={{
                        bg: '#114684',
                        color: 'white',
                        border: ' 2px solid #114684',
                      }}
                      onClick={HandelBrokererage}
                      bg={isClicked ? 'white' : '#114684'}
                      color={isClicked ? '#114684' : 'white'}
                    >
                      Brokerage
                    </Button>
                  </Box>
                </Flex>
                <Box
                  boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
                  h="auto"
                  p="19px"
                  borderRadius="20px"
                  w={'100%'}
                >
                  <form
                    onSubmit={formik.handleSubmit}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Text
                      fontWeight="bold"
                      color="#114684"
                      textAlign="center"
                      fontSize={20}
                    >
                      Create Account
                    </Text>

                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={['5px', '4px', '7px', '20px']}
                    >
                      <Box display="flex" flexDirection={'column'}>
                        <Box display="flex">
                          <Box
                            fontSize={25}
                            color="#114684"
                            borderRadius="5px 0px 0px 5px"
                            height="auto"
                            backgroundColor="#f0f5f9"
                            pl={2}
                            pr={2}
                            pt={1}
                            bg={'#f0f5f9'}
                          >
                            <MdDriveFileRenameOutline />
                          </Box>

                          <Input
                            _hover={{ borderColor: '#114684' }}
                            marginLeft={'-2px'}
                            bg={'#f0f5f9'}
                            border="none"
                            focusBorderColor="transparent"
                            type="name"
                            placeholder="Legal Name"
                            borderRadius="0px 5px 5px 0px"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.userData.legalName}
                            name="userData.legalName"
                          />
                        </Box>
                        {formik.touched.userData?.legalName &&
                        formik.errors?.userData?.legalName ? (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {String(formik.errors.userData.legalName)}
                          </div>
                        ) : null}
                      </Box>

                      <Box display="flex" flexDirection={'column'}>
                        <Box display="flex">
                          <Box
                            fontSize={25}
                            color="#114684"
                            borderRadius="5px 0px 0px 5px"
                            height="auto"
                            backgroundColor="#f0f5f9"
                            pl={2}
                            pr={2}
                            pt={1}
                            bg={'#f0f5f9'}
                          >
                            <IoIosCall />
                          </Box>

                          <Input
                            _hover={{ borderColor: '#114684' }}
                            marginLeft={'-2px'}
                            bg={'#f0f5f9'}
                            border="none"
                            focusBorderColor="transparent"
                            type="tel"
                            placeholder=" Contact Number"
                            borderRadius="0px 5px 5px 0px"
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                              const inputValue = e.target.value
                                .replace(/\D/g, '')
                                .slice(0, 10);
                              formik.setFieldValue(
                                'userData.contactNumber',
                                inputValue
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.userData.contactNumber}
                            name="userData.contactNumber"
                            maxLength={10}
                          />
                        </Box>
                        {formik.touched.userData?.contactNumber &&
                        formik.errors?.userData?.contactNumber ? (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {String(formik.errors.userData.contactNumber)}
                          </div>
                        ) : null}
                      </Box>

                      <Box display="flex" flexDirection={'column'}>
                        <Box display="flex">
                          <Box
                            fontSize={25}
                            color="#114684"
                            borderRadius="5px 0px 0px 5px"
                            height="auto"
                            backgroundColor="#f0f5f9"
                            pl={2}
                            pr={2}
                            pt={1}
                            bg={'#f0f5f9'}
                          >
                            <MdEmail />
                          </Box>

                          <Input
                            _hover={{ borderColor: '#114684' }}
                            marginLeft={'-2px'}
                            bg={'#f0f5f9'}
                            border="none"
                            focusBorderColor="transparent"
                            type="email"
                            placeholder="Email Address"
                            borderRadius="0px 5px 5px 0px"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.userData.email}
                            name="userData.email"
                            autoComplete="userData.email"
                          />
                        </Box>
                        {formik.touched.userData?.email &&
                        formik.errors?.userData?.email ? (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {String(formik.errors.userData.email)}
                          </div>
                        ) : null}
                      </Box>
                      <Box display="flex" flexDirection={'column'}>
                        <Box display="flex">
                          <Box
                            fontSize={25}
                            color="#114684"
                            borderRadius="5px 0px 0px 5px"
                            height="auto"
                            backgroundColor="#f0f5f9"
                            pl={2}
                            pr={2}
                            pt={1}
                            bg={'#f0f5f9'}
                          >
                            <RiLockPasswordFill />
                          </Box>
                          <Input
                            _hover={{ borderColor: '#114684' }}
                            marginLeft={'-2px'}
                            bg={'#f0f5f9'}
                            border="none"
                            focusBorderColor="transparent"
                            placeholder="Password"
                            borderRadius="0px 5px 5px 0px"
                            type={showPassword ? 'text' : 'password'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.userData.password}
                            name="userData.password"
                            autoComplete="userData.current-password"
                          />
                          <IconButton
                            aria-label={
                              showPassword ? 'Hide password' : 'Show password'
                            }
                            icon={
                              showPassword ? (
                                <MdVisibilityOff />
                              ) : (
                                <MdVisibility />
                              )
                            }
                            onClick={handleTogglePassword}
                            borderRadius="0px 5px 5px 0px"
                            ml="-2px"
                            border="none"
                            bg={'#f0f5f9'}
                            _hover={{ bg: '#f0f5f9' }}
                            color="#114684"
                            fontSize={25}
                          />
                        </Box>

                        {formik.touched.userData?.password &&
                        formik.errors?.userData?.password ? (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {String(formik.errors.userData.password)}
                          </div>
                        ) : null}
                      </Box>

                      <Box display="flex" flexDirection={'column'}>
                        <Box display="flex">
                          <Box
                            fontSize={25}
                            color="#114684"
                            borderRadius="5px 0px 0px 5px"
                            height="auto"
                            backgroundColor="#f0f5f9"
                            pl={2}
                            pr={2}
                            pt={1}
                            bg={'#f0f5f9'}
                          >
                            <RiLockPasswordFill />
                          </Box>
                          <Input
                            _hover={{ borderColor: '#114684' }}
                            marginLeft={'-2px'}
                            bg={'#f0f5f9'}
                            border="none"
                            focusBorderColor="transparent"
                            placeholder="Confirm  Password "
                            borderRadius="0px 5px 5px 0px"
                            type={showPassword1 ? 'text' : 'password'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.userData.confirmPassword}
                            name="userData.confirmPassword"
                          />
                          <IconButton
                            aria-label={
                              showPassword1 ? 'Hide password' : 'Show password'
                            }
                            icon={
                              showPassword1 ? (
                                <MdVisibilityOff />
                              ) : (
                                <MdVisibility />
                              )
                            }
                            onClick={handleTogglePassword1}
                            borderRadius="0px 5px 5px 0px"
                            ml="-2px"
                            bg={'#f0f5f9'}
                            _hover={{ bg: '#f0f5f9' }}
                            color="#114684"
                            fontSize={25}
                          />
                        </Box>
                        {formik.touched.userData?.confirmPassword &&
                        formik.errors?.userData?.confirmPassword ? (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {String(formik.errors.userData.confirmPassword)}
                          </div>
                        ) : null}
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="start"
                        flexDirection={'column'}
                      >
                        <Checkbox
                          name="userData.termCondition"
                          color="#fbb03b"
                          borderColor="#fbb03b"
                          colorScheme="yellow"
                          isChecked={formik.values.userData.termCondition}
                          onChange={(e) =>
                            formik.setFieldValue(
                              'userData.termCondition',
                              e.target.checked
                            )
                          }
                          onBlur={formik.handleBlur}
                        >
                          <Text fontSize={'15px'}>
                            I agree to{' '}
                            <Anchor
                              download={'Terms_Conditions.pdf'}
                              href="pdf/Terms_Conditions.pdf"
                              color="#fbb03b"
                              sx={{ textDecoration: 'underline' }}
                            >
                              Terms and Conditions
                            </Anchor>
                          </Text>
                        </Checkbox>

                        {formik.touched.userData?.termCondition &&
                        formik.errors?.userData?.termCondition ? (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {String(formik.errors.userData.termCondition)}
                          </div>
                        ) : null}
                      </Box>

                      <Box>
                        <Button
                          isLoading={isSignUpLoading}
                          loadingText="Sign Up"
                          type="submit"
                          bg="#114684"
                          color="white"
                          w="100%"
                          _hover={{ color: 'white' }}
                        >
                          Sign Up
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </Box>
              </Container>
            </Flex>
          </Container>
        </Flex>
      </Box>
    </>
  );
}

export default BrokererageAccount;
