import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import AutoComplete from 'react-google-autocomplete';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  BrokerageUpdate,
  fetchBrokerageUserData,
} from '../../../Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import { RootState } from '../../../Redux/Store';
import { BrokerageProfileSchema } from '../../../ValidationSchema/Auth/Index';
import useObjectComparator from '../../../hooks/useObjectComparator';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDvWG26al2Ons0JrDhxHWuaDPha0TA7kzs';

function BrokerageProfile() {
  const [showClear, setShowClear] = useState(false);
  const autoCompleteRef: any = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Toast = useToast();
  const useruidData = useSelector((state: RootState) => state.brokerage.user);
  let userGetidData = useruidData && useruidData?.brokerageInfo;
  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const { areDifferent, setInitialValues, setNewValues } =
    useObjectComparator();

  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        if (!useruidData || !Object.keys(useruidData).length) {
          const actionResult = await dispatch(fetchBrokerageUserData() as any);
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          }
        }
      } else {
        navigate('/login');
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (userGetidData) {
      const { legalName, contactNumber, email, officeAddress } = userGetidData;
      formik.setValues((prevValues) => ({
        ...prevValues,
        legalName: legalName || '',
        contactNumber: contactNumber || '',
        email: email || '',
        officeAddress: officeAddress || '',
      }));
      setShowClear(!!officeAddress);
      setInitialValues((prevValues: any) => ({
        ...prevValues,
        legalName: legalName || '',
        contactNumber: contactNumber || '',
        email: email || '',
        officeAddress: officeAddress || '',
      }));
    }
  }, [userGetidData]);

  interface FormValues {
    legalName: string;
    contactNumber: number | null;
    email: string;
    officeAddress: string;
  }

  const initialValues: FormValues = {
    legalName: '',
    contactNumber: null,
    email: '',
    officeAddress: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: BrokerageProfileSchema,
    onSubmit: async (values: FormValues, { resetForm }) => {
      setIsProfileUpdate(true);
      let res = '';
      try {
        if (!areDifferent) {
          setIsProfileUpdate(false);
          return;
        }
        const actionResult = await dispatch(BrokerageUpdate(values) as any);
        const response = actionResult?.payload;
        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 200) {
          Toast({
            title: response.msg,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
          resetForm();
        } else {
          if (resStatus !== 401) {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else {
            navigate('/login');
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          Toast({
            title: '404',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      if (!!localStorage.getItem('Token')) {
        const actionResult = await dispatch(fetchBrokerageUserData() as any);
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
      setIsProfileUpdate(false);
    },
  });

  const handleClear = () => {
    formik.setFieldValue('officeAddress', '');
    setShowClear(false);

    autoCompleteRef.current.value = '';
  };
  useEffect(() => {
    setNewValues(formik.values);
  }, [formik.values]);
  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        h="auto"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        p="0px 22px 0px 22px"
        borderRadius="20px"
      >
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Text
              fontWeight="600"
              color="#114684"
              textAlign="center"
              fontSize={20}
              pt="20px"
            >
              Brokerage Profile
            </Text>
          </Box>

          <Box>
            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="Legal Name" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="name"
                  placeholder="Legal Name"
                  name="legalName"
                  borderRadius="5px"
                  value={formik.values.legalName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.legalName && formik.errors.legalName && (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.legalName}
              </div>
            )}

            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="Contact Number" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="tel"
                  placeholder=" Contact Number"
                  borderRadius="5px"
                  name="contactNumber"
                  value={formik.values.contactNumber ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.contactNumber && formik.errors.contactNumber && (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.contactNumber}
              </div>
            )}

            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="Email Address" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="Email"
                  placeholder="Email Address"
                  borderRadius="5px"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.email && formik.errors.email && (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.email}
              </div>
            )}
            <Tooltip label="Office Address" placement="right" hasArrow>
              <Box display="flex" mb={['20px', '5px']} mt={2}>
                <AutoComplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  defaultValue={formik.values.officeAddress ?? ''}
                  onPlaceSelected={(place) => {
                    if (place && place.formatted_address) {
                      formik.setFieldValue(
                        'officeAddress',
                        place.formatted_address
                      );
                      setShowClear(true);
                    } else {
                      formik.setFieldValue('officeAddress', '');
                      setShowClear(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  className="googlelocation"
                  ref={autoCompleteRef}
                  options={{
                    types: [],
                  }}
                  style={{ fontSize: '13px' }}
                />
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  bg={'#f0f5f9'}
                  mr={2}
                  cursor={'pointer'}
                  _hover={{ color: '#114684' }}
                >
                  {showClear && <FaTimes onClick={handleClear} />}
                </Box>
              </Box>
            </Tooltip>

            {formik.touched.officeAddress && formik.errors.officeAddress && (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.officeAddress}
              </div>
            )}
            <Flex justifyContent="space-evenly" columnGap={5}>
              <Box mt="20px" w="100%" mb={10}>
                <Button
                  isLoading={isProfileUpdate}
                  loadingText="Update"
                  type="submit"
                  bg="#114684"
                  color="white"
                  w="100%"
                  _hover={{
                    bg: '#114684',
                    color: 'white',
                    cursor: areDifferent ? 'pointer' : 'not-allowed',
                  }}
                  isDisabled={!areDifferent}
                >
                  Update
                </Button>
              </Box>
            </Flex>
          </Box>
        </form>
      </Container>
    </>
  );
}

export default BrokerageProfile;
