import { Input, useBreakpointValue } from '@chakra-ui/react';

import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import { LoadingOverlay } from '@mantine/core';
import { useEffect, useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyUserEmail } from 'src/Redux/Auth/Auth';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useAppToast } from 'src/hooks';

const VerifyUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formWidth = useBreakpointValue({ base: '96%', sm: '57%' });
  const auth = useAppSelector((state) => state.auth);
  const toast = useAppToast();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userToken = urlParams?.get('token');
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    if (!!userToken) {
      dispatch(verifyUserEmail({ token: userToken }) as any);
    }
  }, []);

  useEffect(() => {
    const { status, type, verifyUserData, error } = auth;
    switch (status) {
      case 'loading':
        break;
      case 'succeed': {
        if (type === 'VERIFY_EMAIL') {
          setEmail(verifyUserData?.data?.email);
          toast({
            title: verifyUserData?.msg,
            status: 'success',
          });
          redirectLoginPage();
        }
        break;
      }
      case 'failed':
        if (type == 'VERIFY_EMAIL') {
          toast({
            title: error,
            status: 'error',
          });
          redirectLoginPage();
        }
        break;

      default:
        break;
    }
  }, [auth.status]);

  const redirectLoginPage = () => {
    setTimeout(() => {
      navigate('/');
    }, 1000);
  };

  return (
    <>
      <Box h="100%">
        <Flex>
          <Container maxW="100%" p={['0px', '0px', '0px']}>
            <Flex flexWrap="wrap" height="100vh  " gap={[5, 0, 0, 0]}>
              <Box
                bg="#114684"
                borderRadius={[
                  '0px 200px 0px 0px',
                  '0px 245px 0px 0px',
                  '0px 350px 0px 0px',
                ]}
                w={['100%', '40%']}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDir={'column'}
                gap={'10px'}
              >
                <Box display="flex" justifyContent="center">
                  <Image
                    src="Image/insurance3.jpg"
                    alt="insurance"
                    width={['700px', '400px', '500px', '700px']}
                    boxSize={['250px', '230px', '260px', '300px']}
                    objectFit="cover"
                  />
                </Box>
              </Box>

              <Container
                textAlign="center"
                w={formWidth}
                boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
                h="auto"
                margin="auto"
                p="18px"
                borderRadius="20px"
                display={'flex'}
                flexDir={'column'}
                gap={'0.1rem'}
              >
                <Text
                  fontWeight="bold"
                  color="#114684"
                  textAlign="start"
                  fontSize={28}
                >
                  Verify Email
                </Text>
                <Box pos="relative">
                  <LoadingOverlay
                    visible={
                      auth.status === 'loading' && auth.type === 'VERIFY_EMAIL'
                    }
                    zIndex={1000}
                    overlayBlur={0.5}
                    loaderProps={{ size: 'sm' }}
                  />
                  <Box>
                    <Box display="flex" flexDirection={'column'}>
                      <Text
                        fontWeight="bold"
                        color="#114684"
                        display="flex"
                        justifyContent="start"
                        textAlign="center"
                        fontSize={20}
                      >
                        Email Address
                      </Text>

                      <Box display="flex">
                        <Box
                          mr={2}
                          fontSize={25}
                          color="#114684"
                          m={0}
                          borderRadius="5px 0px 0px 5px"
                          backgroundColor="#f0f5f9"
                        >
                          <MdEmail
                            style={{
                              marginTop: '7px',
                              marginLeft: '6px',
                              marginRight: '6px',
                            }}
                          />
                        </Box>

                        <Input
                          ml="-2px"
                          fontSize="lg"
                          border="none"
                          backgroundColor="#f0f5f9"
                          focusBorderColor="transparent"
                          w="100%"
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          borderRadius="0px 5px 5px 0px"
                          fontFamily='"Montserrat", sans-se"'
                          value={email}
                          _hover={{ borderColor: '#114684' }}
                          isReadOnly={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Flex>
          </Container>
        </Flex>
      </Box>
    </>
  );
};

export default VerifyUser;
