import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchBrokerUserData } from 'src/Redux/Broker/BrokerProfile/BrokerProfile';
import { fetchBrokerageUserData } from 'src/Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import Navbar from '../Navbar';
import Sidebar from './Sidebar';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [navSize, changeNavSize] = useState<'small' | 'large'>('large');
  const location = useLocation();
  const userType = localStorage.getItem('userType');
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.broker.brokerProfileData);
  const brokerageSlice = useAppSelector((state) => state.brokerage.user);
  console.log('location', location, userType);

  const mlValue = useBreakpointValue({
    base: '178px',
    sm: '219px',
    md: '255px',
    lg: '300px',
  });

  const mlValue2 = useBreakpointValue({
    base: '50px',
    sm: '50px',
    md: ' 50px',
    lg: '50px',
  });

  useEffect(() => {
    if (userType === 'brokerage') {
      if (!brokerageSlice || !Object.keys(brokerageSlice).length) {
        dispatch(fetchBrokerageUserData() as any);
      }
    } else if (userType === 'broker') {
      if (!userData || !Object.keys(userData?.data).length) {
        dispatch(fetchBrokerUserData() as any);
      }
    }
  }, [brokerageSlice, userData]);

  return (
    <Box>
      <Flex>
        <Sidebar navSize={navSize} changeNavSize={changeNavSize} />
        <Box width={'100%'}>
          <Navbar navSize={navSize} changeNavSize={changeNavSize} />
          <Box
            height={{ base: 'calc(100vh - 64px)', md: 'calc(100vh - 80px)' }}
            overflow={
              userType === 'broker' && location?.pathname === '/'
                ? 'hidden'
                : 'auto'
            }
            transition={'0.3s'}
            pl={{ base: 0, md: navSize !== 'small' ? mlValue : mlValue2 }}
          >
            {children}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Layout;
