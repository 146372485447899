import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callAPI from '../../utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Reports/WorkInProgress/slice';
import initialState, { IReportLinks, IUploadReportsData } from './state';

export const uploadReports = createAsyncThunk(
  'uploadReports/uploadReports',
  async (
    payload: {
      applicationId: number;
      reportInfo: {
        type: string;
        timeFrame: string | null;
        reportName: string;
      };
      reportLinks: IReportLinks[];
      isCombinedReport?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      if (!!payload?.isCombinedReport) {
        delete payload?.isCombinedReport;
        const response = await callAPI(
          'report/uploadCombined',
          'POST',
          payload
        );
        return response.data;
      } else {
        const response = await callAPI('report/upload', 'POST', payload);
        return response.data;
      }
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const updateReports = createAsyncThunk(
  'uploadReports/updateReports',
  async (
    payload: Pick<IUploadReportsData, 'id' | 'reportLinks'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('report/update', 'PUT', payload);
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const listAllReports = createAsyncThunk(
  'uploadReports/listAllReports',
  async ({ applicationId }: { applicationId: number }, { rejectWithValue }) => {
    try {
      const payload = {
        applicationId,
      };
      const response = await callAPI(`report/list`, 'POST', payload);

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const deleteReport = createAsyncThunk(
  'uploadReports/deleteReport',
  async ({ reportID }: { reportID: number }, { rejectWithValue }) => {
    try {
      const response = await callAPI(`report/delete/${reportID}`, 'DELETE');
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const OcrReportScan = createAsyncThunk(
  'uploadReports/OcrReportScan',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      console.log('payload', payload);

      const response = await callAPI(
        'ocr/report/scan',
        'POST',
        payload,
        true,
        'multipart/form-data'
      );
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const uploadReportsStore = createSlice({
  name: 'uploadReports',
  initialState,
  reducers: {
    removeUploadReportsSliceType(state, action) {
      state.type = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadReports.pending, (state, action) => {
        state.type = 'POST_UPLOAD_REPORTS';
        state.status = 'loading';
      })
      .addCase(uploadReports.fulfilled, (state, action) => {
        state.type = 'POST_UPLOAD_REPORTS';
        state.status = 'succeed';
        state.uploadReportsData = action.payload;
      })
      .addCase(uploadReports.rejected, (state, action) => {
        state.type = 'POST_UPLOAD_REPORTS';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(listAllReports.pending, (state, action) => {
        state.type = 'GET_APPLICANT_REPORTS';
        state.status = 'loading';
      })
      .addCase(listAllReports.fulfilled, (state, action) => {
        state.type = 'GET_APPLICANT_REPORTS';
        state.status = 'succeed';
        state.applicantReportsData = action.payload.data;
      })
      .addCase(listAllReports.rejected, (state, action) => {
        state.type = 'GET_APPLICANT_REPORTS';
        state.status = 'failed';
        state.applicantReportsData = null;
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(deleteReport.pending, (state, action) => {
        state.type = 'DELETE_REPORT';
        state.status = 'loading';
      })
      .addCase(deleteReport.fulfilled, (state, action) => {
        state.type = 'DELETE_REPORT';
        state.status = 'succeed';

        state.applicantReportsData = action.payload.data;
      })
      .addCase(deleteReport.rejected, (state, action) => {
        state.type = 'DELETE_REPORT';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(updateReports.pending, (state, action) => {
        state.type = 'UPDATE_REPORT';
        state.status = 'loading';
      })
      .addCase(updateReports.fulfilled, (state, action) => {
        state.type = 'UPDATE_REPORT';
        state.status = 'succeed';

        // state.applicantReportsData = action.payload.data;
      })
      .addCase(updateReports.rejected, (state, action) => {
        state.type = 'UPDATE_REPORT';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      })

      //OCR Report Scan
      .addCase(OcrReportScan.pending, (state, action) => {
        state.type = 'OCR_REPORT_SCAN';
        state.status = 'loading';
      })
      .addCase(OcrReportScan.fulfilled, (state, action) => {
        state.type = 'OCR_REPORT_SCAN';
        state.status = 'succeed';
        state.ocrReportData = action.payload.data;
      })
      .addCase(OcrReportScan.rejected, (state, action) => {
        state.type = 'OCR_REPORT_SCAN';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const uploadReportsSlice = uploadReportsStore.reducer;

export default uploadReportsSlice;
export const { removeUploadReportsSliceType } = uploadReportsStore.actions;
