import { Box, Button, Flex } from '@chakra-ui/react';
import { Modal, Select } from '@mantine/core';
import { MRT_Row } from 'mantine-react-table';
import { useState } from 'react';
import { brokerStatusChange } from 'src/Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import { useAppDispatch } from 'src/Redux/Store';
import { BrokerageManagementTable } from '../BrokerageManagement';

const AssignPermissionBrokerModal = ({
  isOpen,
  onClose,
  tableData,
  selectedRow,
}: {
  isOpen: boolean;
  onClose: () => void;
  tableData: BrokerageManagementTable[];
  selectedRow: MRT_Row<BrokerageManagementTable> | null;
}) => {
  const dispatch = useAppDispatch();

  const [selectedBroker, setSelectedBroker] = useState<string | null>(null);
  console.log('tableData', tableData, selectedRow);

  const handleClose = () => {
    setSelectedBroker(null);
    onClose();
  };

  const handleBrokerChange = () => {
    console.log('selectedBroker', selectedBroker);
    // onConfirm(selectedBroker);

    if (selectedRow && selectedBroker) {
      dispatch(
        brokerStatusChange({
          oldBrokerId: selectedRow.original.brokerId,
          brokerageStatus:
            selectedRow.original.brokerageStatus === 'pending'
              ? 'decline'
              : 'revoke',
          newBrokerId: Number(selectedBroker),
        })
      );
    }
    handleClose();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title="Brokers"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'lg'}
      styles={{
        title: {
          fontWeight: 700,
          fontSize: '1.2rem',
        },
      }}
    >
      <Box margin={'10px'} display={'flex'} flexDirection={'column'} gap={15}>
        <Select
          label="Select Broker"
          placeholder="Select Broker"
          description={
            'Please select the other broker, and assign the applications to new broker.'
          }
          clearable
          allowDeselect={false}
          data={tableData
            ?.filter((el) => el.brokerId !== selectedRow?.original?.brokerId)
            .map((item) => ({
              value: item?.brokerId?.toString(),
              label: item?.firstName + ' ' + item?.lastName,
            }))}
          value={selectedBroker}
          onChange={setSelectedBroker}
          maxDropdownHeight={160}
          withinPortal={true}
          dropdownPosition="bottom"
        />
        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={5}>
          <Button
            type="button"
            onClick={handleClose}
            //   isDisabled={ocrUpdateLoader}
          >
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="blue"
            onClick={handleBrokerChange}
            loadingText="Submit"
            // isLoading={ocrUpdateLoader}
            // isDisabled={ocrUpdateLoader}
          >
            Submit
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default AssignPermissionBrokerModal;
