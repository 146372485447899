import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { LuRotateCw } from 'react-icons/lu';
import { RiZoomInLine, RiZoomOutLine } from 'react-icons/ri';

interface PDFControlsProps {
  pageNumber: number;
  numPages: number;
  scale: number;
  rotation: number;
  onPageChange: (newPage: number) => void;
  onZoomChange: (newScale: number) => void;
  onRotationChange: (newRotation: number) => void;
}

const PDFControls: React.FC<PDFControlsProps> = ({
  pageNumber,
  numPages,
  scale,
  rotation,
  onPageChange,
  onZoomChange,
  onRotationChange,
}) => {
  const buttonClass = 'p-2 rounded bg-light';

  return (
    <div className="d-flex align-items-center justify-content-center gap-4 bg-white p-4 rounded shadow">
      <button
        onClick={() => onPageChange(pageNumber - 1)}
        disabled={pageNumber <= 1}
        className={`${buttonClass} ${pageNumber <= 1 && 'opacity-50 cursor-not-allowed'}`}
      >
        <FaChevronLeft className="w-5 h-5" />
      </button>

      <span className="text-sm">
        Page {pageNumber} of {numPages}
      </span>

      <button
        onClick={() => onPageChange(pageNumber + 1)}
        disabled={pageNumber >= numPages}
        className={`
        ${buttonClass} ${pageNumber >= numPages && 'opacity-50 cursor-not-allowed'}
        `}
      >
        <FaChevronRight className="w-5 h-5" />
      </button>

      <div className="w-px h-6 bg-gray-300 mx-2" />

      <button
        onClick={() => onZoomChange(scale - 0.2)}
        disabled={scale <= 0.5}
        className={`${buttonClass} ${scale <= 0.5 && 'opacity-50 cursor-not-allowed'}`}
      >
        <RiZoomOutLine className="w-5 h-5" />
      </button>

      <span className="text-sm">{Math.round(scale * 100)}%</span>

      <button
        onClick={() => onZoomChange(scale + 0.2)}
        disabled={scale >= 2}
        className={`${buttonClass} ${scale >= 2 && 'opacity-50 cursor-not-allowed'}`}
      >
        <RiZoomInLine className="w-5 h-5" />
      </button>

      <div className="w-px h-6 bg-gray-300 mx-2" />

      <button
        onClick={() => onRotationChange((rotation + 90) % 360)}
        className={buttonClass}
      >
        <LuRotateCw className="w-5 h-5" />
      </button>
    </div>
  );
};

export default PDFControls;
