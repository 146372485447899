import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Select,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useObjectComparator from '../../../hooks/useObjectComparator';
import { BrokerageAllData } from '../../../Redux/Applicant/AddBrokerage/AddBrokerage';
import {
  BrokerUpdate,
  fetchBrokerUserData,
} from '../../../Redux/Broker/BrokerProfile/BrokerProfile';
import { RootState } from '../../../Redux/Store';
import {
  preventAlphabetsTyping,
  trimStringValues,
} from '../../../utils/helpers';
import { BrokerProfileSchema } from '../../../ValidationSchema/Auth/Index';

function BrokerProfile() {
  const Toast = useToast();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(
    (state: RootState) => state.broker.brokerProfileData
  );
  let userGetidData = userData && userData.data;
  const brokerid = useSelector((state: RootState) => state.brokerageID.user);
  let userLegalName = brokerid && brokerid.data;
  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const { areDifferent, setInitialValues, setNewValues } =
    useObjectComparator();

  useEffect(() => {
    fetchBrokerageAllData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        if (!userData || !Object.keys(userGetidData).length) {
          const actionResult = await dispatch(fetchBrokerUserData() as any);
          const response = actionResult?.payload;
          if (response?.payload?.status === 401) {
            navigate('/login');
          }
        }
      } else {
        navigate('/login');
      }
    };
    fetchData();
  }, [dispatch]);

  const fetchBrokerageAllData = async () => {
    if (!!localStorage.getItem('Token')) {
      const actionResult = await dispatch(BrokerageAllData() as any);
      const response = actionResult?.payload;
      if (response?.payload?.status === 401) {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  };

  const fetchData = async () => {
    if (!!localStorage.getItem('Token')) {
      const actionResult = await dispatch(fetchBrokerUserData() as any);
      const response = actionResult?.payload;
      if (response?.payload?.status === 401) {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (userGetidData) {
      formik.setValues({
        firstName: userGetidData.firstName || '',
        lastName: userGetidData.lastName || '',
        email: userGetidData.email || '',
        contactNumber: userGetidData.contactNumber || '',
        brokerageId: userGetidData.brokerageId || '',
      });

      setInitialValues({
        firstName: userGetidData.firstName || '',
        lastName: userGetidData.lastName || '',
        email: userGetidData.email || '',
        contactNumber: userGetidData.contactNumber || '',
        brokerageId: userGetidData.brokerageId || '',
      });
    }
  }, [userGetidData]);

  interface FormValues {
    lastName: string;
    firstName: string;
    contactNumber: number | null;
    email?: string;
    brokerageId: number | null;
  }
  const initialValues: FormValues = {
    lastName: '',
    firstName: '',
    contactNumber: null,
    email: '',
    brokerageId: null,
  };

  const formik = useFormik({
    initialValues,

    validationSchema: BrokerProfileSchema,

    onSubmit: async (values: FormValues, { resetForm }) => {
      setIsProfileUpdate(true);
      let res = '';
      try {
        if (!areDifferent) {
          setIsProfileUpdate(false);
          return;
        }
        delete values.email;
        const actionResult = await dispatch(
          BrokerUpdate(trimStringValues(values)) as any
        );
        const response = actionResult?.payload;
        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 200) {
          Toast({
            title: response.msg,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
          resetForm();
        } else {
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          } else {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 1000,
            position: 'top-right',
          });
        }
      }
      await fetchData();
      setIsProfileUpdate(false);
    },
  });
  useEffect(() => {
    setNewValues(formik.values);
  }, [formik.values]);
  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        h="auto"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        p="0px 22px 0px 22px"
        borderRadius="20px"
      >
        <Box>
          <Text
            fontWeight="600"
            color="#114684"
            textAlign="center"
            fontSize={20}
            pt="20px"
            mb="10px"
          >
            Broker Profile
          </Text>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="First Name" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder=" First Name"
                  name="firstName"
                  borderRadius="5px"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.firstName && formik.errors.firstName && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.firstName}
              </div>
            )}

            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="Last Name" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  borderRadius="5px"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.lastName && formik.errors.lastName && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.lastName}
              </div>
            )}

            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="Contact Number" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="tel"
                  placeholder="Contact Number"
                  name="contactNumber"
                  borderRadius="5px"
                  value={formik.values.contactNumber ?? ''}
                  onChange={(e) =>
                    formik.setFieldValue('contactNumber', e.target.value.trim())
                  }
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                  onKeyDown={preventAlphabetsTyping}
                />
              </Tooltip>
            </Box>
            {formik.touched.contactNumber && formik.errors.contactNumber && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.contactNumber}
              </div>
            )}

            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="Email Address" placement="right" hasArrow>
                <Input
                  disabled={true}
                  fontSize={'13px'}
                  w="100%"
                  type="Email"
                  placeholder="Email Address"
                  name="email"
                  borderRadius="5px"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.email && formik.errors.email && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.email}
              </div>
            )}
            <Tooltip label="Brokerage Name" placement="right" hasArrow>
              <Box display="flex" flexDirection={'column'}>
                <Box display="flex">
                  <Select
                    name="brokerageId"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.brokerageId ?? ''}
                    borderRadius="0px 5px 5px 0px"
                    _hover={{ borderColor: '#114684' }}
                    size={['md', 'sm', 'md', 'md']}
                    marginLeft={'-2px'}
                    bg={'#f0f5f9'}
                    border="none"
                    focusBorderColor="transparent"
                    fontSize={'13px'}
                    isDisabled={['approved', 'grant'].includes(
                      userGetidData?.brokerageStatus
                    )}
                  >
                    <option label=" Select Brokerage" />
                    {Array.isArray(userLegalName) &&
                      userLegalName.map((user: any, index: number) => (
                        <option
                          key={index}
                          value={user.brokerageId}
                          label={user.legalName}
                        />
                      ))}
                  </Select>
                </Box>

                {formik.touched.brokerageId && formik.errors.brokerageId && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.brokerageId}
                  </div>
                )}
              </Box>
            </Tooltip>

            <Flex justifyContent="space-evenly" columnGap={5}>
              <Box mt="20px" w="100%" mb={10}>
                <Button
                  isLoading={isProfileUpdate}
                  loadingText="Update"
                  type="submit"
                  bg="#114684"
                  color="white"
                  w="100%"
                  _hover={{
                    bg: '#114684',
                    color: 'white',
                    cursor: areDifferent ? 'pointer' : 'not-allowed',
                  }}
                  isDisabled={!areDifferent}
                >
                  Update
                </Button>
              </Box>
            </Flex>
          </Box>
        </form>
      </Container>
    </>
  );
}

export default BrokerProfile;
