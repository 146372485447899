import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callAPI from 'src/utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Documents/slice';
import initialState from './state';

export const getAdminDashboardList = createAsyncThunk(
  'admin/getAdminDashboardList',
  async (_, { rejectWithValue }) => {
    try {
      const res = await callAPI('/admin/get-all-dashboard', 'GET');
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const adminDeletBrokerage = createAsyncThunk(
  'admin/adminDeletBrokerage',
  async (
    { brokerageId, action }: { brokerageId: number; action: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(
        `/admin/delete-brokerage/${brokerageId}`,
        'DELETE',
        { type: action },
        false
      );
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const adminStore = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get admin dashboard list api
      .addCase(getAdminDashboardList.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_ALL_ADMIN_DASHBOARD_LIST';
      })
      .addCase(getAdminDashboardList.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_ALL_ADMIN_DASHBOARD_LIST';
        state.adminDashboardList = action.payload.data;
      })
      .addCase(getAdminDashboardList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_ALL_ADMIN_DASHBOARD_LIST';
        state.error = isRejectedActionWithPayload(action);
      })
      //Admin action api
      .addCase(adminDeletBrokerage.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'BROKERAGE_STATUS_UPDATED_BY_ADMIN';
      })
      .addCase(adminDeletBrokerage.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'BROKERAGE_STATUS_UPDATED_BY_ADMIN';
        state.msg = action.payload?.data?.message;
      })
      .addCase(adminDeletBrokerage.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'BROKERAGE_STATUS_UPDATED_BY_ADMIN';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const adminSlice = adminStore.reducer;

export default adminSlice;
