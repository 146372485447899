import { APIStatus } from 'src/types/unions';

export interface ApplicationHistoryData {
  historyId: number;
  applicationId: number;
  modelName: string;
  action: string;
  initiatedBy: string;
  applicationStatus: string;
  applicantId: number | null;
  brokerId: number;
  accountantId: number | null;
  timestamp: string;
  details: {
    action: string;
    isDraft: boolean;
    isFinish: boolean;
    applicationId: number;
    brokerDashboard: string;
    applicantDashboard: string;
  };
  createdAt: string;
  updatedAt: string;
  applicant: Record<string, any> | null;
  broker: {
    brokerId: number;
    firstName: string;
    lastName: string;
    email: string;
    contactNumber: number;
    termCondition: boolean;
    rokerageId: number;
    createdAt: string;
    updatedAt: string;
  };
  accountant: Record<string, any> | null;
}

export interface dunsResData {
  applicationId: number;
  mappingErrors: Record<string, any>;
  msg: string;
}

export interface PDFPreferenceLegalFormData {
  data: {
    uuid: string;
    label: string;
    isChecked: boolean;
    status: string;
  }[];
  status: {
    status: string;
    isStatusChecked: boolean;
  }[];
  question: string;
}
export interface IPdfPreferences {
  legal: PDFPreferenceLegalFormData[];
}
export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_APPLICATION_BY_ID'
    | 'GET_APPLICATIONS_BY_STATUS'
    | 'GET_APPLICATION_HISTORY'
    | 'GET_APPLICATION_USER_KEY'
    | 'APPLICATION_REFRESH_DUNS'
    | 'GET_PDF_PREFERENCES'
    | 'SAVE_PDF_PREFERENCES'
    | 'GET_BROKER_DASHBOARD_APP_LIST'
    | null;
  applications: IApplication[];
  currentApplication: IApplication | null;
  applicationHistory: ApplicationHistoryData[];
  userKey: string | null;
  dunsData: dunsResData | null;
  pdfPreferences: IPdfPreferences | null;
  data: Record<string, any> | null;
  brokerDashboardAppList: IApplication[] | null;
  errorResponse: Record<string, any> | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  applications: [],
  currentApplication: null,
  applicationHistory: [],
  userKey: null,
  dunsData: null,
  pdfPreferences: null,
  data: null,
  brokerDashboardAppList: null,
  errorResponse: null,
};

export default initialState;
