import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { APIStatus } from 'src/types/unions';
import callAPI from 'src/utils/callAPI';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';
import { isRejectedActionWithPayload } from '../Documents/slice';

interface BrokerState {
  user: any;
  loading: boolean;
  error: any;
  type?: 'GET_APPLICANT' | 'GET_BROKER_PROFILE';
  status: APIStatus | null;
  brokerProfileData: Record<string, any> | null;
}

const initialState: BrokerState = {
  user: null,
  loading: false,
  error: null,
  status: null,
  brokerProfileData: null,
};

export const Brokerage = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

interface RequestData {
  lastName: string;
  firstName: string;
  contactNumber: number | null;
  email: string;
  brokerageId: number | null;
}

// Broker get api

export const BrokerUpdate = createAsyncThunk(
  'Broker/BrokerUpdate',
  async (requestData: RequestData, { rejectWithValue }) => {
    try {
      const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/broker/update/profile`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// export const fetchBrokerUserData = createAsyncThunk(
//   'broker/fetchBrokerUserData',
//   async () => {
//     try {
//       // const userId = localStorage.getItem('userID');
//       const Token = localStorage.getItem('Token');
//       try {
//         const config = {
//           method: 'get',
//           maxBodyLength: Infinity,
//           url: `${BASEURL}/user/broker/get/profile`,
//           headers: {
//             Authorization: `Bearer ${Token}`,
//             'Content-Type': 'application/json',
//           },
//         };
//         const response = await axios.request(config);

//         return response.data;
//       } catch (error: any) {
//         if (error?.response?.status === 401) {
//           const result = tokenExipry();
//           return result;
//         }
//         return error?.response?.data;
//       }
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const fetchBrokerUserData = createAsyncThunk(
  'broker/fetchBrokerUserData',
  async (_, { rejectWithValue }) => {
    try {
      const res = await callAPI('user/broker/get/profile', 'GET', null, false);
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const fetchApplicantDetails = createAsyncThunk(
  'applicant/fetchApplicantDetails',
  async ({ applicantId }: { applicantId: string }, { rejectWithValue }) => {
    console.log('app', applicantId);

    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/user/applicant/get/${applicantId}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

const brokerSlice = createSlice({
  name: 'broker',
  initialState,
  reducers: {
    removeBrokerData(state, action) {
      state.user = null;
      state.brokerProfileData = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // brokerage update
      .addCase(BrokerUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BrokerUpdate.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(BrokerUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Broker get api

      .addCase(fetchBrokerUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.type = 'GET_BROKER_PROFILE';
        state.status = 'loading';
      })
      .addCase(
        fetchBrokerUserData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.brokerProfileData = action.payload;
          state.type = 'GET_BROKER_PROFILE';
          state.status = 'succeed';
        }
      )
      .addCase(fetchBrokerUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.type = 'GET_BROKER_PROFILE';
        state.status = 'failed';
      })

      //get Additional applicant details on broker Applicant detail tab
      .addCase(fetchApplicantDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.type = 'GET_APPLICANT';
        state.status = 'loading';
      })
      .addCase(
        fetchApplicantDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
          state.type = 'GET_APPLICANT';
          state.status = 'succeed';
        }
      )
      .addCase(fetchApplicantDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
        state.type = 'GET_APPLICANT';
        state.status = 'failed';
      });
  },
});

export default brokerSlice.reducer;
export const { removeBrokerData } = brokerSlice.actions;
