import { Button, Spinner, Text } from '@chakra-ui/react';
import { Box, Flex, Modal, MultiSelect, Stack, Title } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppToast } from 'src/hooks';
import {
  getPDFPreferanceData,
  savePDFPreferanceData,
} from 'src/Redux/Applications/slice';
import { PDFPreferenceLegalFormData } from 'src/Redux/Applications/state';
import { RootState, useAppSelector } from 'src/Redux/Store';
import { getPdfPreferenceQuestionName } from 'src/utils/forms/legalForm';

const LegalPdfPreference = ({
  opened,
  close,
  applicationId,
}: {
  opened: boolean;
  close: () => void;
  applicationId: number;
}) => {
  const toast = useAppToast();
  const dispatch = useDispatch();
  const appliactionsSlice = useAppSelector(
    (state: RootState) => state.appliactionsSlice
  );
  const [questionData, setQuestionData] = useState<
    Record<string, PDFPreferenceLegalFormData>
  >({});

  const initialData = useRef<Record<string, PDFPreferenceLegalFormData>>();

  const [loader, setLoader] = useState({
    contentLoader: false,
    btnLoader: false,
  });

  const getPdfPreferenceModalData = (data: PDFPreferenceLegalFormData[]) => {
    const aa: Record<string, PDFPreferenceLegalFormData> = {};

    data?.forEach((el) => {
      aa[el?.question] = {
        ...el,
        status: el?.status?.map((elm) => {
          const aa = el.data.filter((q) => q.status === elm.status);
          const isoneOfDataSelected = aa?.some((e) => e.isChecked);
          const selectedData = aa?.filter((item) => item?.isChecked);
          // console.log('aa', aa, el.question);

          return {
            ...elm,
            label: `${elm.status} (${isoneOfDataSelected ? selectedData?.length : aa?.length})`,
          };
        }),
      };
    });

    setQuestionData(aa);
    initialData.current = aa;
  };

  useEffect(() => {
    if (opened) {
      dispatch(
        getPDFPreferanceData({
          formName: 'Credit Bureau',
          applicationId: applicationId,
        }) as any
      );
    }
  }, [opened]);

  useEffect(() => {
    console.log('appliactionsSlice', appliactionsSlice);
    const { status, type, pdfPreferences, error, data } = appliactionsSlice;
    switch (status) {
      case 'loading':
        if (type === 'GET_PDF_PREFERENCES') {
          setLoader({ ...loader, contentLoader: true, btnLoader: false });
        }
        if (type === 'SAVE_PDF_PREFERENCES') {
          setLoader({ ...loader, btnLoader: true });
        }
        break;
      case 'succeed':
        if (type === 'GET_PDF_PREFERENCES' && pdfPreferences) {
          getPdfPreferenceModalData(pdfPreferences?.legal);
          setLoader({ ...loader, contentLoader: false, btnLoader: false });
        }
        if (type === 'SAVE_PDF_PREFERENCES') {
          toast({
            title: data?.msg,
            status: 'success',
          });
          setLoader({ ...loader, btnLoader: false });
          dispatch(
            getPDFPreferanceData({
              formName: 'Credit Bureau',
              applicationId: applicationId,
            }) as any
          );
        }
        break;
      case 'failed': {
        if (type === 'GET_PDF_PREFERENCES' || type === 'SAVE_PDF_PREFERENCES') {
          setLoader({ ...loader, contentLoader: false, btnLoader: false });
          toast({
            title: error,
            status: 'error',
          });
        }

        return;
      }

      default:
        break;
    }
  }, [appliactionsSlice?.status]);

  const handleChangeStatus = (value: string[], name: string) => {
    if (!questionData) return;
    const currData = questionData[name];

    // const data = value.map((val) => {
    //   return currData.data.filter((elm) => elm.status === val);
    // });

    // console.log('data imp', data);

    const selectedStsData = currData?.data?.reduce((acc, curr, index, data) => {
      console.log('data', data);

      const stsData = data?.filter((el) => el.status === curr?.status);
      // console.log('stsData', stsData);

      if (
        !!value.includes(curr.status) &&
        stsData?.some((itm) => itm?.isChecked)
      ) {
        acc = {
          ...acc,
          [curr.status]: {
            checkedLength: stsData?.filter((c1) => c1?.isChecked)?.length,
            isCheck: true,
          },
        };
      } else {
        acc = {
          ...acc,
          [curr.status]: {
            checkedLength: stsData?.length,
            isCheck: false,
          },
        };
      }
      return acc;
    }, {} as any);

    // console.log('selectedStsData', selectedStsData);

    const updatedStatusData = currData?.status?.map((self) => ({
      ...self,
      isStatusChecked: !!value?.includes(self?.status),
      status: self.status,
      label: `${self?.status} (${selectedStsData[self?.status]?.['checkedLength']})`,
    }));

    const updatedData = currData?.data?.map((self: any) => {
      console.log(self, value);

      if (value && value?.length > 0) {
        return {
          ...self,
          isChecked: selectedStsData?.[self?.status]?.['isCheck']
            ? self?.isChecked
            : !!value?.includes(self?.status),
        };
      } else {
        return { ...self, isChecked: false };
      }
    });

    setQuestionData({
      ...questionData,
      [name]: {
        ...questionData[name],
        status: updatedStatusData,
        data: updatedData,
      },
    });
  };

  const handleChangeData = (value: string[], name: string) => {
    if (!initialData.current) return;
    const currData = initialData.current[name];
    const updatedData = currData?.data?.map((self: any) => ({
      ...self,
      isChecked: !!value?.includes(self?.uuid),
    }));

    const countData = currData.data
      .filter((aa) => value.includes(aa.uuid))
      .reduce((acc, curr) => {
        if (acc[curr.status]) {
          acc[curr.status] += 1;
        } else {
          acc[curr.status] = 1;
        }

        return acc;
      }, {} as any);

    let selectedDataStatus = updatedData
      ?.filter((item: any) => item?.isChecked)
      ?.map((el: any) => el.status);

    const updateSelecStatus = currData?.status?.map((item: any) => ({
      ...item,
      isStatusChecked: !!selectedDataStatus?.includes(item?.status),
      label: `${item.status} (${countData[item.status] ?? currData.data.filter((aa) => aa.status === item.status).length})`,
    }));

    setQuestionData({
      ...questionData,
      [name]: {
        ...questionData[name],
        data: updatedData,
        status: updateSelecStatus,
      },
    });
  };

  const submitPdfPreferences = async () => {
    await dispatch(
      savePDFPreferanceData({
        applicationId,
        legal: Object.values(questionData),
      }) as any
    );
  };

  console.log('questionData', questionData, Object.values(questionData));

  return (
    <Modal
      opened={opened}
      onClose={close}
      title="Legal PDF Preference"
      centered
      size={'xl'}
      overlayProps={{
        blur: 3,
        opacity: 0.55,
      }}
      styles={{
        body: {
          minHeight: loader?.contentLoader ? '518px' : 'max-content',
        },
        title: {
          fontWeight: 700,
          fontSize: '1.2rem',
        },
      }}
    >
      <Stack spacing={20}>
        {loader?.contentLoader ? (
          <Flex h={'518px'} align={'center'} justify={'center'}>
            <Spinner size={'md'} />
          </Flex>
        ) : Object.keys(questionData)?.length > 0 ? (
          Object.values(questionData)?.map(
            ({ data, question, status }, index) => {
              return (
                <Box key={question}>
                  <Title order={6}>
                    {getPdfPreferenceQuestionName[question]}
                  </Title>
                  <Flex gap={14}>
                    <Stack sx={{ flex: 1 }}>
                      {/* <Text>sadsdsd</Text> */}
                      <MultiSelect
                        key={question}
                        className="legalform_pdfPreference"
                        data={status?.map((el: any) => ({
                          label: el?.label,
                          value: el?.status,
                        }))}
                        label="Select Status"
                        placeholder="Select Status"
                        maxDropdownHeight={160}
                        disabled={!status?.length}
                        style={{
                          cursor: !status?.length ? 'not-allowed' : 'pointer',
                        }}
                        onChange={(value) =>
                          handleChangeStatus(value, question)
                        }
                        value={status
                          ?.filter((el: any) => el?.isStatusChecked)
                          ?.map((item: any) => item?.status)}
                        withinPortal={true}
                      />
                    </Stack>
                    <Stack sx={{ flex: 1 }}>
                      {/* <Text>sadsdsd</Text> */}
                      <MultiSelect
                        key={question}
                        className="legalform_pdfPreference"
                        data={data.map((el: any) => {
                          // console.log('femil data', data);

                          return {
                            label: el?.label,
                            value: el?.uuid,
                          };
                        })}
                        label="Select Filing"
                        placeholder="Select Filing"
                        searchable
                        maxDropdownHeight={160}
                        disabled={!data?.length}
                        style={{
                          cursor: !data?.length ? 'not-allowed' : 'pointer',
                        }}
                        onChange={(value) => handleChangeData(value, question)}
                        value={data
                          ?.filter((el: any) => el?.isChecked)
                          ?.map((item: any) => item?.uuid)}
                        withinPortal={true}
                      />
                    </Stack>
                  </Flex>
                </Box>
              );
            }
          )
        ) : (
          <Flex align={'center'} justify={'center'} h={'200px'}>
            <Text>No Data Found</Text>
          </Flex>
        )}
        <Box>
          <Button
            bg={'#114684'}
            color={'white'}
            _hover={{ bg: '#114684', color: 'white' }}
            float={'right'}
            type="button"
            onClick={submitPdfPreferences}
            isLoading={loader?.btnLoader}
            isDisabled={!Object.values(questionData)?.length}
            loadingText={'Save PDF Preferences'}
          >
            Save PDF Preferences
          </Button>
        </Box>
      </Stack>
    </Modal>
  );
};

export default LegalPdfPreference;
