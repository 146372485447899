import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { FileInput, Modal } from '@mantine/core';
import { IconFile, IconFileCv } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { useAppToast, useS3FileUpload } from 'src/hooks';
import { getS3FileInfo } from 'src/utils/helpers';
import { LEGAL_FORM_MAX_FILE_SIZE } from '../../utils';
import { dynamicConditionCheck } from './AccordianInput';
import AccordianInputLabel from './AccordianInputLabel';

const FileList = ({
  fileList,
  handleRemoveFile,
  isReadOnly,
}: {
  fileList: {
    linkId: number | null;
    link: string | null;
    urlKey: string | null;
  }[];
  handleRemoveFile: (file: {
    link: string;
    linkId: string;
    urlKey: string;
  }) => void;
  isReadOnly?: boolean;
}) => {
  return (
    <Box
      // border={'1px solid gray'}
      my={2}
    >
      {fileList &&
        fileList?.length > 0 &&
        fileList?.map((item: any) => {
          return (
            <Flex
              direction={'row'}
              justifyContent={'space-between'}
              flexWrap={'nowrap'}
              alignItems={'center'}
              // sx={{
              //   borderBottom: '1px solid gray',
              //   '&:last-child': {
              //     borderBottom: 'none',
              //   },
              // }}
              p={1}
            >
              <Box
                display={'flex'}
                gap={1}
                flexWrap={'nowrap'}
                alignItems={'center'}
              >
                <IconFile style={{ width: 18, height: 18 }} stroke={1.5} />
                <Text>{item?.urlKey?.split('/').pop()}</Text>
              </Box>
              <Tooltip fontSize="md" label="Remove File" placement={'auto'}>
                <Box
                  cursor={isReadOnly ? 'not-allowed' : 'pointer'}
                  onClick={isReadOnly ? () => {} : () => handleRemoveFile(item)}
                  aria-readonly={isReadOnly}
                >
                  <MdClear size={18} />
                </Box>
              </Tooltip>
            </Flex>
          );
        })}
    </Box>
  );
};

const LegalFormCourtFilingUpload = ({
  isOpen,
  onClose,
  modalTitle,
  formik,
  uploadState,
  name,
  value,
  isHistoryMode = false,
  isReadOnly,
  currentHistoryData = {},
}: {
  isOpen: boolean;
  onClose: () => void;
  modalTitle: string;
  formik: Record<string, any>;
  uploadState: string;
  name: string;
  value: {
    linkId: number | null;
    link: string | null;
    urlKey: string | null;
  }[];
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
}) => {
  const {
    S3UploadError,
    S3UploadLoading,
    S3UploadResponse,
    uploadbulkFilesInS3,
  } = useS3FileUpload();
  const toast = useAppToast();
  // const fileNames =
  //   value && value?.length > 0
  //     ? value?.map((item: any) => item?.urlKey?.split('/').pop())?.join(',')
  //     : null;
  const [fileList, setFileList] = useState<
    {
      linkId: number | null;
      link: string | null;
      urlKey: string | null;
    }[]
  >([]);

  const bgColor = dynamicConditionCheck(
    currentHistoryData,
    name,
    isHistoryMode,
    '#114684',
    'rgb(241, 243, 245)'
  );

  const color = dynamicConditionCheck(
    currentHistoryData,
    name,
    isHistoryMode,
    'white',
    'black'
  );

  console.log(
    'color-LegalFormCourtFilingUpload',
    bgColor,
    color,
    name,
    currentHistoryData
  );

  useEffect(() => {
    setFileList(value);
  }, []);

  useEffect(() => {
    if (S3UploadResponse) {
      // console.log('S3UploadResponse', S3UploadResponse, formik?.values);
      // const regex = /files\[(\w+)\[(\d+)\]\.courtFilings\]/;
      // const regex = new RegExp(
      //   `files\\[(\\w+)\\[(\\d+)\\]\\.(${uploadState})\\]`
      // );
      const regex = /files\[(\w+)\[(\d+)\]\.(\w+)\]/;
      // let values = formik?.values;
      const s3resData: Record<string, any> = S3UploadResponse;
      const { hasLiensCourtFilings } = s3resData?.reduce(
        (acc: any, item: any) => {
          switch (item.fieldname) {
            case `files[${name}]`:
              acc.hasLiensCourtFilings.push(item);
              break;
            default:
              break;
          }
          return acc;
        },
        {
          hasLiensCourtFilings: [] as any[],
        }
      );

      if (hasLiensCourtFilings?.length) {
        const matchRes = hasLiensCourtFilings[0]?.fieldname?.match(regex);
        // const liensStateName = matchRes[1];
        // const liensUploadIndex = matchRes[2];
        const [, liensStateName, liensUploadIndex, uploadFieldState] = matchRes;
        const updatedLiensState = formik?.values?.[liensStateName]?.map(
          (item: any, index: number) => {
            if (index === Number(liensUploadIndex)) {
              return {
                ...item,
                [uploadState]: item?.[uploadState]
                  ? [
                      ...item?.[uploadState],
                      ...getS3FileInfo(hasLiensCourtFilings),
                    ]
                  : getS3FileInfo(hasLiensCourtFilings),
              };
            } else {
              return item;
            }
          }
        );
        // console.log(
        //   'regex-matchRes',
        //   liensStateName,
        //   liensUploadIndex,
        //   uploadFieldState
        // );

        // console.log('updatedLiensState', updatedLiensState);
        setFileList(updatedLiensState?.[liensUploadIndex]?.[uploadFieldState]);
        formik.setFieldValue(liensStateName, updatedLiensState);
        formik.setFieldValue(uploadFieldState, []);
      }
      // console.log('values', values);

      //   submitHandler(values);
    }
    return () => {};
  }, [S3UploadResponse]);

  const handleRemoveFile = (file: {
    link: string;
    linkId: string;
    urlKey: string;
  }) => {
    const updatedFileList = fileList?.filter(
      (item) => item?.urlKey !== file?.urlKey
    );
    console.log('updatedFileList', updatedFileList, formik);
    setFileList(updatedFileList);
    // formik?.setFieldValue(name, updatedFileList);
  };

  const handleUploadDocument = async () => {
    console.log(
      'handleUploadDocument',
      formik?.values?.[uploadState],
      formik?.values,
      uploadState,
      name,
      fileList
    );
    const isLegalFormDocUpload =
      formik?.values?.[uploadState] && formik?.values?.[uploadState]?.length;
    const formData = new FormData();
    if (isLegalFormDocUpload) {
      formik?.values?.[uploadState]?.forEach((file: File) => {
        formData.append(`files[${name}]`, file);
      });
    }
    if (isLegalFormDocUpload) {
      console.log('FormData contents:', formData);
      formData.forEach((value, key) => {
        console.log(`${key}:`, value);
      });

      await uploadbulkFilesInS3(formData);

      if (S3UploadError) {
        toast({
          title: 'Erro in file uploading!!',
          status: 'error',
        });
        return;
      }
    }
    // else { // upload new doc and remove some doc (to handle both case remove else clause)
    formik.setFieldValue(name, fileList);
    toast({
      title: 'Success',
      status: 'success',
    });
    // }
  };

  const handleFileChange = (files: File[]) => {
    // const MAX_FILE_SIZE = 25 * 1024 * 1024;
    const largeFiles: string[] = [];
    const validFiles = files.filter((file) => {
      if (file.size > LEGAL_FORM_MAX_FILE_SIZE) {
        largeFiles.push(file.name);
        return false;
      }
      return true;
    });

    if (!!largeFiles?.length) {
      toast({
        title: `${largeFiles.join(', ')} file(s) exceed the size limit of 25 MB`,
        status: 'error',
      });
    }
    // Update formik or state with valid files only
    formik?.setFieldValue(uploadState, validFiles);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setFileList([]);
        formik.setFieldValue(uploadState, null);
        onClose();
      }}
      title={modalTitle}
      centered
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'725px'}
      className="global_history"
    >
      <Box margin={'10px 10px 0 10px'} maxW={'725px'} maxH={'600px'}>
        <AccordianInputLabel
          label={
            uploadState === 'courtFilings' ? 'Court Filings' : 'Payment Plan'
          }
        />
        <FileInput
          icon={
            <IconFileCv
              style={{
                width: 18,
                height: 18,
                color: color,
              }}
              stroke={1.5}
            />
          }
          styles={{
            input: {
              // maxWidth: '350px',
              border: '1px solid #7f7f7f',
              borderRadius: '5px',
              paddingTop: '6px',
              paddingBottom: '6px',
              cursor: isReadOnly ? 'not-allowed' : 'pointer',
              background: bgColor,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '&:hover': {
                border: '1px solid #7f7f7f',
                cursor: isReadOnly ? 'not-allowed' : 'pointer',
              },
              '&:focus': {
                border: '1px solid #7f7f7f',
              },
            },
            placeholder: {
              color: `${color} !important`,
            },
          }}
          // @ts-ignore
          placeholder={'Upload files'}
          // label="Upload files"
          multiple
          onChange={(payload: File[]) => {
            // console.log('payload', payload);
            handleFileChange(payload);
            // formik?.setFieldValue(uploadState, payload);
            // const formData = new FormData();
            // payload?.forEach((file: File) => {
            //   formData.append('files', file);
            // });
            // console.log('formData', formData);
          }}
          value={formik?.values?.[uploadState] ?? []}
          description="The file format should be - xlsx, xls, csv, pdf, doc, docx"
          // withAsterisk
          variant="filled"
          accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          readOnly={isReadOnly}
        />

        {fileList && fileList?.length > 0 && (
          <FileList
            fileList={fileList}
            handleRemoveFile={handleRemoveFile}
            isReadOnly={isReadOnly}
          />
        )}

        <Box display={'flex'} justifyContent={'end'} mt={'15px'}>
          <Button
            bg={'#114684'}
            color={'white'}
            _hover={{ bg: '#114684', color: 'white' }}
            type="button"
            onClick={handleUploadDocument}
            isLoading={S3UploadLoading}
            isDisabled={isReadOnly}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LegalFormCourtFilingUpload;
