//final BS report calculation Assets/Liabilities

import {
  BSCurrentLiabilitiesAndAssets,
  BSReportCalculation,
  BSReportTotalFixedAssets,
  BSReportTotalLongTermLiabilities,
  PLCostOfGoods,
  PLExpenses,
  PLIncomeType,
  PLReportCalculation,
  PLReportIncomeTaxes,
  PLReportProfit,
  PLTotalCostOfGoods,
} from 'src/Redux/OcrReports/state';

// Update totals for BSReportCalculation rows
export function updateBSReportCalculations(
  ocrReportData: Record<string, any>[]
) {
  // Find indices of rows for TotalCurrentAssets, TotalFixedAssets, and TotalAssets
  const totalCurrentAssetsIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalCurrentAssets
  );
  const totalFixedAssetsIndex = ocrReportData.findIndex(
    (row) =>
      row.Label ===
      Object.values(BSReportTotalFixedAssets).includes(row?.Label?.trim())
  );
  const totalAssetsIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalAssets
  );
  //find index for TotalCurrentLiabilities, TotalLongTermLiabilities, TotalLiabilities, TotalAccountsPayable

  const totalCurrentLiabilitiesIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalCurrentLiabilities
  );
  const totalLongTermLiabilitiesIndex = ocrReportData.findIndex(
    (row) =>
      row.Label ===
      Object.values(BSReportTotalLongTermLiabilities).includes(
        row?.Label?.trim()
      )
  );
  const totalLiabilitiesIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalLiabilities
  );
  const totalAccountsPayableIndex = ocrReportData.findIndex((row) =>
    row.Label.includes(BSReportCalculation.TotalAccountsPayable)
  );

  //find index for TotalLiabilitiesAndEquity , TotalEquity
  const totalLiabilitiesAndEquityIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalLiabilitiesAndEquity
  );
  const TotalEquityIndex = ocrReportData.findIndex(
    (row) => row.Label === BSReportCalculation.TotalEquity
  );

  // Calculate TotalCurrentAssets
  if (totalCurrentAssetsIndex !== -1) {
    const totalCurrentAssetsSum = calculateSumAbove(
      ocrReportData,
      totalCurrentAssetsIndex,
      'total'
    );
    // ocrReportData[totalCurrentAssetsIndex].Total = totalCurrentAssetsSum;
    ocrReportData[totalCurrentAssetsIndex] = {
      ...ocrReportData[totalCurrentAssetsIndex],
      Total: Number(parseFloat(totalCurrentAssetsSum.toString()).toFixed(2)),
    };
  }

  // Calculate TotalFixedAssets
  if (totalFixedAssetsIndex !== -1 && totalCurrentAssetsIndex !== -1) {
    const totalFixedAssetsSum = calculateSumBetween(
      ocrReportData,
      totalCurrentAssetsIndex + 1,
      totalFixedAssetsIndex,
      'total'
    );
    // ocrReportData[totalFixedAssetsIndex].Total = totalFixedAssetsSum;
    ocrReportData[totalFixedAssetsIndex] = {
      ...ocrReportData[totalFixedAssetsIndex],
      Total: Number(parseFloat(totalFixedAssetsSum.toString()).toFixed(2)),
    };
  }

  // Calculate TotalAssets
  if (
    ![
      totalAssetsIndex,
      totalCurrentAssetsIndex,
      totalFixedAssetsIndex,
    ].includes(-1)
  ) {
    const totalAssetsSum =
      (ocrReportData[totalCurrentAssetsIndex].Total || 0) +
      (ocrReportData[totalFixedAssetsIndex].Total || 0);
    // ocrReportData[totalAssetsIndex].Total = totalAssetsSum;
    ocrReportData[totalAssetsIndex] = {
      ...ocrReportData[totalAssetsIndex],
      Total: Number(parseFloat(totalAssetsSum.toString()).toFixed(2)),
    };
  }

  //Calculate TotalCurrentLiabilities
  if (totalCurrentLiabilitiesIndex !== -1 && totalAccountsPayableIndex !== -1) {
    const TotalCurrentLiabilitiesSum = calculateSumBetween(
      ocrReportData,
      totalAccountsPayableIndex,
      totalCurrentLiabilitiesIndex,
      'total'
    );

    ocrReportData[totalCurrentLiabilitiesIndex] = {
      ...ocrReportData[totalCurrentLiabilitiesIndex],
      Total: Number(
        parseFloat(TotalCurrentLiabilitiesSum.toString()).toFixed(2)
      ),
    };
  }

  //Calculate TotalLongTermLiabilities
  if (
    totalCurrentLiabilitiesIndex !== -1 &&
    totalLongTermLiabilitiesIndex !== -1
  ) {
    const TotalLongTermLiabilitiesSum = calculateSumBetween(
      ocrReportData,
      totalCurrentLiabilitiesIndex + 1,
      totalLongTermLiabilitiesIndex,
      'total'
    );
    ocrReportData[totalLongTermLiabilitiesIndex] = {
      ...ocrReportData[totalLongTermLiabilitiesIndex],
      Total: Number(
        parseFloat(TotalLongTermLiabilitiesSum.toString()).toFixed(2)
      ),
    };
  }

  // Calculate TotalLiabilities
  if (
    totalLiabilitiesIndex !== -1 &&
    totalCurrentLiabilitiesIndex !== -1 &&
    totalLongTermLiabilitiesIndex !== -1
  ) {
    const TotalLiabilitiesSum =
      (ocrReportData[totalCurrentLiabilitiesIndex].Total || 0) +
      (ocrReportData[totalLongTermLiabilitiesIndex].Total || 0);
    // ocrReportData[totalAssetsIndex].Total = totalAssetsSum;
    ocrReportData[totalLiabilitiesIndex] = {
      ...ocrReportData[totalLiabilitiesIndex],
      Total: Number(parseFloat(TotalLiabilitiesSum.toString()).toFixed(2)),
    };
  }

  // Calculate TotalLiabilitiesAndEquity
  if (
    totalLiabilitiesAndEquityIndex !== -1 &&
    totalCurrentLiabilitiesIndex !== -1 &&
    totalLongTermLiabilitiesIndex !== -1
  ) {
    const TotalLiabilitiesAndEquitySum =
      (ocrReportData[totalLiabilitiesIndex].Total || 0) +
      (ocrReportData[TotalEquityIndex].Total || 0);
    // ocrReportData[totalAssetsIndex].Total = totalAssetsSum;
    ocrReportData[totalLiabilitiesAndEquityIndex] = {
      ...ocrReportData[totalLiabilitiesAndEquityIndex],
      Total: Number(
        parseFloat(TotalLiabilitiesAndEquitySum.toString()).toFixed(2)
      ),
    };
  }

  return ocrReportData;
}

// Helper function to calculate the sum of 'Total' values above a specified index
function calculateSumAbove(
  data: Record<string, any>[],
  endIndex: number,
  labelContains: string
) {
  return data.slice(0, endIndex).reduce((acc, row) => {
    if (row.Label?.toLowerCase()?.includes(labelContains)) {
      const totalValue = parseFloat(row.Total) || 0;
      return acc + totalValue;
    }
    return acc;
  }, 0);
}

// Helper function to calculate the sum of 'Total' values between two indices (inclusive)
function calculateSumBetween(
  data: Record<string, any>[],
  startIndex: number,
  endIndex: number,
  labelContains: string
) {
  return data.slice(startIndex, endIndex).reduce((acc, row) => {
    if (row.Label?.toLowerCase()?.includes(labelContains)) {
      const totalValue = parseFloat(row.Total) || 0;
      return acc + totalValue;
    }
    return acc;
  }, 0);
}

//update record calculation function
// Function to update 'Total' fields for consecutive "Total" rows after editing a row
export function updateConsecutiveTotals(data: Record<string, any>[]) {
  // Loop through all rows to find consecutive "Total" rows
  let priorTotalRowIndex = -1;

  for (let i = 0; i < data.length; i++) {
    if (data[i].Label.toLowerCase()?.includes('total')) {
      if (priorTotalRowIndex === -1) {
        // Case for the first "Total" row: set startIndex from 0 to prior "Total" row
        const startIndex = 0;
        const endIndex = i - 1;

        const sum =
          startIndex <= endIndex
            ? calculateSum(data, startIndex, i - 1)
            : data[i].Total;
        // data[i].Total = sum;
        data[i] = {
          ...data[i],
          Total: Number(parseFloat(sum.toString()).toFixed(2)),
        };
      } else {
        // Case for subsequent "Total" rows
        const startIndex = priorTotalRowIndex + 1;
        const endIndex = i - 1;

        const sum =
          startIndex <= endIndex
            ? calculateSum(data, startIndex, i - 1)
            : data[i].Total;
        // data[i].Total = sum;
        data[i] = {
          ...data[i],
          Total: Number(parseFloat(sum.toString()).toFixed(2)),
        };
      }
      // Update priorTotalRowIndex to the current index for the next iteration
      priorTotalRowIndex = i;
    }
  }
  return data;
}

// Helper function to calculate sum of 'Total' values from startIndex to endIndex (inclusive)
function calculateSum(
  data: Record<string, any>[],
  startIndex: number,
  endIndex: number
) {
  return data.slice(startIndex, endIndex + 1).reduce((acc, row) => {
    // console.log('startIndex', startIndex, endIndex + 1, data);
    if (
      !convertLowercase(Object.values(BSCurrentLiabilitiesAndAssets)).includes(
        row?.Label?.toLowerCase()?.trim()
      )
    ) {
      const totalValue = parseFloat(row.Total) || 0; // Convert to number, default to 0 if null/undefined
      return acc + totalValue;
    }
    return acc;
  }, 0);
}

//add record calculation function
const updateTotalCalculation = (
  data: Record<string, any>[],
  selectedRow: {
    rowIndex: number;
    rowData: Record<string, any>;
  }
) => {
  const selectedRowIndex =
    data?.findIndex((item) => item?.id === selectedRow?.rowData?.id) ?? 0;
  console.log('selectedRowIndex', selectedRowIndex);

  // Step 2: Find the nearest prior "Total" row
  let priorTotalRowIndex = -1;
  for (let i = selectedRowIndex - 1; i >= 0; i--) {
    if (data[i].Label.includes('Total')) {
      priorTotalRowIndex = i;
      break;
    }
  }

  // Step 3: Define startIndex for summing
  const startIndex = priorTotalRowIndex !== -1 ? priorTotalRowIndex + 1 : 0;

  // Step 4: Calculate the sum of "Total" values from startIndex to selectedRowIndex - 1
  const sum = data.slice(startIndex, selectedRowIndex).reduce((acc, row) => {
    const totalValue = parseFloat(row?.Total) || 0; // Parse to number or default to 0 if null/undefined
    return acc + totalValue;
  }, 0);

  // Step 5: Update the Total value for the row at selectedRow?.rowIndex
  console.log('sum', sum);

  // data[selectedRowIndex].Total = sum;
  data[selectedRowIndex] = {
    ...data[selectedRowIndex],
    Total: Number(parseFloat(sum.toString()).toFixed(2)),
  };

  return data;
};

//PL Report Calculation
export const updatePLCalculation = (ocrReportData: Record<string, any>[]) => {
  const totalIncomeIndex = ocrReportData.findIndex((row) =>
    convertLowercase(Object.values(PLIncomeType)).includes(
      row?.Label?.toLowerCase()?.trim()
    )
  );
  let costOfGoodsSoldIndex = ocrReportData.findIndex((row) =>
    convertLowercase(Object.values(PLCostOfGoods)).includes(
      row?.Label?.toLowerCase()?.trim()
    )
  );
  let totalCostofGoodsSoldIndex = ocrReportData.findIndex((row) =>
    convertLowercase(Object.values(PLTotalCostOfGoods)).includes(
      row?.Label?.toLowerCase()?.trim()
    )
  );
  const grossProfitIndex = ocrReportData.findIndex(
    (row) =>
      row?.Label?.toLowerCase()?.trim() ===
      PLReportCalculation.GrossProfit?.toLowerCase()
  );

  const totalExpensesIndex = ocrReportData.findIndex((row) =>
    convertLowercase(Object.values(PLExpenses)).includes(
      row?.Label?.toLowerCase()?.trim()
    )
  );
  const totalOtherIncomeIndex = ocrReportData.findIndex(
    (row) =>
      row?.Label?.toLowerCase()?.trim() ===
      PLReportCalculation.TotalOtherIncome?.toLowerCase()
  );
  const otherIncomeIndex = ocrReportData.findIndex(
    (row) =>
      row?.Label?.toLowerCase()?.trim() ===
      PLReportCalculation.OtherIncome?.toLowerCase()
  );
  const totalOtherExpensesIndex = ocrReportData.findIndex(
    (row) =>
      row?.Label?.toLowerCase()?.trim() ===
      PLReportCalculation.TotalOtherExpenses?.toLowerCase()
  );
  const otherExpensesIndex = ocrReportData.findIndex(
    (row) =>
      row?.Label?.toLowerCase()?.trim() ===
      PLReportCalculation.OtherExpenses?.toLowerCase()
  );

  const currentIncomeTaxIndex = ocrReportData.findLastIndex((row) =>
    convertLowercase(Object.values(PLReportIncomeTaxes)).includes(
      row?.Label?.toLowerCase()?.trim()
    )
  );
  const incomeBeforeIncomeTaxesIndex = ocrReportData.findIndex(
    (row) =>
      row?.Label?.toLowerCase()?.trim() ===
      PLReportCalculation.IncomeBeforeIncomeTaxes?.toLowerCase()
  );

  const profitIndex = ocrReportData.findLastIndex((row) =>
    convertLowercase(Object.values(PLReportProfit)).includes(
      row?.Label?.toLowerCase()?.trim()
    )
  );

  let totalNetIncome = 0;
  let totalCostofGoodsSold = 0;
  let grossProfitValue = 0;
  let totalExpenses = 0;
  let totalOtherIncome = 0;
  let totalOtherExpenses = 0;
  let currentIncomeTaxes = 0;
  let incomeBeforeIncomeTaxes = 0;

  //not found totalCostofGoodsSoldIndex then find GrossProfit index and then take previous index of grossProfit as totalCostofGoodsSoldIndex
  if (totalCostofGoodsSoldIndex === -1) {
    totalCostofGoodsSoldIndex = grossProfitIndex - 1;
  }

  if (costOfGoodsSoldIndex === -1) {
    costOfGoodsSoldIndex = totalIncomeIndex + 1;
  }

  if (totalIncomeIndex !== -1) {
    totalNetIncome = calculateTotalNetIncome(
      ocrReportData,
      0,
      totalIncomeIndex
    );

    ocrReportData[totalIncomeIndex] = {
      ...ocrReportData[totalIncomeIndex],
      Total: Number(parseFloat(totalNetIncome.toString()).toFixed(2)),
    };
  }

  if (![costOfGoodsSoldIndex, totalCostofGoodsSoldIndex].includes(-1)) {
    totalCostofGoodsSold = calculateTotalNetIncome(
      ocrReportData,
      costOfGoodsSoldIndex + 1,
      totalCostofGoodsSoldIndex
    );

    ocrReportData[totalCostofGoodsSoldIndex] = {
      ...ocrReportData[totalCostofGoodsSoldIndex],
      Total: Number(parseFloat(totalCostofGoodsSold.toString()).toFixed(2)),
    };
  }

  if (grossProfitIndex !== -1) {
    // const totalNetIncome = calculateTotalNetIncome(ocrReportData, 0, totalIncomeIndex);
    // const totalCostofGoodsSold = calculateTotalNetIncome(ocrReportData, totalIncomeIndex, totalCostofGoodsSoldIndex);

    grossProfitValue = totalNetIncome - totalCostofGoodsSold;

    ocrReportData[grossProfitIndex] = {
      ...ocrReportData[grossProfitIndex],
      Total: Number(parseFloat(grossProfitValue.toString()).toFixed(2)),
    };
  }

  if (![totalExpensesIndex, grossProfitIndex].includes(-1)) {
    totalExpenses = calculateTotalNetIncome(
      ocrReportData,
      grossProfitIndex + 1,
      totalExpensesIndex
    );

    ocrReportData[totalExpensesIndex] = {
      ...ocrReportData[totalExpensesIndex],
      Total: Number(parseFloat(totalExpenses.toString()).toFixed(2)),
    };
  }

  if (![totalOtherIncomeIndex, otherIncomeIndex].includes(-1)) {
    totalOtherIncome = calculateTotalNetIncome(
      ocrReportData,
      otherIncomeIndex + 1,
      totalOtherIncomeIndex
    );

    ocrReportData[totalOtherIncomeIndex] = {
      ...ocrReportData[totalOtherIncomeIndex],
      Total: Number(parseFloat(totalOtherIncome.toString()).toFixed(2)),
    };
  }

  if (![totalOtherExpensesIndex, otherExpensesIndex].includes(-1)) {
    totalOtherExpenses = calculateTotalNetIncome(
      ocrReportData,
      otherExpensesIndex + 1,
      totalOtherExpensesIndex
    );

    ocrReportData[totalOtherExpensesIndex] = {
      ...ocrReportData[totalOtherExpensesIndex],
      Total: Number(parseFloat(totalOtherExpenses.toString()).toFixed(2)),
    };
  }

  console.log('totalIncomeIndex', profitIndex, currentIncomeTaxIndex);

  if (
    ![
      profitIndex,
      currentIncomeTaxIndex,
      incomeBeforeIncomeTaxesIndex,
    ].includes(-1)
  ) {
    incomeBeforeIncomeTaxes =
      ocrReportData[incomeBeforeIncomeTaxesIndex]?.Total;
    currentIncomeTaxes = ocrReportData[currentIncomeTaxIndex]?.Total;
    const profit = incomeBeforeIncomeTaxes - currentIncomeTaxes;

    ocrReportData[profitIndex] = {
      ...ocrReportData[profitIndex],
      Total: Number(parseFloat(profit.toString()).toFixed(2)),
    };
  }

  if (profitIndex !== -1) {
    // let profit = 0;
    // if (currentIncomeTaxIndex === -1) {
    //   profit = grossProfitValue - totalExpenses + totalOtherIncome - totalOtherExpenses;
    // } else {
    //   profit = grossProfitValue - totalExpenses - currentIncomeTaxes + totalOtherIncome - totalOtherExpenses;
    // }
    const profit =
      grossProfitValue -
      totalExpenses -
      currentIncomeTaxes +
      totalOtherIncome -
      totalOtherExpenses;
    console.log(
      'profit',
      grossProfitValue,
      totalExpenses,
      currentIncomeTaxes,
      totalOtherIncome,
      totalOtherExpenses
    );

    ocrReportData[profitIndex] = {
      ...ocrReportData[profitIndex],
      Total: Number(parseFloat(profit.toString()).toFixed(2)),
    };
  }

  return ocrReportData;
};

export const calculateTotalNetIncome = (
  data: Record<string, any>[],
  startIndex: number,
  endIndex: number
) => {
  return data.slice(startIndex, endIndex).reduce((acc, row) => {
    const totalValue = parseFloat(row.Total) || 0;
    return acc + totalValue;
  }, 0);
};

const convertLowercase = (arr: string[]) => {
  return arr.map((el) => el.toLowerCase());
};

//AR AP report calculation
export const calculateTotalForAPAR = (
  data: Record<string, any>[],
  totalLabel = 'TOTAL'
) => {
  // Calculate the sum of TOTAL values excluding the "TOTAL" Label
  const totalSum = data.reduce((sum, item) => {
    if (item.Label !== totalLabel) {
      const totalValue = parseFloat(item[totalLabel]) || 0;
      return sum + totalValue; // Add TOTAL value or 0 if undefined
    }
    return sum;
  }, 0);

  console.log('totalSum', totalSum);

  // Update the TOTAL value for the "TOTAL" Label
  const updateTotal = data.map((item) => {
    if (item.Label === totalLabel) {
      return {
        ...item,
        [totalLabel]: Number(parseFloat(totalSum.toString()).toFixed(2)),
      };
    } else {
      return item;
    }
  });
  return updateTotal; // Return updated array
};

export function convertValuesToNumber(obj: Record<string, any>) {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = key === 'Label' ? obj[key] : obj[key] ? Number(obj[key]) : null; // Convert to number, fallback to 0 for invalid numbers
    return acc;
  }, {} as any);
}

export function calculateEachRowTotal(
  data: Record<string, any>[],
  totalLabel = 'TOTAL'
) {
  return data.map((row) => {
    // Get all keys except "Label" and "id" as relevant keys
    const keys = Object.keys(row).filter(
      (key) => key !== 'Label' && key !== 'id' && key !== totalLabel
    );

    // Calculate the sum of numeric values for relevant keys
    const total = keys.reduce((sum, key) => {
      const value = Number(row[key]); // Convert value to number or NaN
      return sum + (isNaN(value) ? 0 : value); // Add to sum if numeric
    }, 0);

    // Update the row's TOTAL field with the calculated sum
    return {
      ...row,
      [totalLabel]: Number(total.toFixed(2)), // Ensure TOTAL is rounded to 2 decimal places
    };
  });
}

export function updateAPARReportConsecutiveTotals(data: Record<string, any>[]) {
  // Initialize the previous total row index
  let priorTotalRowIndex = -1;

  // Iterate through the data
  for (let i = 0; i < data?.length; i++) {
    if (data[i]?.Label?.toLowerCase().includes('total')) {
      // Define start and end indices for summation
      const startIndex = priorTotalRowIndex + 1;
      const endIndex = i - 1;

      if (startIndex > endIndex) {
        // Directly assign the current row's object if startIndex > endIndex
        priorTotalRowIndex = i;
        continue;
      }

      // Calculate sums for all numeric keys except 'Label'
      const keys = Object.keys(data[i]).filter(
        (key) => key !== 'Label' && key !== 'id'
      );

      const sums = keys.reduce((acc, key) => {
        acc[key] = 0;
        for (let j = startIndex; j <= endIndex; j++) {
          acc[key] += Number(data[j][key]) || 0; // Safely add numeric values
        }
        return acc;
      }, {} as any);

      // Update the current total row with calculated sums
      data[i] = {
        ...data[i],
        ...Object.fromEntries(
          keys.map((key) => [key, Number(sums[key].toFixed(2))])
        ),
      };

      // Update prior total row index to the current index
      priorTotalRowIndex = i;
    }
  }

  return data;
}

export function updateAPARLastTotalRow(data: Record<string, any>[]) {
  // Calculate totals for the last row with "Label": "TOTAL"
  const lastIndex = data.length - 1;
  const lastRow = data[lastIndex];

  if (lastRow?.Label?.toLowerCase() === 'total') {
    // Filter out rows with "Label" containing "total" (case-insensitive)
    const validRows = data.filter((row, index) => {
      const isTotalLabel = row?.Label?.toLowerCase().includes('total');
      return !isTotalLabel && index !== lastIndex;
    });

    // Get all numeric keys excluding "Label" and "id"
    const keys = Object.keys(lastRow).filter(
      (key) => key !== 'Label' && key !== 'id'
    );

    // Calculate sums for each key
    const sums = keys.reduce((acc, key) => {
      acc[key] = validRows.reduce((sum, row) => {
        return sum + (Number(row[key]) || 0);
      }, 0);
      return acc;
    }, {} as any);

    // Update the last row with calculated sums
    data[lastIndex] = {
      ...lastRow,
      ...Object.fromEntries(
        keys.map((key) => [key, Number(sums[key].toFixed(2))])
      ),
    };
  }

  return data;
}
