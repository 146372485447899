import { APIStatus } from 'src/types/unions';

export interface AdminDashboardApplication {
  applicationName: string;
}

export interface AdminDashboardBroker {
  brokerId: number;
  firstName: string;
  applications: AdminDashboardApplication[];
}

export interface AdminDashboardList {
  legalName: string;
  brokerageId: number;
  brokers: AdminDashboardBroker[];
}

export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_ALL_ADMIN_DASHBOARD_LIST'
    | 'BROKERAGE_STATUS_UPDATED_BY_ADMIN'
    | null;
  adminDashboardList: AdminDashboardList[];
  msg: string;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  adminDashboardList: [],
  msg: '',
};

export default initialState;
